import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { MediaService } from './media.service';
import { LogService } from './log.service';
import { SurveyType } from './enum';
import { MediaObject, MEDIA_STATUS } from '@ionic-native/media/ngx';

@Injectable()
export class CurrentSurvey {
    static flagvar1 = 0;
    static flagvar2 = 0;
    static flagvar3 = 0;
    static flagvar4 = 0;
    static flagvar5 = 0;
    static flagvar6 = 0;
    static flagvar7 = 0;
    static flagvar8 = 0;
    static flagvar9 = 0;
    static flagvar10 = 0;
    static flagvar11 = 0;
    static flagvar12 = 0;
    static flagvar13 = 0;
    static flagvar14 = 0;
    static flagvar15 = 0;
    static flagvar16 = 0;
    static flagvar17 = 0;
    static flagvar18 = 0;
    static flagvar19 = 0;
    static flagvar20 = 0;

    static BankSector = 0;
    static BankBranch = 0;
    static TokenId = 0;

    static State: string;
    static City: string;
    static BranchOfBank: string;
    static IFSC: string;

    // static CustomerId = 0;
    static ApplicationName = 0;
    static Tier = 0;
    static Region = 0;
    static Age = 0;
    static Gender = 0;
    static Product1 = 0;
    static Product2 = 0;
    static Product3 = 0;
    static Product4 = 0;
    static Product5 = 0;

    static ExpiryData: Date; //keeps sys_uid

    static code: string;
    static respId: number;
    static uId: string; //keeps sys_uid
    static surveyDirectory: string;
    static type: SurveyType;
    static title: string;
    static lastModifiedOn: any;
    static languages: any[];
    static cssRules: string[];
    static lang: any;
    static config: any;
    static speak: boolean;
    static respVersion: number;
    static respVersionSuffix: string;
    static isRestart;
    static surveyEndMessage: string;
    static skipped = false; //Indicates that skip function has been called so no need to call move
    static skippedToPageIndex = -1;

    static recording: MediaObject;
    static recordingPath: string;
    static recordingStatus: MEDIA_STATUS;
    static recordingSubscriptions;

    static questions: any;
    static lists: any;
    static dispositions: any[];
    static translations: any;
    static loops: any[];
    static sessions: any[]; //For LivePoll
    static includes: any[];

    static qMap: any = {};
    static sysVarMap: any = {};
    static listMap: any = {};
    static pages = [];
    static page: any;
    static navHistory: number[];
    static isRunning;

    static runOnline: boolean;
    static surveyFilesDict = {};
    static gs = GenericService;
    static ms: MediaService;
    static ls: LogService;

    static feedback: string;
}
