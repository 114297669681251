export const enum LogLevel
{
    Info = 1,
    Debug = 2,
    Error = 3,
    AppCrash = 4
}

export enum SurveyType //Removing const keyword. Otherwise not able tio create its alias to access on html
{
    Survey = 1,
    RandomDiary = 2, //like water survey. Everytime u drink, you fill survey. Requires login
    PeriodicDiary = 3, // Waves are defined in survey-details.json. On those waves only user can fill survey. Requires login
    LivePoll = 4,
    ChatBot = 5
}

export const enum SurveyStatus
{
    Incomplete = 0,
    Complete = 1,
    Terminated = 2,
}

export const enum QuotaType
{
    SoftQuota = 1,
    HardQuota = 2,
}
export const enum Permission
{
    ManageUsers = 1,
    ManageQuota = 2,
    EditProfile = 3, //Self Profile & Change Password
    AccessData = 4,
    ExportDeviceData = 5,
    SyncAll = 6,
    FieldStatus = 7,
    Scripting = 8, //Translations, Variables, Recode etc.
    BackendWork = 9, //Update Variables etc.
    NewInterview = 10,//Update Variables etc.
    SurveyFilesAccess = 11,
    Audit = 12,
    RemoveProject = 13,
    AllowTest = 14
}

