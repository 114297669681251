import { Injectable } from '@angular/core';
import { MENU } from 'src/settings/menu';

@Injectable()
export class GlobalService
{
    static spinner;
    static user: any;
    static isMobileView: boolean;

    static getMenuItemByPath(path: string)
    {
        for (const item of MENU)
        {
            if (item.path === path)
                return item;
            if (item.sub)
            {
                for (const subItem of item.sub)
                {
                    if (subItem.path === path)
                        return subItem;
                }
            }
        }
    }

    static getPageTitleByPath(path: string)
    {
        const item = this.getMenuItemByPath(path);
        return item.title;
    }
}
