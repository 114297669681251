import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { Ng5SliderModule } from 'ng5-slider';
//import { NgImageSliderModule } from 'ng-image-slider';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UiModule } from './ui/ui.module';

import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { FlatpickrModule } from 'angularx-flatpickr';

import { AppConfig } from './providers/app.config';
import { TokenService } from './providers/token.service';
import { AuthService } from './providers/auth.service';
import { GenericService } from './providers/generic.service';
import { AjaxService } from './providers/ajax.service';
import { SqliteService } from './providers/sqlite.service';
import { ChartService } from './providers/chart.service';
import { RegexService } from './providers/regex.service';
import { MediaService } from './providers/media.service';
import { LogService } from './providers/log.service';
import { NetworkService } from './providers/network.service';
import { IonicService } from './providers/ionic.service';
import { DataService } from './providers/data.service';
import { GlobalErrorHandler } from './providers/global-error.handler';
import { GlobalService } from './providers/global.service';

import { SurveyService } from './providers/survey.service';
import { FCMService } from './providers/fcm.service';
import { ScriptService } from './providers/script.service';
import { CurrentSurvey } from './providers/current-survey';
import { SyncService } from './providers/sync.service';
import { CustomService } from './providers/custom.service';
import { Styler } from './providers/styler';
import { AuthGuard } from './providers/auth-guard';
import { FileService } from './providers/file.service';
import { ImageService } from './providers/image.service';
import { SurveyPageService } from './providers/survey-page.service';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { File } from '@ionic-native/file/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { Device } from '@ionic-native/device/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { Market } from '@ionic-native/market/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Media } from '@ionic-native/media/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { FTP } from '@ionic-native/ftp/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';

import { AnimationService, AnimatesDirective } from 'css-animator';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';

export function getJwtHttp(tokenService: TokenService) {
    return {
        tokenName: 'access_token',
        tokenGetter: (() => {
            return tokenService.getAccessToken();
        }),
        globalHeaders: [{ 'Content-Type': 'application/json' }]
    };
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        Ng5SliderModule,
        //NgImageSliderModule,
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        HammerModule,
        MatDialogModule,
        JwtModule.forRoot(
            {
                jwtOptionsProvider:
                {
                    provide: JWT_OPTIONS,
                    useFactory: getJwtHttp,
                    deps: [TokenService]
                }
            }),
        FlatpickrModule.forRoot(),
        AppRoutingModule,
        UiModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        AppConfig,
        GenericService,
        AjaxService,
        AuthService,
        AuthGuard,
        TokenService,
        ChartService,
        SqliteService,
        RegexService,
        FileService,
        MediaService,
        ImageService,
        LogService,
        NetworkService,
        IonicService,
        DataService,
        GlobalService,
        GlobalErrorHandler,
        SurveyService,
        FCMService,
        ScriptService,
        SurveyPageService,
        CurrentSurvey,
        SyncService,
        CustomService,
        Styler,

        AnimationService,
        AnimatesDirective,

        File,
        FileTransfer,
        SQLite,
        Device,
        SocialSharing,
        Network,
        Camera,
        Diagnostic,
        AppVersion,
        InAppBrowser,
        CodePush,
        Market,
        LocalNotifications,
        GoogleAnalytics,
        Geolocation,
        Media,
        MediaCapture,
        WebView,
        TextToSpeech,
        ScreenOrientation,
        FTP,
        LocationAccuracy,
        BatteryStatus
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
