import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AjaxService } from '../providers/ajax.service';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from './global.service';


@Injectable()
export class FCMService
{
    messaging: any;
    unsubscribeOnTokenRefresh = () => { };
    currentMessage = new BehaviorSubject(null)
    public config = {
        messagingSenderId: "1056972291179"
    };

    constructor(private ajaxService: AjaxService, private storage: Storage)
    {
    }

    public enableNotifications(): any
    {
        console.log('Requesting permission...');
        return this.messaging.requestPermission().then(() =>
        {
            console.log('Permission granted');
            // token might change - we need to listen for changes to it and update it
            this.setupOnTokenRefresh();
            return this.updateToken();
        });
    }

    public disableNotifications()
    {
        this.messaging.deleteToken().then(() =>
        {
            this.unsubscribeOnTokenRefresh();
            this.unsubscribeOnTokenRefresh = () => { };
            this.setFCMToken(null);
        });
    }

    updateToken()
    {
        return this.messaging.getToken().then((fcmToken) =>
        {
            if (fcmToken)
            {
                // we've got the token from Firebase, now let's store it in the database
                this.setFCMToken(fcmToken);
            }
            else
            {
                console.log('No Instance ID token available. Request permission to generate one.');
            }
        });
    }

    private setupOnTokenRefresh(): void
    {
        this.unsubscribeOnTokenRefresh = this.messaging.onTokenRefresh(() =>
        {
            console.log("Token refreshed");
            this.updateToken();
        });
    }

    receiveMessage()
    {
        this.messaging.onMessage((payload) =>
        {
            console.log("Message received. ", payload);
            this.currentMessage.next(payload)
        });
    }

    setFCMToken(fcmToken)
    {
        if (GlobalService.user == null)
            return;
        this.ajaxService.postData('user/setFCMToken', { Id: GlobalService.user.Id, Name: fcmToken })
            .subscribe
            (() =>
            {
                GlobalService.user.FCMToken = fcmToken;
                this.storage.set('user', GlobalService.user);
            },
            (err) =>
            {
                console.log(err);
            });
    }

    sendMessage()
    {
    }
}
