import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './providers/auth-guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule'
    },
    {
        path: 'uniquesurvey',
        loadChildren: './pages/survey/survey.module#SurveyPageModule',
    },
    {
        path: 'survey',
        loadChildren: './pages/survey/survey.module#SurveyPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'offline-surveys',
        loadChildren: './pages/offline-surveys/offline-surveys.module#OfflineSurveysPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'offline-survey-card/:code/:title',
        loadChildren: './pages/offline-survey-card/offline-survey-card.module#OfflineSurveyCardPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfilePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'about',
        loadChildren: './pages/about/about.module#AboutPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'media-gallery',
        loadChildren: './pages/media-gallery/media-gallery.module#MediaGalleryPageModule',
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
