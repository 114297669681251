import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AjaxService } from './ajax.service';
import { AuthService } from './auth.service';
import { SqliteService } from './sqlite.service';
import { SurveyService } from './survey.service';
import { IonicService } from './ionic.service';
import { MediaService } from './media.service';
import { LogService } from './log.service';
import { AppConfig } from './app.config';
import { Permission } from './enum';
import { Market } from '@ionic-native/market/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { GlobalService } from './global.service';
import { CodePush, SyncStatus, InstallMode } from '@ionic-native/code-push/ngx';
import { GenericService } from './generic.service';

@Injectable()
export class SyncService
{
    gs = GenericService;
    synced: any = {};
    unsynced: any = {};
    silentMode;

    constructor(private ajaxService: AjaxService, private as: AuthService, private surveyService: SurveyService, private ls: LogService,
        private sqliteService: SqliteService, private ions: IonicService, private ms: MediaService, private codePush: CodePush,
        private appVersion: AppVersion, private platform: Platform, private market: Market)
    {
    }


    async sync(surveyCode, syncAll = false)
    {
        if (!this.silentMode)
            await this.ions.showLoader('Syncing started. Please wait...');
        await this.checkCounts(surveyCode);

        await this.syncLogs().catch(async err => { await this.ions.alertError(err) });

        if (!this.silentMode)
            await this.ions.showLoader('Syncing Interviews...');
        await this.syncInterviews(surveyCode, syncAll).catch(async err => { await this.ions.alertError(err) });

        if (!this.silentMode)
            await this.ions.showLoader('Syncing Questionnaire...');
        await this.surveyService.downloadSurvey(surveyCode).catch(async err => { await this.ions.alertError(err) });

        if (!this.silentMode)
            await this.ions.showLoader('Syncing Interview files...');
        await this.ms.syncFiles(surveyCode, this.unsynced, this.synced, this.silentMode).catch(async err => { await this.ions.alertError(err) });
        //.then(async () => { await this.ions.hideLoader() })
    }

    async syncInterviews(surveyCode, syncAll = false): Promise<any>
    {
        const filter = syncAll ? null : { synced: 0 }; //This is required only in emergency case when we want all the synced data again
        let err, interviews;

        let pageNo = 1;
        while (1)
        {
            [err, interviews] = await this.gs.to(this.sqliteService.getInterviews(surveyCode, filter, pageNo, 10));
            if (err)
                return await this.ions.showAndLogError(err, "Error while fetching Interviews to sync");

            if (interviews.length === 0)
                break;

            const url = 'response/sync?surveyCode=' + surveyCode;
            [err] = await this.gs.to(this.ajaxService.postData(url, interviews, !GlobalService.user).toPromise());
            if (err)
                return await this.ions.showAndLogError(err, "Error while syncing Interviews");

            const uids = interviews.map(x => x.sys_uid.Answer);
            [err] = await this.gs.to(this.sqliteService.markInterviewsAsSynced(surveyCode, uids));
            if (err)
                return await this.ions.showAndLogError(err, "Error while marking interview as synced");

            const incompletes = interviews.filter(x => x["sys_survey_status"].Answer === 0);
            const completes = interviews.filter(x => x["sys_survey_status"].Answer === 1);
            const terminated = interviews.filter(x => x["sys_survey_status"].Answer === 2);

            this.synced.Incomplete += incompletes.length;
            this.synced.Complete += completes.length;
            this.synced.Terminated += terminated.length;

            this.unsynced.Incomplete -= incompletes.length;
            this.unsynced.Complete -= completes.length;
            this.unsynced.Terminated -= terminated.length;5

            pageNo++;
        }
    }

    async syncLogs(surveyCode = null): Promise<any>
    {
        let pageNo = 1;
        while (1)
        {
            try
            {
                let [err, logs] = await this.gs.to(this.sqliteService.getLogs(surveyCode, 0, pageNo, 50));
                if (err)
                    return await this.ions.showAndLogError(err, "Error while fetching logs to sync");

                if (logs.length === 0)
                    break;

                const url = 'log/sync';
                [err] = await this.gs.to(this.ajaxService.postData(url, logs, !GlobalService.user).toPromise());
                if (err)
                    return await this.ions.showAndLogError(err, "Error while syncing Logs");

                const ids = logs.map(x => x.id);
                [err] = await this.gs.to(this.sqliteService.markLogsAsSynced(ids));
                if (err)
                    return await this.ions.showAndLogError(err, "Error while marking logs as synced");

                this.synced.Logs += logs.length;
                this.unsynced.Logs -= logs.length;

                pageNo++;
            }
            catch
            {

            }
        }
    }

    async checkCounts(surveyCode): Promise<any>
    {
        try
        {
            let res;

            const unsynced: any = {};
            const synced: any = {};

            try
            {
                unsynced.Media = await this.sqliteService.countInterviewFiles(surveyCode, 0); //await this.ms.checkCounts(surveyCode);
                synced.Media = await this.sqliteService.countInterviewFiles(surveyCode, 1);
                unsynced.Missing = await this.sqliteService.countInterviewFiles(surveyCode, 0, 'missing');
            }
            catch(err)
            {
                const msg = this.gs.fetchErrorMessage(err);
                alert('Error in countInterviewFiles: '+msg);
            }
            unsynced.Log = 0; synced.Log = 0;

            try
            {
                res = await this.sqliteService.countLogsByStatus(surveyCode);
                synced.Log = res.Synced;
                unsynced.Log = res.Unsynced;
            }
            catch (err)
            {
                const msg = this.gs.fetchErrorMessage(err);
                alert('Error in countLogsByStatus: ' + msg);
            }
            try
            {
                res = await this.sqliteService.countInterviewsByStatus(surveyCode);
                synced.Incomplete = res.Synced.Incomplete;
                synced.Complete = res.Synced.Complete;
                synced.Terminated = res.Synced.Terminated;
                unsynced.Incomplete = res.Unsynced.Incomplete;
                unsynced.Complete = res.Unsynced.Complete;
                unsynced.Terminated = res.Unsynced.Terminated;
            }
            catch (err)
            {
                const msg = this.gs.fetchErrorMessage(err);
                alert('Error in countInterviewsByStatus: ' + msg);
            }

            this.unsynced = unsynced;
            this.synced = synced;
        }
        catch (err)
        {
            const msg = this.gs.fetchErrorMessage(err);
            alert(msg);
            //this.ls.log(err, LogLevel.Error, null, 'Error in checkCounts');
        }
    }

    async updateCode()
    {
        this.ions.showLoader('Checking for updates...');

        return this.ajaxService.callGetService('version-info')
            .subscribe(json =>
            {
                const config = this.platform.is('ios') ? json['ios'] : json['android'];
                this.appVersion.getVersionCode().then(current =>
                {
                    if (config.latest > current)
                    {
                        this.ions.hideLoader();
                        this.market.open(config.id);
                    }
                    else
                    {
                        const downloadProgress = (progress) =>
                        {
                            this.ions.showLoader(`Downloaded ${(progress.receivedBytes / 1000000).toFixed(1)} MB of ${(progress.totalBytes / 1000000).toFixed(1)} MB`);
                        }
                        let key;
                        if (AuthService.hasPermission(Permission.AllowTest))
                            key = AppConfig.codePushKeyStaging;
                        else
                            key = AppConfig.codePushKeyProduction;


                        this.codePush.sync({ updateDialog: { appendReleaseDescription: true }, installMode: InstallMode.IMMEDIATE, deploymentKey: key }, downloadProgress)
                            .subscribe(syncStatus =>
                            {
                                if (syncStatus === SyncStatus.UP_TO_DATE)
                                    this.ions.showSuccessToast('App is up to date...');
                                else if (syncStatus === SyncStatus.DOWNLOADING_PACKAGE)
                                    this.ions.showLoader('Downloading updates');
                                else if (syncStatus === SyncStatus.INSTALLING_UPDATE)
                                    this.ions.showLoader('Installing updates');
                                else
                                {
                                    this.ions.hideLoader();
                                    this.ls.log('Sync Status:' + syncStatus);
                                }
                            },
                                err =>
                                {
                                    this.ions.showErrorToast(err, 'Sorry app could not be updated');
                                });
                    }
                }).catch(err =>
                {
                    this.ions.showErrorToast(err, 'Error while reading App Version');
                });
            },
                err =>
                {
                    this.ions.showErrorToast(err, 'Error while reading version-info');
                });
    }
}
