import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GenericService } from './generic.service';
import { CurrentSurvey } from './current-survey';
import { IonicService } from './ionic.service';
import { LogService } from './log.service';
import { File } from '@ionic-native/file/ngx';
import { LogLevel } from './enum';
import { AppConfig } from './app.config';
import { MEDIA_STATUS, Media } from '@ionic-native/media/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { MediaCapture, MediaFile, CaptureError } from '@ionic-native/media-capture/ngx';
import { FileService } from './file.service';
import { SqliteService } from './sqlite.service';
import { FTP } from '@ionic-native/ftp/ngx';
import { AjaxService } from './ajax.service';

@Injectable()
export class MediaService {
    cs = CurrentSurvey; gs = GenericService;
    silentMode;

    constructor(private platform: Platform, private sqliteService: SqliteService, private ions: IonicService,
        private file: File, private fs: FileService, private ft: FileTransfer, private media: Media,
        private ls: LogService, private tts: TextToSpeech, private ftp: FTP,
        private ajaxService: AjaxService, private mediaCapture: MediaCapture) {
    }

    async getDirectory(dirName, path = null): Promise<string> {
        path = path || FileService.rootDirPath;
        const p = (path.endsWith('/') ? path : path + '/') + dirName + "/";
        return this.file.createDir(path, dirName, false).then(() => {
            return p;
        }).catch(err => {
            if (err.message !== 'PATH_EXISTS_ERR')
                throw Error(err.message);
            return p;
        });
    }

    async setupDirStructure(surveyCode): Promise<any> {
        if (this.platform.is('ios'))
            FileService.rootDirPath = this.file.documentsDirectory;
        else if (this.platform.is('android'))
            FileService.rootDirPath = this.file.externalDataDirectory; //We are using externalDataDirectory because this will make files private to this app.
        //We know it will delete all the files if data is cleared or app is uninstalled.

        if (surveyCode) {
            let err;
            [err] = await this.gs.to(this.getDirectory(surveyCode));
            if (err)
                return await this.ions.showAndLogError(err, "Error while reading dir " + surveyCode);

            [err] = await this.gs.to(this.getDirectory(FileService.projectAssetsDirName, FileService.rootDirPath + surveyCode));
            if (err)
                return await this.ions.showAndLogError(err, "Error while reading dir " + FileService.projectAssetsDirName);

            [err] = await this.gs.to(this.getDirectory(FileService.interviewFilesDirName, FileService.rootDirPath + surveyCode));
            if (err)
                return await this.ions.showAndLogError(err, "Error while reading dir " + FileService.rootDirPath + surveyCode);
        }

        FileService.fileTransfer = this.ft.create();
    }

    async play(filePath) {
        const audio: any = document.getElementById("voiceover");
        audio.src = filePath;
        audio.play();
    }

    async stopPlaying() {
        const audio: any = document.getElementById("voiceover");
        if (audio) {
            audio.pause();
            delete audio.src;
            if (AppConfig.isApp)
                return this.tts.speak(" ");
        }
    }

    async startAudioRecording(questCode: string, duration = 0, silentRecord = false): Promise<string> {
        if (!AppConfig.isApp)
            return;

        if (!questCode || !this.cs.qMap[questCode])
            throw new Error('A valid question code must be mentioned while recording audio.');

        const quest = this.cs.qMap[questCode];

        if (silentRecord) {
            const isAuthorized = await this.ions.diagnostic.isMicrophoneAuthorized();
            if (!isAuthorized) {
                throw new Error('Microphone authorization is required for silent recording.');
            }
        }

        const [authError] = await GenericService.to(this.ions.diagnostic.requestMicrophoneAuthorization());
        if (authError) {
            if (silentRecord) {
                return; // Return silently if authorization failed for silent recording
            }
            throw new Error('Error while requesting microphone authorization: ' + authError);
        }

        // Stop any ongoing audio playback before starting recording
        await this.stopPlaying();

        // Ensure any ongoing recording is stopped before starting a new one
        await this.stopAudioRecording();

        // Speak a blank message to ensure any ongoing text-to-speech playback is stopped
        await this.tts.speak(' ');

        const fileName = this.fs.getInterviewFileName(this.cs.code, this.cs.respId, this.cs.uId, questCode, 'm4a');
        if (quest.Type === 'AudioRecording') {
            quest.Answer = fileName;
        }

        const interviewFilesDirPath = FileService.rootDirPath + this.cs.code + '/' + FileService.interviewFilesDirName;
        const filePath = interviewFilesDirPath + '/' + fileName;

        this.cs.recordingPath = fileName;
        try {
            this.cs.recording = this.media.create(filePath);
        } catch (createError) {
            if (!silentRecord) {
                this.ions.showAndLogError(createError, 'Error creating media for recording: ' + this.cs.recordingPath);
            } else {
                this.ls.log(createError, LogLevel.Error, null, 'Error creating media for recording: ' + this.cs.recordingPath);
            }
            return;
        }

        // Subscribe to recording errors
        this.cs.recordingSubscriptions = this.cs.recording.onError.subscribe(recordingError => {
            this.ls.log(recordingError, LogLevel.Error, null, 'Error in Audio Recording for ' + this.cs.recordingPath);
        });

        // Subscribe to recording status updates
        this.cs.recordingSubscriptions.add(this.cs.recording.onStatusUpdate.subscribe(status => {
            this.cs.recordingStatus = status;
            //this.ls.log("MediaStatus: " + status + " for " + this.cs.recordingPath, LogLevel.Debug);
            if (status === MEDIA_STATUS.STOPPED && this.cs.recording) //If stopped without calling stopRecording()
            {
                this.ls.log('Recording stopped unexpectedly: ' + this.cs.recordingPath, LogLevel.Debug);
                this.stopAudioRecording();
            }
        }));

        try {
            // Start recording
            this.cs.recording.startRecord();
            // this.ls.log('Media Recording Start: ' + this.cs.recordingPath, LogLevel.Debug);

            // Save recording information
            await this.sqliteService.saveInterviewFile(this.cs.code, this.cs.uId, fileName, questCode, 'audio', 'started', new Date());


            // If duration is specified, stop recording after the specified duration
            if (duration) {
                setTimeout(() => this.stopAudioRecording(), duration * 1000);
            }


        } catch (startError) {
            if (!silentRecord) {
                this.ions.showAndLogError(startError, 'Unable to start recording: ' + this.cs.recordingPath);
            } else {
                this.ls.log(startError, LogLevel.Error, null, 'Unable to start recording: ' + this.cs.recordingPath);
            }
        }


        return fileName;
    }

    async stopAudioRecording() {
        if (!AppConfig.isApp) {
            return;
        }

        if (this.cs.recording) {
            try {
                // Update interview file status
                await this.sqliteService.updateInterviewFileStatus(this.cs.code, this.cs.uId, this.cs.recordingPath, 'stopped');

                // Unsubscribe from recording subscriptions to prevent memory leaks
                if (this.cs.recordingSubscriptions) {
                    this.cs.recordingSubscriptions.unsubscribe();
                }

                // Stop recording
                this.cs.recording.stopRecord();

                // Release resources
                this.cs.recording.release();

                // Reset recording-related properties
                this.cs.recording = null;
                this.cs.recordingStatus = MEDIA_STATUS.NONE;
                this.cs.recordingPath = null;

                //if (NetworkService.isOnline())
                //    this.syncFiles(this.cs.code, null, null, true);
            } catch (error) {
                // Handle any errors that may occur during stopping the recording
                console.error('Error while stopping audio recording:', error);
            }
        }
    }


    async startVideoRecording(questCode: string): Promise<string> {
        if (!AppConfig.isApp)
            return;

        if (!questCode || !this.cs.qMap[questCode])
            throw new Error('A valid question code must be mentioned while recording video.');

        const quest = this.cs.qMap[questCode];

        let err;
        [err] = await GenericService.to(this.ions.diagnostic.requestMicrophoneAuthorization());
        if (err)
            throw new Error(err);

        [err] = await GenericService.to(this.ions.diagnostic.requestCameraAuthorization());
        if (err)
            throw new Error(err);

        await this.stopPlaying(); //To stop the audio if running before starting recording.
        await this.stopAudioRecording();
        await this.tts.speak(" ");

        const interviewFilesDirPath = FileService.rootDirPath + this.cs.code + '/' + FileService.interviewFilesDirName;
        let fileName = this.fs.getInterviewFileName(this.cs.code, this.cs.respId, this.cs.uId, questCode, 'm4v');
        fileName = this.fs.appendTimeStamp(fileName); //This is required because on reset and re-record it shouws cached video otherwise

        await this.sqliteService.saveInterviewFile(this.cs.code, this.cs.uId, fileName, questCode, 'video', 'started', new Date());

        const options: any = { limit: 1 };
        if (quest.Max)
            options.duration = quest.Max;

        quest.Answer = null;
        quest.src = null;

        return this.mediaCapture.captureVideo(options).then(
            async (mediaFiles: MediaFile[]) => {
                if (mediaFiles.length > 0) {
                    mediaFiles[0].getFormatData(data => {
                        quest.Duration = data.duration;
                    }, err => {
                        this.ls.log(err, LogLevel.Error, null, "Error in mediaFiles[0].getFormatData " + fileName);
                    });

                    const tempPath = mediaFiles[0].fullPath.replace(mediaFiles[0].name, '');

                    return this.file.moveFile(tempPath, mediaFiles[0].name, interviewFilesDirPath, fileName).then(async (f) => {
                        await this.sqliteService.saveInterviewFile(this.cs.code, this.cs.uId, fileName, questCode, 'video', 'saved', new Date());
                        quest.Answer = fileName;
                        quest.src = this.fs.pathToSrc(f.nativeURL) + "#t=0.02";

                        return quest.Answer;
                    })
                        .catch(err => {
                            this.ions.showAndLogError(err, "Error while moving file " + mediaFiles[0].name + " to interviewfiles dir" + fileName);
                        });
                }
            },
            (err: CaptureError) => {
                quest.Answer = null;
                if (err.code != "3")
                    this.ions.showAndLogError(err, "Error while recording video " + fileName);
            }
        );
    }

    async syncFiles(surveyCode, unsynced = null, synced = null, silentMode = false) //Syncing can be done without loading project
    {
        let err;
        [err] = await this.gs.to(this.ions.diagnostic.requestExternalStorageAuthorization());
        if (err)
            return this.ions.showErrorToast(err, 'External storage permission denied');

        this.silentMode = silentMode;
        const unSyncedFilesInfo = await this.sqliteService.getInterviewFiles(surveyCode, 0);

        if (unSyncedFilesInfo.length === 0) {
            if (!silentMode)
                await this.ions.showSuccessToast('Syncing done.', 'top')
            return;
        }

        const creds = await this.getFtpCredentials();
        if (!creds)
            return;

        await this.ions.showLoader('Connecting to file server...');

        [err] = await this.gs.to(this.ftp.connect(creds.Host, creds.UserName, creds.Password));
        if (err)
            return await this.ions.showErrorToast(err, 'Unable to connect to file server');

        await this.ions.showLoader('Syncing Interviews files...');

        //let options: FileUploadOptions =
        //{
        //    fileKey: 'file',
        //    headers: {}
        //}

        const files = await this.fs.getFiles(FileService.interviewFilesDirName, FileService.rootDirPath + surveyCode);

        for (const fileInfo of unSyncedFilesInfo) {
            const file = files.find(x => x.name === fileInfo.Name);
            if (!file) {
                //This happens when you close app directly. Without stop it does not write file.
                await this.sqliteService.updateInterviewFileStatus(surveyCode, fileInfo.UId, fileInfo.Name, 'missing');
                if (unsynced) unsynced.Media--;
                if (synced) synced.Media++;
                continue;
            }

            let path = file.fullPath;
            if (path.startsWith("/"))
                path = path.substring(1);

            this.ftp.upload(FileService.rootDirPath + path, '/' + surveyCode + '/' + FileService.interviewFilesDirName + '/' + file.name)
                .subscribe(async percent => {
                    if (percent === 1) {
                        await this.sqliteService.markInterviewFileAsSynced(surveyCode, fileInfo.UId, fileInfo.QuestCode);
                        if (unsynced) {
                            unsynced.Media--;
                            if (unsynced.Media === 0 && !silentMode) {
                                await this.ions.showSuccessToast('Syncing done.')
                            }
                        }
                        if (synced) synced.Media++;
                    }
                },
                    async err => {
                        await this.ions.showErrorToast(err);
                    });

            //options.fileName = fileInfo.Name;
            //let url = AppConfig.baseUrl + 'response/upload-file';
            //url = GenericService.addToQueryString(url, "surveyCode", surveyCode);
            //url = GenericService.addToQueryString(url, "uId", fileInfo.UId);
            //url = GenericService.addToQueryString(url, "questCode", fileInfo.QuestCode);
            //url = GenericService.addToQueryString(url, "type", fileInfo.Type);

            //await FileService.fileTransfer.upload(path, url, options).then(async () =>
            //{
            //    await this.sqliteService.markInterviewFileAsSynced(this.cs.code, fileInfo.UId, fileInfo.QuestCode);
            //    if (unsynced) unsynced.Media--;
            //    if (synced) synced.Media++;
            //}).catch(err =>
            //    {
            //        return this.ions.showAndLogError(err, "Error in file transfer of unsynced file " + fileInfo.Name);
            //    });
        }
    }

    async ftpUpload() {
        await this.ions.showSuccessToast('Connecting to server...');
        let err;
        const creds = await this.getFtpCredentials();
        if (!creds)
            return;

        await this.ions.showSuccessToast('Connecting to FTP Host...');

        [err] = await this.gs.to(this.ftp.connect(creds.Host, creds.UserName, creds.Password));
        if (err)
            return await this.ions.showErrorToast(err, 'Unable to connect to ftp server');

        await this.ions.showSuccessToast('FTP connection successful...');

        let files;
        [err, files] = await this.gs.to(this.fs.getFiles(FileService.interviewFilesDirName, FileService.rootDirPath + this.cs.code));

        if (err)
            return await this.ions.showSuccessToast('Unable to read files dir');

        let c = 0;
        for (let i = 0; i < files.length; i++) {
            if (files[i].isFile) {
                let path = files[i].fullPath;
                if (path.startsWith("/"))
                    path = path.substring(1);

                this.ftp.upload(FileService.rootDirPath + path, '/' + this.cs.code + '/' + FileService.interviewFilesDirName + '/' + files[i].name)
                    .subscribe(async percent => {
                        if (percent === 1) {
                            c++;
                            if (c === files.length)
                                await this.ions.showSuccessToast(c + ' files transferred successfully');
                        }
                    },
                        async err => {
                            await this.ions.showErrorToast(err);
                            c++;
                        });
            }
        }
    }

    async getFtpCredentials() {
        await this.ions.showLoader('Connecting to server...');
        const [err, creds] = await this.gs.to(this.ajaxService.callGetService("ftp-creds").toPromise());
        if (err)
            await this.ions.showAndLogError('Error in ftp-cred');

        return creds;
    }

    async downloadProjectAssets(surveyCode) {
        let [err, serverFiles] = await this.gs.to(this.ajaxService.callGetService("survey/survey-files?surveyCode=" + surveyCode).toPromise());
        if (err)
            await this.ions.showAndLogError('Error while downloading files');

        if (serverFiles.length === 0)
            return;

        const arrs = serverFiles.map(x => x.FullName.split(FileService.projectAssetsDirName + '\\')[1].split('\\'));
        const fileNames = arrs.map(x => x[x.length - 1]);
        let parentDirs = arrs.map(x => x.slice(0, -1));
        const f = parentDirs.filter(x => x.length > 0).map(x => x.join());
        parentDirs = parentDirs.map(x => x.join('/'));

        const set = new Set(f);
        const dirsArray = Array.from(set).map((x: string) => x.split(','));

        const projectAssetsDirPath = FileService.rootDirPath + surveyCode + '/' + FileService.projectAssetsDirName;

        for (const dirs of dirsArray) {
            let parentDir = projectAssetsDirPath; let path;
            for (const dir of dirs) {
                [err, path] = await this.gs.to(this.getDirectory(dir, parentDir));
                if (err)
                    return await this.ions.showAndLogError(err, "Error while reading/creating dir " + dir);

                parentDir = path;
            }
        }

        const localFiles = await this.sqliteService.getSurveyFiles(surveyCode);
        const baseUrl = AppConfig.filesUrl + surveyCode + "/" + FileService.projectAssetsDirName + "/";
        const downloadPromises = [];
        let downloadedCount = 0;

        for (let i = 0; i < serverFiles.length; i++) {
            serverFiles[i].RemotePath = parentDirs[i] ? (parentDirs[i] + '/' + fileNames[i]) : fileNames[i];
            const lFile = localFiles.find(x => x.RemotePath === serverFiles[i].RemotePath.toLowerCase());
            if (!lFile || lFile.DownloadedOn < serverFiles[i].LastWriteTime || fileNames[i].indexOf('no-cache') > -1) {
                const promise = FileService.fileTransfer.download(baseUrl + serverFiles[i].RemotePath, projectAssetsDirPath + '/' + serverFiles[i].RemotePath).then(entry => {
                    downloadedCount++;
                    this.ions.showLoader("Files downloaded " + downloadedCount + "/" + downloadPromises.length);
                    const url = this.fs.pathToSrc(entry.toURL());
                    this.sqliteService.saveSurveyFile(surveyCode, url, serverFiles[i].RemotePath.toLowerCase(), serverFiles[i].TimeStamp);
                });
                downloadPromises.push(promise);
            }
        }

        if (downloadPromises.length > 0) {
            const [err] = await this.gs.to(Promise.all(downloadPromises));
            if (err)
                return await this.ions.showAndLogError(err, "Error while downloading files " + err.source || err.target);
        }
    }
}

