import { MenuController, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { AjaxService } from './ajax.service';
import { TokenService } from './token.service';
import { GlobalService } from './global.service';
import { AppConfig } from './app.config';
import { Storage } from '@ionic/storage';
import { CurrentSurvey } from './current-survey';

declare var globalConfig: any;

@Injectable()
export class AuthService
{
    cs = CurrentSurvey;

    constructor(private tokenService: TokenService, private ajaxService: AjaxService,
        private storage: Storage, private navCtrl: NavController, private menu: MenuController)
    {

    }

    async isLoggedin()
    {
        GlobalService.user = GlobalService.user || await this.storage.get('user');
        if (!GlobalService.user)
            return false;
        if (!GlobalService.user.access_token)
        {
            GlobalService.user = null;
            return false;
        }

        const loggedIn = !this.tokenService.isTokenExpired(GlobalService.user.access_token);
        if (!loggedIn)
            GlobalService.user = null;

        return loggedIn;
    }

    getUser(): Observable<any>
    {
        return from(this.storage.get('user'));
    }

    login(userName: string, password: string): Observable<boolean>
    {
        const data = { userName: userName, password: password }

        return this.ajaxService.callPostService('user/login', data, true).pipe(
            timeout(20000),
            map((response: Response) =>
            {
                const user = this.tokenService.setUser(response);
                GlobalService.user = user;
                return user;
            })
        );
    }

    changePassword(currentPassword: string, newPassword: string): Observable<boolean>
    {
        var data: any = { CurrentPassword: currentPassword, NewPassword: newPassword };

        return this.ajaxService.putData('user/changePassword', data).pipe(
            map((response: Response) =>
            {
                return true;
            })
        );
    }

    async logOut()
    {
        this.menu.close();
        this.menu.enable(false);
        GlobalService.user = null;
        await this.storage.remove('user');
        this.navCtrl.navigateRoot('/login')
    }

    static hasRole(roleId, user = null): boolean
    {
        user = user || GlobalService.user;
        if (!user || !user.Role)
            return false
        else
        {
            return user.Role.Id.toString().toLowerCase() == roleId.toString().toLowerCase();
        }
    }

    static hasPermission(permission, user = null): boolean
    {
        user = user || GlobalService.user;
        if (!user || !user.Role || !user.Role.Permissions)
            return false;

        const hasPermission = user.Role.Permissions.find(p => p.Id == permission) ? true : false;
        return hasPermission;
    }

    async getDefaultPage(): Promise<string>
    {
        let path = '/login';

        if (AppConfig.isApp)
        {
            const code = await this.storage.get("CurrentSurvey");
            if (code)
            {
                this.cs.code = code;
                path = '/survey';
            }
            else
                path = '/offline-surveys';
        }
        else
        {
            this.cs.code = globalConfig.surveyCode; //globalConfig.surveyCode will be available in case of online link in custom.js
            path = '/survey';
        }

        return path;
    }
}
