import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { IonicService } from './ionic.service';
import { AjaxService } from './ajax.service';
import { AppConfig } from './app.config';

@Injectable()
export class DataService
{
    subscription: any;
    dataFetcherEvent: any;
    pageNo; //pageNo starts at 1. Also 0 will be considered as 1.
    pageSize;
    maxRecords: number;
    list: any[];
    dTable: any;
    serviceUrl;
    filters;
    mode;
    searchText: string;
    searchBoxVisible: boolean;
    content: any;
    searchBar: any;
    columnDefs: any;

    constructor(private ajaxService: AjaxService, private ions: IonicService)
    {
        this.init();
    }

    async init(serviceUrl = null)
    {
        this.mode = 'list';
        this.serviceUrl = serviceUrl;
        this.list = [];
        this.dTable = { Fields: [], Data: [] }; //For DTable type data
        this.columnDefs = [];
        this.pageNo = 1;
        this.pageSize = 10;
        this.searchText = null;
        this.searchBoxVisible = false;
        this.subscription = null;
        this.dataFetcherEvent = null;
        this.content = null;
        this.searchBar = null;
    }

    async downloadExcel(serviceUrl)
    {
        await this.ions.showLoader();
        this.ajaxService.callGetService(serviceUrl, false).pipe(finalize(() =>
        {
            this.ions.hideLoader();
        })).subscribe(fileName =>
        {
            location.href = AppConfig.baseUrl + 'download-excel?fileName=' + fileName;
        })
    }

    async getList(filters = null, event = null, mode = null)
    {
        this.filters = filters;

        this.ions.hideLoader(); //This is not required. In some rare cases it may be helpful
        if (!mode || mode === 'search')
            this.ions.showSpinner();

        if (this.subscription && !this.subscription.closed && this.subscription.unsubscribe)
            this.subscription.unsubscribe();

        if (this.searchText)
            this.filters = GenericService.addToFilterString(this.filters, "SearchText", this.searchText) || "";

        let pageNo = this.pageNo;
        let pageSize = this.pageSize;
        const excel = mode == 'excel';

        if (mode == "refresh" && this.pageNo > 0)
            pageNo--;

        if (mode == "infinite-scroll")
            pageNo++;

        else if (mode != 'page-change')
        {
            pageNo = 1;
            if (mode == "excel")
                pageSize = 0;
        }

        if (this.dataFetcherEvent)
            this.dataFetcherEvent.cancel();

        this.dataFetcherEvent = event;

        if (excel)
            await this.ions.showLoader("Downloading file Please wait...");

        this.subscription = this.ajaxService.getList(this.serviceUrl, pageNo, pageSize, null, this.filters, excel).pipe(
            finalize(() =>
            {
                this.ions.hideSpinner();
                this.ions.hideLoader();
                if (this.dataFetcherEvent)
                {
                    this.dataFetcherEvent.complete();
                    this.dataFetcherEvent = null;
                }
            })).subscribe((res: any) => 
            {
                if (!excel)
                {
                    if (res.length > 0)
                    {
                        const list = res;
                        if (list.length > 0)
                            this.columnDefs = Object.keys(list[0]);
                        if (mode === "infinite-scroll" && list.length === 0)
                            return;

                        this.pageNo = pageNo;
                        if (mode !== "infinite-scroll")
                        {
                            this.list.splice(0); //clear
                        }
                        else if (mode === "infinite-scroll" && list.length + this.list.length > this.maxRecords)
                        {
                            this.list.splice(0, this.pageSize); // Remove 1 page from starting
                        }
                        this.list = this.list.concat(list);
                    }
                    else
                    {
                        this.list = [];
                    }
                }
                else
                {
                    location.href = AppConfig.baseUrl + 'downloadExcel?fileName=' + res;
                }
            },
            (err) =>
            {
                this.ions.alertError(err);
            });
    }

    onPageNoChange(pageNo)
    {
        this.pageNo = pageNo;
        this.getList(this.filters, null, 'page-change');
    }

    toggleSearchBox()
    {
        this.searchBoxVisible = !this.searchBoxVisible;
        this.content.resize();
        this.setFocusOnSearchBox();
    }

    setFocusOnSearchBox()
    {
        window.setTimeout(() =>
        {
            if (this.searchBar)
                this.searchBar.setFocus();
        });
    }

    changeMode(mode)
    {
        this.mode = mode;
    }

    async uploadData(file, url)
    {
        await this.ions.showLoader('Uploading file. Please wait...');

        this.ajaxService.postData(url, file[0])
            .subscribe((res) =>
            {
                this.ions.showSuccessToast(res);
                this.getList(this.filters);
                this.mode = "list";
            },
            (err) =>
            {
                this.ions.alertError(err);
            });
    }
}
