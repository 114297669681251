export const MENU: any = [
    {
        title: 'New Interview',
        icon: {
            class: 'fas fa-clipboard-list',
            bg: '#275389',
            color: 'rgba(255,255,255,.87)'
        },
        path: '/survey'
    },
    {
        title: 'Current Project',
        icon: {
            class: 'fas fa-bookmark',
            bg: '#275389',
            color: 'rgba(255,255,255,.87)'
        },
        path: '/offline-survey-card',
        appOnly:true
    },
    {
        title: 'Projects',
        icon: {
            class: 'fas fa-code',
            bg: '#275389',
            color: 'rgba(255,255,255,.87)'
        },
        path: '/offline-surveys',
        appOnly: true
    },
    //{
    //    title: 'Interview Files',
    //    icon: {
    //        class: 'fas fa-file',
    //        bg: '#275389',
    //        color: 'rgba(255,255,255,.87)'
    //    },
    //    path: '/media-gallery',
    //    appOnly: true
    //},
    {
        title: 'Profile',
        icon: {
            class: 'fas fa-user-edit',
            bg: '#275389',
            color: 'rgba(255,255,255,.87)'
        },
        path: '/profile'
    },
    {
        title: 'About',
        icon: {
            class: 'fas fa-info-circle',
            bg: '#275389',
            color: 'rgba(255,255,255,.87)'
        },
        path: '/about'
    }
];



