import { Injectable } from '@angular/core';

@Injectable()
export class RegexService
{
    static commonPatterns=
    {
        'email': /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        'url': /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        'ip-address': /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        '10digits': /^[0-9]{10}$/,
        '11digits': /^[0-9]{11}$/,
        'number': /^[0-9]*$/,
        'script': /(.*)(\[\%.+\%\])(.*)/
    }

    static test(str, pattern)
    {
        let patt = this.commonPatterns[pattern];
        if (!patt)
            patt = pattern;

        const exp = new RegExp(patt);
        return exp.test(str);
    }
}
