import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { LogService } from './log.service';
import { GenericService } from './generic.service';
import { LogLevel } from './enum';
import { GlobalService } from './global.service';
import { NetworkService, CONNECTION_ERROR } from './network.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppConfig } from './app.config';

@Injectable()
export class IonicService {
    loader: HTMLIonLoadingElement;
    subscription: any;
    searchText: string;
    dataFetcherEvent: any;

    constructor(private loadingCtrl: LoadingController, private toastCtrl: ToastController,
        public alertCtrl: AlertController, private ls: LogService, public diagnostic: Diagnostic,
        private device: Device, private appVersion: AppVersion) {

    }

    async showLoader(text: string = null, allowCancel = false) {
        if (this.loader)
            this.loader.message = text;
        else {
            text = text || 'Please wait a moment...';
            if (allowCancel)
                this.loader = await this.loadingCtrl.create({ message: text, showBackdrop: true });
            else
                this.loader = await this.loadingCtrl.create({ message: text });
            return await this.loader.present();
        }
    }

    async hideLoader() {
        const ele = document.querySelector('ion-loading');
        if (ele)
            ele.remove();

        //if (this.loader && this.loader.dismiss)
        //{
        //    //await this.loader.dismiss();
        //}
        this.loader = null;
    }

    get spinner() {
        return GlobalService.spinner;
    }

    showSpinner() {
        GlobalService.spinner = true;
    }

    hideSpinner() {
        GlobalService.spinner = false;
    }

    async showToast(text, position: any = 'top', cssClass = '') {
        await this.hideLoader();
        await this.hideSpinner();
        const toast = await this.toastCtrl.create({
            message: text,
            duration: 3000,
            position: position,
            cssClass: cssClass
        });

        if (cssClass)
            toast.cssClass = cssClass

        await toast.present();
    }

    async showSuccessToast(text: string, position = null) {
        await this.showToast(text, position, 'success-toast');
    }

    async showErrorToast(err: any, remarks: string = null, log = true, position = null) {
        let msg = GenericService.fetchErrorMessage(err);
        if (msg.startsWith('ServerError: ')) {
            msg = msg.replace('ServerError: ', '');
            log = false;
        }
        await this.showToast(remarks || msg, position, 'error-toast');
        if (log)
            this.ls.log(err);
    }

    async alert(text: string, cssClass = '') {
        await this.hideLoader();
        await this.hideSpinner();


        if (text.includes('Survey already filled. Please close the survey')) {
            const obj1: any = {
                message: GenericService.replaceAll(text, '"', ''),
                buttons: [{
                    text: 'Close Survey',
                    handler: x => {
                        window.location.href = "http://www.google.com";
                    }
                }]
            };
            if (cssClass)
                obj1.cssClass = cssClass

            const alert = await this.alertCtrl.create(obj1);
            await alert.present();
        }
        else {
            const obj: any = {
                message: text,
                buttons: ['Ok'],
            };
            if (cssClass)
                obj.cssClass = cssClass

            const alert = await this.alertCtrl.create(obj);
            await alert.present();

        }


    }

    async alertSuccess(text: string) {
        await this.alert(text, 'alert-success');
    }

    async alertError(err: any, remarks: string = null, log = true) {
        let msg: string = GenericService.fetchErrorMessage(err);
        if (msg.startsWith('ServerError: ')) {
            msg = msg.replace('ServerError: ', '');
            log = false;
        }
        await this.alert(remarks || msg, 'alert-error');
        if (log)
            this.ls.log(err, LogLevel.Error, null, remarks);
    }

    async showAndLogError(err, remarks = null, silentMode = false) {
        remarks = remarks || "Something went wrong";
        if (err === CONNECTION_ERROR)
            remarks = err;
        if (!silentMode) await this.alertError(err, remarks, true);
        else
            this.ls.log(err, LogLevel.Error, null, remarks);
        await this.hideLoader();
        return err;
    }

    throwCustomError(err, remarks, logErr = true) {
        if (err === CONNECTION_ERROR)
            remarks = err;
        remarks = remarks || "Something went wrong";
        if (logErr)
            this.ls.log(err, LogLevel.Error, null, remarks);

        throw new Error(remarks);
    }

    async alertEnableData() {
        await this.hideLoader();
        await this.hideSpinner();

        if (NetworkService.isOffline()) {
            const prompt = await this.alertCtrl.create({
                header: 'Turn on Mobile data?',
                message: 'Connect to a Wi-Fi network or turn on Mobile data.',
                buttons: [
                    {
                        text: "Ok",
                    }
                ]
            });
            await prompt.present();
        }
    }

    getAppVersion() {
        if (AppConfig.isApp) {
            return this.appVersion.getVersionCode().then(versionCode => {
                return this.appVersion.getVersionNumber().then(version => {
                    return versionCode + " / " + version;
                });
            });
        }
        else
            return Promise.resolve(AppConfig.appVersion);
    }
}
