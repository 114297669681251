import { Component, OnInit } from '@angular/core';
import { MENU } from '../../../settings/menu';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthGuard } from '../../providers/auth-guard';
import { CurrentSurvey } from '../../providers/current-survey';
import { MediaService } from '../../providers/media.service';
import { SurveyType } from '../../providers/enum';
import { SurveyService } from '../../providers/survey.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    host: {
        'class': 'app-menu'
    }
})

export class MenuComponent implements OnInit
{
    menuItems: any;
    subscription: any;
    gs = GlobalService;
    cs = CurrentSurvey;

    constructor(private router: Router, private navCtrl: NavController, private surveyService: SurveyService,
        private authGuard: AuthGuard, private ms: MediaService)
    {
        router.events.subscribe((event: RouterEvent) =>
        {
            if (event instanceof NavigationStart)
                this.showHidePages();
        });
    }

    getLiClasses(item: any)
    {
        return {
            'has-sub': item.sub,
            'active': this.router.url === '/'+item.path,
            'menu-item-group': item.groupTitle,
            'disabled': item.disabled
        };
    }
    getStyles(item: any)
    {
        return {
            'background': item.bg,
            'color': item.color
        };
    }

    ngOnInit(): void
    {
        this.menuItems = GenericService.clone(MENU);
          this.showHidePages();
    }

    toggle(event: Event, item: any, el: any)
    {
        event.preventDefault();

        const items = el.menuItems;

        if (item.active)
        {
            item.active = false;
        }
        else
        {
            for (let i = 0; i < items.length; i++)
            {
                items[i].active = false;
            }
            item.active = true;
        }
    }

    async showHidePages()
    {
        setTimeout(() =>
        {
            this.menuItems.forEach((item: any) =>
            {
                if (this.cs.type === SurveyType.LivePoll && item.title === 'New Interview')
                    item.visible = false;
                else
                    item.visible = this.authGuard.canViewPage(item.path);// && location.pathname.indexOf(item.path)==-1;
            });
        });
    }

    async open(path)
    {
        if (path === '/survey')
        {
            if (location.pathname !== '/survey')
            {
                this.ms.stopAudioRecording();
                await this.ms.stopPlaying();
                this.navCtrl.navigateForward(path);
            }
            else
            {
                await this.surveyService.resetSurvey(this.cs.code);
                this.navCtrl.navigateForward(path, { queryParams: { r: GenericService.createGuid() }, skipLocationChange: true });
            }
        }
        else
        {
            await this.ms.stopAudioRecording();
            await this.ms.stopPlaying();

            if (path === '/offline-survey-card')
                path = path + '/' + this.cs.code + '/' + this.cs.title;

            this.navCtrl.navigateForward(path);
        }
    }

    //async confirmBeforeRestartSurvey()
    //{
    //    let alert = await this.ions.alertCtrl.create({
    //        header: 'Confirm Restart',
    //        message: 'Do you really want to quit this and start new survey?',
    //        buttons: [
    //            {
    //                text: 'Cancel',
    //                role: 'cancel',
    //                handler: () =>
    //                {

    //                }
    //            },
    //            {
    //                text: 'Yes',
    //                handler: async () =>
    //                {
    //                    this.ms.stopAudioRecording();
    //                    await this.ms.stopPlaying();
    //                    this.navCtrl.navigateForward(['/survey'], { queryParams: { r: GenericService.createGuid() }, skipLocationChange: true });
    //                }
    //            }
    //        ]
    //    });
    //    await alert.present();
    //}

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
