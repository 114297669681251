import { NgModule } from '@angular/core';
import {IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuComponent } from './menu/menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
    ],
    declarations: [MenuComponent],
    exports: [MenuComponent]
})

export class UiModule {}
