import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { NavController, Platform } from '@ionic/angular';
import { AppConfig } from './app.config';
import { SqliteService } from './sqlite.service';
import { IonicService } from './ionic.service';
import { GlobalService } from './global.service';
import { CurrentSurvey } from './current-survey';

@Injectable()
export class AuthGuard implements CanActivate {
    cs = CurrentSurvey;
    constructor(public authService: AuthService, private navCtrl: NavController, private platform: Platform,
        private sqliteService: SqliteService, private ions: IonicService) { }

    canViewPage(path) {
        if (path === '/login')
            return true;

        
        // if (path.includes('uniquesurvey')) {
        //     // console.log(this.cs.code);
        //     // console.log(AppConfig.skipLogin)

        //     if (!this.cs.code)
        //         return false;

        //     if (AppConfig.skipLogin)
        //         return true;
        // }
        if (path === '/survey') {
            // console.log(this.cs.code);
            // console.log(AppConfig.skipLogin)

            if (!this.cs.code)
                return false;

            if (AppConfig.skipLogin)
                return true;
        }

        if (!GlobalService.user)
            return false;

        if (path === '/offline-survey-card' && !this.cs.code)
            return false;

        const page = GlobalService.getMenuItemByPath(path);
        if (page) {
            if (page.webOnly && AppConfig.isApp)
                return false;

            if (page.appOnly && !AppConfig.isApp)
                return false;

            if (page.permission && !AuthService.hasPermission(page.permission))
                return false;
        }

        return true;
    }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        await this.initApp();
        await this.authService.isLoggedin();

        if (!GlobalService.user) {
            if (AppConfig.isApp || (!AppConfig.isApp && !AppConfig.skipLogin)) {
                this.navCtrl.navigateRoot('/login');
                return false;
            }
        }
       // console.log(GlobalService.user)
        const canView = await this.canViewPage(state.url);
        return canView;
    }

    async initApp() {
        if (AppConfig.isApp && !AppConfig.isAppLoaded) {
            try {
                await this.platform.ready();
                await this.ions.diagnostic.requestCameraAuthorization();
                await this.ions.diagnostic.requestLocationAuthorization();
                await this.ions.diagnostic.requestExternalStorageAuthorization();
                await this.ions.diagnostic.requestMicrophoneAuthorization();

                await this.sqliteService.init(null);
                AppConfig.isAppLoaded = true;
            }
            catch
            {
                //This catch is just to avoid permission error on livereload
            }
        }
    }
}