import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage';
import { AjaxService } from "./ajax.service";
import { AppConfig } from './app.config';
import { CurrentSurvey } from './current-survey';
import { SurveyStatus, SurveyType, QuotaType } from './enum';
import { GenericService } from './generic.service';
import { GlobalService } from './global.service';
import { IonicService } from "./ionic.service";
import { MediaService } from './media.service';
import { ScriptService } from "./script.service";
import { SqliteService } from "./sqlite.service";
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { HttpClient } from '@angular/common/http';
import { Styler } from './styler';

@Injectable()
export class SurveyService {
    gs = GenericService;
    cs = CurrentSurvey;

    constructor(private sqliteService: SqliteService, private storage: Storage, private http: HttpClient,
        private ms: MediaService, private ajaxService: AjaxService, private device: Device, private ions: IonicService,
        private ss: ScriptService, private batteryStatus: BatteryStatus
    ) {
    }

    async downloadSurvey(code: string): Promise<any> {
        const survey = await this.ajaxService.callGetService('survey/details?code=' + code, !GlobalService.user).toPromise();
        survey.Code = code;
        if (survey) {
            if (AppConfig.isApp) {
                await this.ms.setupDirStructure(code);
                await this.sqliteService.init(code);
                await this.ms.downloadProjectAssets(code);
            }

            if (AppConfig.isApp)
                await this.sqliteService.saveSurvey(survey);

            return survey;
        }

        throw new Error("Survey " + code + " is empty");
    }

    async loadSurvey(code: string): Promise<any> {
        let survey;
        if (AppConfig.isApp) {
            survey = await this.sqliteService.getSurvey(code);
            if (!survey)
                survey = await this.downloadSurvey(code);
            else
                await this.ms.setupDirStructure(code);
        }
        else
            survey = await this.downloadSurvey(code);

        return survey;
    }

    async resetSurvey(code) {
        this.cs.code = code;
        this.cs.title = null;
        this.cs.type = null;
        this.cs.config = null;
        this.cs.page = null;

        if (AppConfig.isApp)
            await this.storage.set("CurrentSurvey", this.cs.code);
    }

    async updateSchema() {
        await this.ions.showLoader();
        let survey = await this.downloadSurvey(this.cs.code);
        survey = await this.formatSurvey(survey);

        const url = "survey/updateQuestionnaire?surveyCode=" + this.cs.code;
        survey.Questionnaire.Loops = {};
        const [err] = await this.gs.to(this.ajaxService.postData(url, survey.Questionnaire).toPromise());
        if (err)
            return this.ions.alertError(err);

        await this.ions.hideLoader();
        alert("Schema updated successfully");
    }

    async init(survey) {
        this.cs.code = survey.Code;
        this.cs.title = survey.Title;
        this.cs.type = survey.Type;
        this.cs.page = null;
        this.cs.respId = 0;

        this.cs.skipped = false;
        this.cs.skippedToPageIndex = -1;
        this.cs.respVersion = 1;
        this.cs.respVersionSuffix = "";

        this.cs.uId = this.gs.createUId();
        this.cs.navHistory = [];

        this.cs.config = survey.Questionnaire.Config;
        this.cs.config.MaxAttempts = this.cs.config.MaxAttempts || 10;
        this.cs.runOnline = (this.cs.type === SurveyType.LivePoll || this.cs.config.RunOnline) ? true : false; //Lateron we will give a button in app to switch to Online/Offline mode

        if (this.cs.type === SurveyType.ChatBot)
            this.cs.config.ChatBotImage = this.cs.config.ChatBotImage || 'assets/images/bot4.png';

        this.cs.languages = survey.Questionnaire.Languages && survey.Questionnaire.Languages.length > 0 ? survey.Questionnaire.Languages : [{}];
        this.cs.cssRules = survey.Questionnaire.CssRules;
        this.cs.lang = this.cs.languages[0];
        this.cs.questions = survey.Questionnaire.Questions;
        this.cs.lists = survey.Questionnaire.Lists || [];
        this.cs.dispositions = survey.Questionnaire.Dispositions;
        this.cs.translations = survey.Translations;
        this.cs.loops = survey.Questionnaire.Loops || [];
        this.cs.sessions = survey.Questionnaire.Sessions || [];
        this.cs.includes = survey.Questionnaire.Includes || [];

        this.cs.qMap = survey.qMap;
        this.cs.listMap = survey.listMap;
        this.createPages();
    }

    createMaps(survey) {
        survey.qMap = {};
        survey.listMap = {};

        for (const quest of survey.Questionnaire.Questions) {
            survey.qMap[quest.Code] = quest;
        }

        if (survey.Questionnaire.Lists) {
            for (let j = 0; j < survey.Questionnaire.Lists.length; j++) {
                const list = survey.Questionnaire.Lists[j];
                survey.listMap[list.Code] = list;
                list.CMap = {};
                for (const choice of list.Choices) {
                    list.CMap[choice.Code] = choice;
                }
            }
        }
    }

    createCMaps(survey) {
        for (const quest of survey.Questionnaire.Questions) {
            survey.qMap[quest.Code] = quest;

            const choiceProps = ['Choices', 'Rows', 'Columns'];
            const choiceMapProps = ['CMap', 'RowMap', 'ColMap'];

            let i = 0;
            for (const prop of choiceProps) {
                if (quest[prop]) {
                    quest[choiceMapProps[i]] = {};
                    for (const choice of quest[prop]) {
                        quest[choiceMapProps[i]][choice.Code] = choice;
                    }

                    if (prop === 'Rows' || prop === 'Columns') {
                        quest[prop].forEach(obj => {
                            if (obj.Cells) {
                                obj.CellMap = {};
                                obj.Cells.forEach(x => obj.CellMap[x.Code] = x);
                            }
                            if (obj.Choices) {
                                obj.CMap = {};
                                obj.Choices.forEach(x => obj.CMap[x.Code] = x);
                            }
                        });
                    }
                }
                i++;
            }
            // let j = 0;
            // if (quest.Type === "Scroll") {
            //     quest['CMap'] = {}
            //     for (var x = quest.Min; x <= quest.Max; x++) {
            //         quest['CMap'][j] = {
            //             "Code": x,
            //             "Text": x,
            //             "QuestCode": quest.Code
            //         }
            //         // quest['CMap'][j].Code = x;
            //         // quest['CMap'][j].Text = x;
            //         // quest['CMap'][j].QuestCode = quest.Code;
            //         // // quest['CMap'][j].ObjRef = x;
            //         j++;

            //     }

            // }
        }
    }

    async removeMaps(survey) {
        delete survey.qMap;
        delete survey.listMap;

        for (const quest of survey.Questionnaire.Questions) {
            delete quest.ChoiceMap;
            delete quest.RowMap;
            delete quest.CellMap;
            delete quest.ChoiceMap;

            const choiceProps = ['Choices', 'Rows', 'Columns'];
            const choiceMapProps = ['CMap', 'RowMap', 'ColMap'];

            let i = 0;
            for (const prop of choiceProps) {
                if (quest[prop]) {
                    delete quest[choiceMapProps[i]];

                    if (prop === 'Rows' || prop === 'Columns') {
                        quest[prop].forEach(obj => {
                            delete obj.CellMap;
                            delete obj.CMap;
                        });
                    }
                }
                i++;
            }
        }
    }

    async insertScriptStyles() {
        if (this.cs.cssRules) {
            const styleSheet: any = Array.from(document.styleSheets).find(x => x.href && x.href.indexOf('/script.css') > -1);
            const rules = styleSheet.cssRules || styleSheet.rules;

            setTimeout(() => {
                this.cs.cssRules.forEach(rule => {
                    if (styleSheet && rule)
                        styleSheet.insertRule(rule, rules.length);
                });
            }, 100);
        }

        if (this.cs.type === SurveyType.ChatBot)
            Styler.addClasses('page-survey', 'chat-bot');
        else
            Styler.removeClasses('page-survey', 'chat-bot');

        if (this.cs.includes) {
            const head = document.getElementsByTagName('head')[0];
            for (let i = 0; i < this.cs.includes.length; i++) {
                const url = this.ss.formatFilePaths(this.cs.includes[i], this.cs.code);
                //if (url.indexOf('no-cache') > -1)
                //    url += "?ver=" + GenericService.getRandomNumber(1, 99); //Its not working don't know why

                if (url.endsWith('.js')) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.setAttribute('data-custom', "1");
                    script.src = url;
                    head.appendChild(script);
                }

                else if (url.endsWith('.css')) {
                    const link = document.createElement('link');
                    link.rel = 'stylesheet';
                    link.setAttribute('data-custom', "1");
                    link.href = url;
                    head.appendChild(link);
                }
            }
        }
    }

    clearPrvSurveyScriptsAndStyles() {
        const styleSheet: any = Array.from(document.styleSheets).find(x => x.href && x.href.indexOf('/script.css') > -1);
        const rules = styleSheet.cssRules || styleSheet.rules;
        while (rules.length > 0)
            styleSheet.deleteRule(rules.length - 1);

        const head = document.getElementsByTagName('head')[0];
        const scriptTags = head.querySelectorAll('script[data-custom="1"]');
        scriptTags.forEach(el => el.remove());
        const linkTags = head.querySelectorAll('link[data-custom="1"]');
        linkTags.forEach(el => el.remove());
        Styler.removeClasses('page-survey', 'chat-bot');
    }

    async formatSurvey(survey) {
        if (AppConfig.isApp) {
            const surveyFiles = await this.sqliteService.getSurveyFiles(survey.Code);
            surveyFiles.forEach(x => {
                this.cs.surveyFilesDict[x.RemotePath] = x.LocalPath;
            });
        }

        this.createMaps(survey);
        const params = GenericService.getQueryStringMap();
        Object.keys(params).forEach(key => {
            const quest = survey.Questionnaire.Questions.find(x => x.Code === key);
            if (quest)
                quest.Answer = params[key];
        });

        survey.Questionnaire.Loops = survey.Questionnaire.Loops || [];
        survey.Questionnaire.Config.ChatBotImage = this.ss.formatFilePaths(survey.Questionnaire.Config.ChatBotImage, survey.Code);
        survey.Questionnaire.Languages = this.ss.formatFilePaths(survey.Questionnaire.Languages, survey.Code) || [];
        survey.Questionnaire.CssRules = this.ss.formatFilePaths(survey.Questionnaire.CssRules, survey.Code) || [];

        if (survey.Questionnaire.Lists) {
            for (let j = 0; j < survey.Questionnaire.Lists.length; j++) {
                const list = survey.Questionnaire.Lists[j];
                this.buildChoices(list, 'Choices', survey);
            }
        }

        for (const quest of survey.Questionnaire.Questions) {
            this.formatQuestion(survey, quest);
        }

        this.createCMaps(survey); //Need to call it again Otherwise Choices which are coming from list dows not populate in CMap. 
        this.processLoops(survey);
        this.createCMaps(survey); //Need to call it again Otherwise Choices which are coming from list dows not populate in CMap. 

        return survey;
    }

    formatQuestion(survey, quest) {
        this.replaceItrInQuestion(quest);
        if (quest.Type === 'SingleSelect' || quest.Type === 'MultiSelect' || quest.Type === 'Ranking') {
            if (quest.Type === 'Ranking' && !quest.Mode)
                // quest.Mode = 'DragDrop';
                quest.Mode = 'DragDropOld';

            if (quest.Type === 'SingleSelect' && quest.Mode === 'Slider') {
                quest.SliderSingleSelect = true
            }
            this.buildChoices(quest, 'Choices', survey, quest.Itr, quest.ItrNext);
            quest.Choices.forEach(x => { x.QuestCode = quest.Code, x.ObjRef = quest.Code + '$C' + x.Code });
        }
        // if (quest.Type === 'Scroll') {
        //     this.buildscrollChoices(quest, 'Choices', survey, quest.Itr, quest.ItrNext);
        //     quest.Choices.forEach(x => { x.QuestCode = quest.Code, x.ObjRef = quest.Code + '$C' + x.Code });

        // }

        if (quest.Type === 'Grid' || quest.Type === 'DartBoard') {
            quest.Direction = quest.Direction === 'Column' ? 'Column' : 'Row';

            if (quest.Mode === 'Slider' || quest.Mode === 'Slider2') {
                quest.SliderSingleSelect = false
            }

            //do not randomize grid. As it not capable enough to randomize properly


            this.buildChoices(quest, 'Columns', survey, quest.Itr, quest.ItrNext);
            this.buildChoices(quest, 'Rows', survey, quest.Itr, quest.ItrNext);

            for (let i = 0; i < quest.Rows.length; i++) {
                const row = quest.Rows[i];
                row.QuestCode = quest.Code;
                row.ObjRef = quest.Code + '$R' + row.Code;


                if (quest.Direction === "Row") {
                    if (row.Mode === "DropDown")
                        this.buildChoices(row, 'Choices', survey, quest.Itr, quest.ItrNext);

                    if (quest.Required === false)
                        row.Required = false;

                    if (quest.Mode === 'DragDrop' || quest.Mode === 'DragDropOld' || quest.Mode === 'Slider' || quest.Mode === 'Slider2')
                        row.Type = 'SingleSelect';
                }

                for (let j = 0; j < quest.Columns.length; j++) {
                    const col = quest.Columns[j];
                    col.QuestCode = quest.Code;
                    col.ObjRef = quest.Code + '$C' + col.Code;

                    if (!row.Cells || !row.Cells[j]) {
                        row.Cells = row.Cells || [];
                        row.Cells[j] = {};
                    }

                    const cell = row.Cells[j];
                    cell.QuestCode = quest.Code;
                    cell.SubQuestCode = quest.Direction === "Row" ? row.Code : col.Code;
                    cell.ObjRef = quest.Code + "$R" + row.Code + "$C" + col.Code;
                    cell.Suffix = quest.Direction === "Row" ? row.Suffix : col.Suffix;
                    cell.Prefix = quest.Direction === "Row" ? row.Prefix : col.Prefix;
                    cell.Regex = quest.Direction === "Row" ? row.Regex : col.Regex;

                    //cell.Type = "SingleSelect" 
                    col.Cells = col.Cells || [];
                    col.Cells.push(cell);


                    let q;
                    if (quest.Direction === 'Row') {
                        cell.Code = col.Code;
                        cell.Color = col.Color;
                        cell.Exclusive = col.Exclusive;
                        q = row;
                    }
                    else {
                        cell.Code = row.Code;
                        cell.Color = row.Color;
                        cell.Exclusive = row.Exclusive;
                        q = quest.Columns[j];
                    }

                    cell.Type = q.Type;
                    cell.Mode = q.Mode;
                    cell.Min = q.Min;
                    cell.Max = q.Max;

                    if (cell.Hidden === undefined)
                        cell.Hidden = row.Hidden === undefined ? col.Hidden : row.Hidden;

                    if (quest.Direction === 'Row' && row.Required === false)
                        cell.Required = false;

                    if (quest.Direction === 'Column' && col.Required === false)
                        cell.Required = false;

                    if (cell.Mode === "DropDown")
                        cell.Choices = this.cloneChoices(q.Choices);
                }
            }

            for (let j = 0; j < quest.Columns.length; j++) {
                const col = quest.Columns[j];
                this.buildChoices(col, 'Cells', survey, quest.Itr, quest.ItrNext);
                if (quest.Direction === "Column" && col.Mode === "DropDown")
                    this.buildChoices(col, 'Choices', survey, quest.Itr, quest.ItrNext);

                if (quest.Direction === 'Column' && quest.Required === false)
                    col.Required = false;

                if (quest.Mode === 'DragDrop' || quest.Mode === 'DragDropOld' || quest.Mode === 'Slider' || quest.Mode === 'Slider2')
                    col.Type = 'SingleSelect';
            }
        }
    }

    formatFilePathsInQuest(quest, surveyCode) {
        quest.Text = this.ss.pipeInAndFormatFilePaths(quest, 'Text', surveyCode);
        quest.GridTitle = this.ss.pipeInAndFormatFilePaths(quest, 'GridTitle', surveyCode);
        quest.Prefix = this.ss.pipeInAndFormatFilePaths(quest, 'Prefix', surveyCode);
        quest.Suffix = this.ss.pipeInAndFormatFilePaths(quest, 'Suffix', surveyCode);
        quest.Image = this.ss.pipeInAndFormatFilePaths(quest, 'Image', surveyCode);
        quest.VoiceOver = this.ss.pipeInAndFormatFilePaths(quest, 'VoiceOver', surveyCode);

        const props = ['Choices', 'Rows', 'Columns'];
        props.forEach(prop => {
            if (quest[prop]) {
                quest[prop].forEach(choice => {
                    choice.Text = this.ss.pipeInAndFormatFilePaths(choice, 'Text', surveyCode);
                    choice.Image = this.ss.pipeInAndFormatFilePaths(choice, 'Image', surveyCode);
                    if (prop === 'Rows' || prop === 'Columns')
                        choice.OnChange = this.ss.pipeInAndFormatFilePaths(choice, 'OnChange', surveyCode);
                });
            }
        });
    }

    buildscrollChoices(questOrList, choiceProp, survey, itr = null, itrNext = null) {
        questOrList[choiceProp] = questOrList[choiceProp] || []; //In case of grid it can be Columns, Rows or Cells
        let choices = [];
        let j = 0;
        for (var x = questOrList.Min; x <= questOrList.Max; x++) {
            questOrList[choiceProp][j] = {
                "Code": x,
                "Text": x,
            }
            j++;
        }

        for (const choice of questOrList[choiceProp]) {
            this.replaceItrInChoices(choice, itr, itrNext);
            if (choice.List) {
                if (choice.List.indexOf('#ITR_') > -1)
                    return;

                const list = survey.listMap[choice.List];

                if (!list) {
                    this.ions.alertError("Invalid List " + choice.List);
                    return;
                }

                if (!list.Choices) {
                    this.ions.alertError("List " + choice.List + " is empty");
                    return;
                }

                let choices1;
                if (!choice.Pick)
                    choices1 = this.cloneChoices(list.Choices);
                else {
                    let selectedChoiceCodes = [];
                    const arr1 = choice.Pick.split(',');

                    arr1.forEach(y => {
                        const n = y.trim();
                        const choice = list.Choices.find(x => x.Code == n);
                        if (choice)
                            selectedChoiceCodes.push(choice.Code);
                        else {
                            const arr2 = y.split('-');
                            if (arr2.length === 2) //It is a range (It will ignore non numeric choice codes
                            {
                                const range = list.Choices.filter(x => !isNaN(x.Code) && x.Code >= Number(arr2[0]) && x.Code <= Number(arr2[1])).map(x => x.Code);
                                selectedChoiceCodes = selectedChoiceCodes.concat(range);
                            }
                        }
                    });

                    choices1 = list.Choices.filter(x => selectedChoiceCodes.indexOf(x.Code) > -1); //This was necessary to pick the choices in the order in which those were given in source list
                    choices1 = this.cloneChoices(choices1);
                }

                choices1.forEach(x => {
                    x.Code = parseInt(x.Code);
                    x.ParentList = list.Code;
                    if (choice.Image) x.Image = choice.Image;
                    if (choice.Type) x.Type = choice.Type;
                    if (choice.Mode) x.Mode = choice.Mode
                });
                choices = choices.concat(choices1);
            }
            else {
                choice.Code = parseInt(choice.Code);
                choices.push(choice);
            }
        }
        questOrList[choiceProp] = choices;
        if (questOrList.Randomize)
            this.ss.randomizeChoices(questOrList.Code, choiceProp);
    }
    buildChoices(questOrList, choiceProp, survey, itr = null, itrNext = null) {
        questOrList[choiceProp] = questOrList[choiceProp] || []; //In case of grid it can be Columns, Rows or Cells
        let choices = [];
        for (const choice of questOrList[choiceProp]) {
            this.replaceItrInChoices(choice, itr, itrNext);
            if (choice.List) {
                if (choice.List.indexOf('#ITR_') > -1)
                    return;

                const list = survey.listMap[choice.List];

                if (!list) {
                    this.ions.alertError("Invalid List " + choice.List);
                    return;
                }

                if (!list.Choices) {
                    this.ions.alertError("List " + choice.List + " is empty");
                    return;
                }

                let choices1;
                if (!choice.Pick)
                    choices1 = this.cloneChoices(list.Choices);
                else {
                    let selectedChoiceCodes = [];
                    const arr1 = choice.Pick.split(',');

                    arr1.forEach(y => {
                        const n = y.trim();
                        const choice = list.Choices.find(x => x.Code == n);
                        if (choice)
                            selectedChoiceCodes.push(choice.Code);
                        else {
                            const arr2 = y.split('-');
                            if (arr2.length === 2) //It is a range (It will ignore non numeric choice codes
                            {
                                const range = list.Choices.filter(x => !isNaN(x.Code) && x.Code >= Number(arr2[0]) && x.Code <= Number(arr2[1])).map(x => x.Code);
                                selectedChoiceCodes = selectedChoiceCodes.concat(range);
                            }
                        }
                    });

                    choices1 = list.Choices.filter(x => selectedChoiceCodes.indexOf(x.Code) > -1); //This was necessary to pick the choices in the order in which those were given in source list
                    choices1 = this.cloneChoices(choices1);
                }

                choices1.forEach(x => {
                    x.Code = parseInt(x.Code);
                    x.ParentList = list.Code;
                    if (choice.Image) x.Image = choice.Image;
                    if (choice.Type) x.Type = choice.Type;
                    if (choice.Mode) x.Mode = choice.Mode
                });
                choices = choices.concat(choices1);
            }
            else {
                choice.Code = parseInt(choice.Code);
                choices.push(choice);
            }
        }
        questOrList[choiceProp] = choices;
        if (questOrList.Randomize)
            this.ss.randomizeChoices(questOrList.Code, choiceProp);
    }

    createPages() {
        let pageIndex = 0;
        let AllowBack = true;
        this.cs.questions.forEach(quest => {
            this.cs.qMap[quest.Code] = quest;
            quest.PageIndex = pageIndex;
            if (quest.PageBreak !== false)
                pageIndex++;
        });

        this.cs.pages = [];
        // if(this.cs.questions.filter(x=> x.AllowBack == "false").length > 0){
        //     AllowBack = "false";
        // }

        this.cs.questions.forEach(quest => {

            // if (quest.Code == "EK01") {
            //     debugger;
            // }
            if (quest.AllowBack == "false") {
                AllowBack = false;
            } else
                AllowBack = true;

            this.cs.pages[quest.PageIndex] = this.cs.pages[quest.PageIndex] || { Questions: [], Index: quest.PageIndex, AllowBack: AllowBack };
            // this.cs.pages[quest.PageIndex] = this.cs.pages[quest.PageIndex] || { Questions: [], Index: quest.PageIndex };
            this.cs.pages[quest.PageIndex].Questions.push(quest);

        });
    }

    retrieveAllAnswersToSave(qMap) {
        //qMap = GenericService.clone(qMap);
        for (const key in qMap) {
            this.retrieveAnswer(qMap[key]);
        }
        return qMap;
    }

    retrieveAnswersToSave(startIndex, endIndex, prev = false) //In case of prev reset the answer;
    {
        const qMap = {};
        if (endIndex < startIndex) {
            const s = startIndex;
            startIndex = endIndex;
            endIndex = s;
        }
        for (let i = startIndex; i <= endIndex; i++) {
            const page = this.cs.pages[i];
            for (let j = 0; j < page.Questions.length; j++) {
                const q = page.Questions[j];
                var CheckHidden = 0;
                // Adding Below Code To Save HiddenResponse In DB 
                if (q.FHidden) {
                    q.Hidden = false;
                    CheckHidden = 1;
                }

                if (q.Type !== 'Instruction' && !q.Hidden && !q.Skipped) {
                    const quest = this.retrieveAnswer(q, prev);
                    qMap[quest.Code] = quest;

                    if (q.IsQuota) {
                        qMap[quest.Code + "Quota" + quest.Answer] = {
                            "Code": quest.Code + "Quota" + quest.Answer,
                            "Answer": quest.Answer,
                            "Type": "OpenEnd"
                        };
                    }
                    if (quest.Code == "A11") {
                        qMap['PrimaryProduct'] = this.gs.clone(quest);
                        qMap['PrimaryProduct'].Code = 'PrimaryProduct';
                        qMap['ProductsCount'] = {
                            "Code": "ProductsCount",
                            "Type": "OpenEnd",
                            "Answer": 1
                        }
                    }

                    else if (quest.Code.startsWith("M") || quest.Code.startsWith("N") || quest.Code.startsWith("O")) {
                        const varArray = ["M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8", "M9", "M10", "M11", "N1", "N2", "N3", "O5"];
                        for (let k = 0; k <= varArray.length; k++) {
                            if (quest.Code.includes(varArray[k] + "x")) {
                                qMap[varArray[k]] = this.gs.clone(quest);
                                qMap[varArray[k]].Code = varArray[k];
                            }
                        }
                    }
                    else if (quest.Code.startsWith("Q1x")) {

                        // if (quest.Code.includes("Q1x" + this.cs.qMap['A11'].Answer)) {
                        //     qMap['SecondaryProduct1'].Answer = this.cs.qMap['A11'].Answer;
                        //     qMap['SecondaryProduct1'].Code = 'SecondaryProduct1';
                        // }

                        const varArray = ["A11", "SecondaryProduct1", "SecondaryProduct2", "SecondaryProduct3", "SecondaryProduct4", "SecondaryProduct5", "SecondaryProduct6", "SecondaryProduct7", "SecondaryProduct8", "SecondaryProduct9", "SecondaryProduct10"];

                        for (let k = 1; k <= varArray.length; k++) {
                            if (quest.Code.includes("Q1x" + this.cs.qMap[varArray[k - 1]].Answer)) {
                                const questProduct = this.retrieveAnswer(this.cs.qMap["Q1x" + this.cs.qMap[varArray[k - 1]].Answer], prev);
                                qMap[varArray[k]] = this.gs.clone(questProduct);
                                qMap[varArray[k]].Code = varArray[k];

                                qMap['ProductsCount'] = {
                                    "Code": "ProductsCount",
                                    "Type": "OpenEnd",
                                    "Mode": "Numeric",
                                    "Answer": k + 1
                                }

                            }
                        }
                    }
                }

                // Adding Below Code To Save HiddenResponse In DB 

                if (CheckHidden === 1)
                    q.Hidden = true;
            }
        }

        this.addSysVar("sys_version", this.cs.respVersion, qMap);

        const lastQuest = this.cs.qMap['sys_last_quest' + this.cs.respVersionSuffix];
        if (lastQuest) {
            qMap['sys_last_quest' + this.cs.respVersionSuffix] = this.cloneQuestion(lastQuest);
            qMap['sys_last_quest'] = this.cloneQuestion(lastQuest);
        }
        return qMap;
    }

    retrieveAnswer(quest, prev = false) {
        const questNew: any = { Code: quest.Code, Type: quest.Type, Mode: quest.Mode };

        if (!quest.Type || quest.Type === "OpenEnd" || quest.Type === "SingleSelect") {
            if (quest.Type === "SingleSelect") {
                if (quest.Hidden || prev) {
                    questNew.Choices = [];
                    const choice = quest.Choices.find(x => x.Other);
                    if (choice)
                        questNew.Choices.push({ Other: true }); //This is to reset OtherAnswer
                }
                else {
                    if (!this.ss.isAnswered(quest)) {
                        questNew.Answer = 0;
                    }
                    else {
                        questNew.Answer = quest.Answer;
                        questNew.Choices = quest.Choices.filter(x => x.Code === quest.Answer).map(y => {
                            const choice: any = { Code: y.Code };
                            if (quest.IsQuota)
                                choice.Quota = y.Quota;
                            if (y.Other)
                                choice.Other = y.Other;

                            return choice;
                        });

                        if (quest.OtherAnswer !== null && quest.OtherAnswer !== undefined)
                            questNew.OtherAnswer = quest.OtherAnswer;
                    }
                }
            }
            else
                //Adding Below To Code To Allow Unique Entery  
                //
                if (quest.Unique) {
                    questNew.Answer = quest.Answer;
                    questNew.Unique = quest.Unique;

                }
                //
                else if (!quest.Hidden && !prev) {
                    questNew.Answer = quest.Answer;
                    if (quest.Unique)
                        questNew.Unique = quest.Unique;
                    if (quest.PrependToAnswer)
                        questNew.PrependToAnswer = quest.PrependToAnswer;
                    if (quest.AppendToAnswer)
                        questNew.AppendToAnswer = quest.AppendToAnswer;
                }
        }

        else if (quest.Type === "HotSpot" && !quest.Hidden && !prev)
            questNew.Answers = quest.Answers;

        else if (quest.Type === "Scroll" && !quest.Hidden && !prev)
            questNew.Answer = quest.Answer;

        else if (quest.Type === "MultiSelect") {
            questNew.Choices = quest.Choices.map(y => {
                const choice: any = { Code: y.Code };
                if (quest.Hidden || y.Hidden || prev)
                    choice.Selected = null;
                else if (y.Selected)
                    choice.Selected = true;
                else
                    choice.Selected = false;

                if (y.Other) {
                    choice.Other = y.Other;
                    choice.OtherAnswer = prev ? null : y.OtherAnswer;
                }
                return choice;

            }).filter(x => !x.isHeader);
        }
        else if (quest.Type === "OpenEndLoop") {
            questNew.Choices = quest.Choices.map(y => {
                const choice: any = { Code: y.Code };
                if (quest.Hidden || y.Hidden || prev)
                    choice.Selected = null;
                else if (y.Selected)
                    choice.Selected = true;
                else
                    choice.Selected = false;

                if (y.Other) {
                    choice.Other = y.Other;
                    choice.OtherAnswer = prev ? null : y.OtherAnswer;
                }
                return choice;

            }).filter(x => !x.isHeader);
        }

        else if (quest.Type === "Ranking") {
            questNew.Choices = quest.Choices.map(y => {
                const choice: any = { Code: y.Code };
                if (quest.Hidden || y.Hidden || prev)
                    choice.Answer = null;
                else
                    choice.Answer = y.Answer;

                return choice;
            }).filter(x => !x.isHeader);
        }

        else if (quest.Type === "Grid" || quest.Type === "DartBoard") //DartBoard can only be Single Selection Row Grid
        {
            questNew.Direction = quest.Direction;
            //Grid heavily uses index. So we will have to add every row/col/cell even if it is Hidden
            const arr = [];
            const questProp = quest.Direction === 'Row' ? 'Rows' : 'Columns';
            const oppProp = quest.Direction === 'Row' ? 'Columns' : 'Rows';

            quest[questProp].forEach((obj) => {
                const newObj: any = { Code: obj.Code, Type: obj.Type, Mode: obj.Mode, Cells: [] };
                if (quest.Mode === 'DragDrop' || quest.Mode === 'DragDropOld' || (obj.Type === 'SingleSelect' && obj.Mode !== 'DropDown') || quest.Mode === 'ConstantSumKnob') //1 row 1 Answer
                {
                    if (quest.Hidden || obj.Hidden || prev)
                        newObj.Answer = null;
                    else if (!this.ss.isAnswered(obj))
                        newObj.Answer = 0;
                    else
                        newObj.Answer = obj.Answer;
                    arr.push(newObj);
                }
                else {
                    obj.Cells.forEach((cell, j) => {
                        if (obj.Type === 'MultiSelect') {
                            const newCell: any = { Code: cell.Code };

                            if (quest.Hidden || obj.Hidden || quest[oppProp].Hidden || cell.Hidden || prev)
                                newCell.Selected = null;
                            else if (cell.Selected)
                                newCell.Selected = true;
                            else
                                newCell.Selected = false;

                            newObj.Cells.push(newCell);
                        }
                        else if (obj.Type === 'SingleSelect' && obj.Mode === 'DropDown') {
                            const newCell = { Code: cell.Code, Answer: cell.Answer, Hidden: cell.Hidden };
                            if (j < quest[oppProp].length && (quest.Hidden || obj.Hidden || quest[oppProp][j].Hidden || cell.Hidden || prev))
                                newCell.Answer = null;
                            else if (!this.ss.isAnswered(cell))
                                newCell.Answer = 0;
                            else
                                newCell.Answer = cell.Answer;
                            newObj.Cells.push(newCell);
                        }
                        else if (!this.gs.isNullOrEmpty(cell.Answer))
                            newObj.Cells.push({ Code: cell.Code, Answer: prev ? null : cell.Answer, Hidden: cell.Hidden });
                    });
                    arr.push(newObj);
                }
            });

            questNew[questProp] = arr;
        }

        if (quest.Attachment)
            questNew.Attachment = quest.Attachment;

        if (quest.IsQuota) {
            questNew.IsQuota = quest.IsQuota;
            questNew.QuotaType = quest.QuotaType ? quest.QuotaType : QuotaType.SoftQuota;
        }
        if (quest.Quota)
            questNew.Quota = quest.Quota;

        return this.cloneQuestion(questNew);
    }

    async processLoops(survey) {
        const loops = [];
        survey.Questionnaire.Loops.forEach((loop, i) => {
            const newLoop: any = {};
            loops.push(newLoop);
            if (!loop.From || !loop.To || !loop.List)
                throw new Error("Invalid loop " + (i + 1));

            newLoop.From = survey.qMap[loop.From];
            newLoop.To = survey.qMap[loop.To];
            newLoop.List = loop.List;

            if (!newLoop.From || !newLoop.To || !newLoop.List)
                throw new Error("Invalid loop " + (i + 1));

            if (newLoop.From.PageIndex > newLoop.To.PageIndex)
                throw new Error("Invalid loop " + (i + 1) + ". " + loop.From + " comes after " + loop.To);
        });

        survey.Questionnaire.Loops = loops;

        for (let i = 0; i < loops.length; i++) {
            let x = loops[i];
            const list = survey.listMap[x.List];
            const iterations = list.Choices.filter(x => !x.Hidden);
            //We are excluding Hidden because before start of survey hidden will be only those choices which have actually been not in use now

            const questions = [];
            let quest;
            let startQuestIndex = -1;
            for (let j = 0; j < survey.Questionnaire.Questions.length; j++) {
                quest = survey.Questionnaire.Questions[j];
                if (startQuestIndex === -1 && x.From.Code === quest.Code)
                    startQuestIndex = j;

                if (startQuestIndex === -1)
                    continue;

                questions.push(quest);
                if (x.To.Code === quest.Code) {
                    if (startQuestIndex === -1)
                        throw new Error("Invalid loop " + (i + 1) + ". From and To should be reveresed");

                    break;
                }
            }

            const allQuests = [];
            for (let j = 0; j < iterations.length; j++) {
                const itr = iterations[j];
                const itrNext = iterations[j + 1];

                for (let k = 0; k < questions.length; k++) {
                    x = questions[k];
                    const quest = this.cloneQuestion(x);
                    quest.MainQuestCode = quest.Code;
                    quest.Code += "x" + itr.Code;
                    quest.Itr = { Code: itr.Code, Text: itr.Text, Loop: i };
                    if (itrNext)
                        quest.ItrNext = { Code: itrNext.Code, Text: itrNext.Text, Loop: i };

                    this.formatQuestion(survey, quest);
                    allQuests.push(quest);
                }
            }
            survey.Questionnaire.Questions.splice(startQuestIndex, questions.length, ...allQuests);

            for (let j = 0; j < questions.length; j++) {
                delete survey.qMap[questions[j].Code];
            }
        }
    }

    cloneQuestion(questToClone) {
        const quest = Object.assign({}, questToClone);
        const props = ['Choices', 'Rows', 'Columns'];
        props.forEach(prop => {
            if (quest[prop]) {
                quest[prop] = this.cloneChoices(quest[prop]);
            }
        });
        return quest;
    }

    cloneChoices(choices) {
        const list = choices;
        const newList = [];
        list.forEach((c, i) => {
            newList[i] = Object.assign({}, c);
            const cells = list[i].Cells;
            if (cells) {
                cells.forEach((cell, j) => {
                    cells[j] = Object.assign({}, cell);
                    const c = cells[j];

                    if (c.Choices) {
                        c.Choices.forEach((ch, k) => {
                            c.Choices[k] = Object.assign({}, ch);
                        });
                    }
                });
            }
        });

        return newList;
    }

    replaceItrInQuestion(quest) {
        if (quest.Itr || quest.ItrNext) {
            const props = ['Text', 'GridTitle', 'Prefix', 'Suffix', 'Image', 'VoiceOver', 'OnChange', 'PreEnter', 'PostEnter', 'PreExit'];
            for (const prop of props) {
                if (quest[prop]) {
                    let x = quest[prop];
                    if (quest.Itr) {
                        x = GenericService.replaceAll(x, "#ITR_CODE#", quest.Itr.Code);
                        x = GenericService.replaceAll(x, "#ITR_TEXT#", quest.Itr.Text);
                    }
                    if (quest.ItrNext) {
                        x = GenericService.replaceAll(x, "#ITR_NEXT_CODE#", quest.ItrNext.Code);
                        x = GenericService.replaceAll(x, "#ITR_NEXT_TEXT#", quest.ItrNext.Text);
                    }
                    quest[prop] = x;
                }
            }
        }
    }

    replaceItrInChoices(choice, itr, itrNext) {
        if (itr || itrNext) {
            const loopOn = ['List', 'Text', 'Image', 'OnChange'];
            for (const prop of loopOn) {
                if (choice[prop]) {
                    let x = choice[prop];
                    if (itr) {
                        x = GenericService.replaceAll(x, "#ITR_CODE#", itr.Code);
                        x = GenericService.replaceAll(x, "#ITR_TEXT#", itr.Text);
                    }
                    if (itrNext) {
                        x = GenericService.replaceAll(x, "#ITR_NEXT_CODE#", itrNext.Code);
                        x = GenericService.replaceAll(x, "#ITR_NEXT_TEXT#", itrNext.Text);
                    }
                    choice[prop] = x;
                }
            }

            if (choice.Cells && choice.Cells[0].Choices) {
                choice.Cells.forEach(cell => {
                    if (cell.Choices) {
                        cell.Choices.forEach(ch => {
                            if (itr) {
                                ch.Text = GenericService.replaceAll(ch.Text, "#ITR_CODE#", itr.Code);
                                ch.Text = GenericService.replaceAll(ch.Text, "#ITR_TEXT#", itr.Text);
                            }
                            if (itrNext) {
                                ch.Text = GenericService.replaceAll(ch.Text, "#ITR_NEXT_CODE#", itrNext.Code);
                                ch.Text = GenericService.replaceAll(ch.Text, "#ITR_NEXT_TEXT#", itrNext.Text);
                            }
                        });
                    }
                });
            }
        }
    }

    addSysVar(code: string, answer, qMap = null, type = 'OpenEnd') {
        qMap = qMap || this.cs.qMap;
        qMap[code] = { Code: code, Type: type, Answer: answer };
    }

    addVersionedSysVar(code, answer, qMap = null, type = 'OpenEnd') {
        this.addSysVar(code + this.cs.respVersionSuffix, answer, qMap, type);
    }

    async setStartSysVars(qMap): Promise<void> {
        if (!this.cs.respVersion) {
            this.cs.respVersion = 1;
            this.cs.respVersionSuffix = this.cs.respVersion > 1 ? "_" + this.cs.respVersion : "";

        }

        this.addSysVar('sys_survey_status', SurveyStatus.Incomplete, qMap);
        this.addSysVar('sys_language', this.cs.lang.Name, qMap);

        this.addSysVar('sys_end_device', new Date(), qMap);
        this.addSysVar('sys_end_date', new Date(), qMap);
        this.addSysVar('sys_end_time', new Date(), qMap);
        this.addSysVar('sys_elapsed_time', "0H:0D:0M:0S", qMap);

        this.addSysVar('sys_user_id', GlobalService.user ? GlobalService.user.Id : null, qMap);
        this.addSysVar('sys_uid', this.cs.uId, qMap);


        // Commenting For Anubhav Project

        // const appVersion = await this.ions.getAppVersion();
        // this.addSysVar('sys_app_version', appVersion, qMap);

        // this.setBatteryLevel(qMap);

        // if (AppConfig.isApp) {
        //     this.addSysVar('sys_device_id', this.device.uuid, qMap);
        //     this.addSysVar('sys_manufacturer', this.device.manufacturer, qMap);
        //     this.addSysVar('sys_model', this.device.model, qMap);
        //     this.addSysVar('sys_os_version', this.device.version, qMap);
        // }
    }

    async setRestartSysVars(qMap = null): Promise<void> {

        //Commenting for Anubhav Project

        this.addSysVar('sys_version', this.cs.respVersion, qMap);
        this.addSysVar('sys_end_device', new Date(), qMap);
        this.addSysVar('sys_end_date', new Date(), qMap);
        this.addSysVar('sys_end_time', new Date(), qMap);
        this.addSysVar('sys_elapsed_time', "0H:0D:0M:0S", qMap);

        // this.addVersionedSysVar('sys_restart_device', new Date(), qMap);
        this.addVersionedSysVar('sys_user_id', GlobalService.user ? GlobalService.user.Id : null, qMap);
        this.addSysVar('sys_language', this.cs.lang.Name, qMap);

        //this.setBatteryLevel(qMap, this.cs.respVersion);
    }

    getFilledResponses(keyValues): Promise<any> {
        if (!AppConfig.isApp || this.cs.runOnline) {
            return this.fillResponsesOnline(keyValues);
        }
        else {
            return this.fillResponsesOffline(keyValues);
        }
    }

    fillResponsesOnline(keyValues): Promise<any> {
        return this.ajaxService.callPostService('response/incomplete-interview?surveyCode=' + this.cs.code, keyValues, !GlobalService.user)
            .toPromise().then(questionnaire => {
                if (questionnaire) {
                    questionnaire.Questions.forEach(filledQuest => {
                        const quest = this.cs.qMap[filledQuest.Code];
                        if (!quest) //maiVars like sys_resp_id, sys_version, sys_survey_status
                        {
                            this.cs.qMap[filledQuest.Code] = filledQuest;
                            if (filledQuest.Code === 'sys_resp_id')
                                this.cs.respId = filledQuest.Answer;
                            if (filledQuest.Code === 'sys_uid')
                                this.cs.uId = filledQuest.Answer;
                        }
                        else
                            this.ss.copyAnswer(filledQuest, quest);
                    });
                    const quest = this.cs.qMap['sys_version'];
                    this.cs.respVersion = quest ? Number(this.cs.qMap['sys_version'].Answer) + 1 : 1;
                    this.cs.respVersionSuffix = this.cs.respVersion > 1 ? "_" + this.cs.respVersion : "";

                    this.cs.isRestart = true;
                }
            });
    }

    fillResponsesOffline(keyValues): Promise<any> {
        return this.sqliteService.getInterviews(this.cs.code, keyValues, 1, 1).then(list => {
            let qMap;
            if (list.length > 0) {
                qMap = list[0];

                const statusQuest = qMap['sys_survey_status'];
                if (statusQuest && statusQuest.Answer != SurveyStatus.Incomplete)
                    throw new Error("Survey already filled");
                else {
                    Object.keys(qMap).forEach(filledQuestCode => {
                        const quest = this.cs.qMap[filledQuestCode];
                        if (!quest) //mainVars like sys_version, sys_survey_status
                        {
                            this.cs.qMap[filledQuestCode] = qMap[filledQuestCode];
                            if (filledQuestCode === 'sys_uid')
                                this.cs.uId = qMap[filledQuestCode].Answer;
                        }
                        else
                            this.ss.copyAnswer(qMap[filledQuestCode], quest);
                    });
                    this.cs.respVersion = Number(this.cs.qMap['sys_version'].Answer) + 1;
                    this.cs.respVersionSuffix = this.cs.respVersion > 1 ? "_" + this.cs.respVersion : "";

                    this.cs.isRestart = true;
                }
            }
        });
    }

    setBatteryLevel(qMap, version = null) {
        const subscription = this.batteryStatus.onChange().subscribe(status => {
            if (version)
                this.addSysVar('sys_battery_level', status.level, qMap);
            else
                this.addVersionedSysVar('sys_battery_level', status.level, qMap);
            subscription.unsubscribe();
        });
    }
}
