import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { CurrentSurvey } from './current-survey';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';

@Injectable()
export class FileService
{
    static rootDirPath;
    static projectAssetsDirName = 'ProjectAssets'; static interviewFilesDirName = "InterviewFiles";
    static fileTransfer;

    cs = CurrentSurvey;
    gs = GenericService;
    surveyCode;
    silentMode;
    extensionLists = { image: ['jpg', 'gif', 'bmp', 'png'], audio: ['m4a', 'mp3', 'wav'], video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'] };

    constructor(private file: File, private webView : WebView)
    {
    }

    async getDirs(dirName: string = FileService.interviewFilesDirName, path: string = FileService.rootDirPath)
    {
        const list = await this.file.listDir(path, dirName);
        return list.filter(item => item.isDirectory);
    }

    async getFiles(dirName: string, path)
    {
        const list = await this.file.listDir(path, dirName);
        return list.filter(item => !item.isDirectory);
    }

    async checkFile(path: string, fileName: string)
    {
        //Do not use this and this.file.checkFile
        //This is rediculus. this.file.checkFile always gives not found error.
        const [err, res] = await this.gs.to(this.file.checkFile(path, fileName));
        if (err)
            return false;
        return res;
    }

    async removeProjectDir(surveyCode: string)
    {
        return await this.file.removeRecursively(FileService.rootDirPath, surveyCode);
    }

    //here is the method is used to write a file in storage  
    async writeFile(base64Data: any, dirPath: string, fileName: any)
    {
        const contentType = this.getContentType(base64Data);
        const blob = this.base64toBlob(base64Data, contentType);
        // here iam mentioned this line this.file.externalRootDirectory is a native pre-defined file path storage. You can change a file path whatever pre-defined method.  
        return this.file.writeFile(dirPath, fileName, blob, contentType);
    }

    //here is the method is used to get content type of an bas64 data  
    getContentType(base64Data: any)
    {
        const block = base64Data.split(";");
        const contentType = block[0].split(":")[1];
        return contentType;
    }

    base64toBlob(dataURI, contentType):Blob
    {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++)
        {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: contentType });
    }

    getFileName(url, withExt: boolean = true): string
    {
        const fileName: string = url.substring(url.lastIndexOf('/') + 1);
        const ext = "." + url.split('.').pop();
        return withExt ? fileName : `${fileName.replace(ext, "")}`;
    }

    getFileExtension(fileName: string): string
    {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    isImage(fileName: string): boolean
    {
        const ext = this.getFileExtension(fileName);
        return this.extensionLists.image.find(x => x.endsWith(ext)) ? true : false;
    }

    isAudio(fileName: string): boolean
    {
        const ext = this.getFileExtension(fileName);
        return this.extensionLists.audio.find(x => x.endsWith(ext)) ? true : false;
    }

    isVideo(fileName: string):boolean
    {
        const ext = this.getFileExtension(fileName);
        return this.extensionLists.video.find(x => x.endsWith(ext)) ? true : false;
    }

    getFileType(fileName: string): string
    {
        if (this.isImage(fileName))
            return "image";
        else if (this.isAudio(fileName))
            return "audio";
        else if (this.isVideo(fileName))
            return "video";
        else
            return "other";
    }

    appendTimeStamp(fileName: string): string
    {
        const ext = this.getFileExtension(fileName);
        fileName = GenericService.replaceAll(fileName, '.' + ext, '');

        const date = new Date();
        fileName = fileName + "_" + date.getDate() + (date.getMonth() + 1) + date.getFullYear() + date.getHours() + date.getMinutes() + date.getSeconds();
        if (ext)
            fileName += "." + ext;

        return fileName;
    }

    pathToSrc(path):string
    {
        return this.webView.convertFileSrc(path);
    }

    getInterviewFileName(surveyCode: string, respId: number,uId: string, questCode:string, ext:string):string
    {
        const fileName = uId + '_' + questCode + '_' + surveyCode + '.'+ext;
        //fileName = this.appendTimeStamp(fileName);
        return fileName
    }
}

