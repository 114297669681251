import { Injectable } from '@angular/core';
import { AjaxService } from "./ajax.service";
import { AppConfig } from './app.config';
import { CurrentSurvey } from './current-survey';
import { SurveyStatus, SurveyType ,QuotaType} from './enum';
import { GenericService } from './generic.service';
import { GlobalService } from './global.service';
import { IonicService } from "./ionic.service";
import { MediaService } from './media.service';
import { ScriptService } from "./script.service";
import { SqliteService } from "./sqlite.service";
import { RegexService } from './regex.service';
import { SurveyService } from './survey.service';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { SyncService } from './sync.service';
import { NetworkService } from './network.service';

declare const globalConfig: any;

@Injectable()
export class SurveyPageService {
    gs = GenericService;
    cs = CurrentSurvey;
    mode = '';
    autoTest = false;
    autoTestsDone = 0;
    autoTestToBeDone = 0;
    retry = false;
    styleSheet;
    langOptions: any[];
    disableNext = true;
    storage;
    pages = []; //Pages to show on screen in ChatBot
    saving = false;
    dots = false;
    eventListener;

    constructor(private sqliteService: SqliteService, private surveyService: SurveyService, private ions: IonicService,
        private ms: MediaService, private ajaxService: AjaxService, private ss: ScriptService, private tts: TextToSpeech, private syncService: SyncService
    ) {
    }

    async renderSurvey() {
        this.pages = [];
        this.retry = false;
        await this.ions.showLoader();

        if (!this.cs.code) {
            if (AppConfig.isApp)
                this.cs.code = await this.storage.get("CurrentSurvey");
            else
                this.cs.code = globalConfig.surveyCode;
        }

        if (AppConfig.isApp)
            await this.storage.set("CurrentSurvey", this.cs.code);
        this.surveyService.clearPrvSurveyScriptsAndStyles();

        const survey = await this.surveyService.loadSurvey(this.cs.code);

        // let today = new Date();
        // let dd = String(today.getDate()).padStart(2, '0');
        // let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        // let yyyy = today.getFullYear();
        // today = dd + '-' + mm + '-' + yyyy;
        // console.log(today);

        var isLinkExpired = false;
        var currentDate = new Date()
        var CDate = currentDate.getDate() + '-' + (currentDate.getMonth() < 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) + '-' + currentDate.getFullYear()
        this.cs.title = survey.Title;
        await this.surveyService.formatSurvey(survey);
        await this.surveyService.init(survey);
        await this.surveyService.insertScriptStyles();

        this.setLangOptions();
        const langName = await this.storage.get(this.cs.code + '-language');
        if (langName && this.cs.languages.find(x => x.Name === langName))
            this.setLanguage(langName);
        else
            this.setLanguage(this.cs.languages[0].Name);

        if (!AppConfig.isApp) {
            const DataMap = this.gs.getQueryStringMap()
            this.cs.ExpiryData = DataMap['ed'] ? DataMap['ed'] : null;

            if (this.cs.ExpiryData) {
                isLinkExpired = CDate > this.cs.ExpiryData.toLocaleString() ? true : false;
            }

            this.cs.BankSector = DataMap['bs'] ? DataMap['bs'] : null;
            this.cs.BankBranch = DataMap['bb'] ? DataMap['bb'] : null;
            this.cs.TokenId = DataMap['tid'] ? DataMap['tid'] : null;
            this.cs.ApplicationName = DataMap['an'] ? DataMap['an'] : null;

            this.cs.State = DataMap['s'] ? DataMap['s'] : null;
            this.cs.City = DataMap['c'] ? DataMap['c'] : null;
            this.cs.BranchOfBank = DataMap['bob'] ? DataMap['bob'] : null;
            this.cs.IFSC = DataMap['i'] ? DataMap['i'] : null;

            this.cs.Tier = DataMap['t'] ? DataMap['t'] : null;
            this.cs.Region = DataMap['r'] ? DataMap['r'] : null;
            this.cs.Age = DataMap['a'] ? DataMap['a'] : null;
            this.cs.Gender = DataMap['g'] ? DataMap['g'] : null;
            this.cs.Product1 = DataMap['p1'] ? DataMap['p1'] : null;
            this.cs.Product2 = DataMap['p2'] ? DataMap['p2'] : null;
            this.cs.Product3 = DataMap['p3'] ? DataMap['p3'] : null;
            this.cs.Product4 = DataMap['p4'] ? DataMap['p4'] : null;
            this.cs.Product5 = DataMap['p5'] ? DataMap['p5'] : null;
        }


        if (this.cs.config.OnLoad)
            this.ss.evalExp(this.cs.config.OnLoad, ' - OnLoad');
        await this.ions.hideLoader();

        if (isLinkExpired)
            this.changeMode('linkexpired');

        else if (this.autoTest && this.autoTestsDone > 0 && this.autoTestsDone < this.autoTestToBeDone)
            await this.startSurvey(this.cs.lang);

        else if (this.cs.config.ShowStartPage === false)
            await this.startSurvey(this.cs.lang);

        else if (!AppConfig.isApp && this.cs.TokenId) {
            const keyValues = {
                "QTokenId": this.cs.TokenId
            }
            await this.GetTokenStatus(keyValues);
        }
        else
            this.changeMode('welcome');
    }

    GetTokenStatus(keyValues): Promise<any> {
        return this.ajaxService.callPostService('response/tokenidstatus?surveyCode=' + this.cs.code, keyValues, !GlobalService.user)
            .toPromise().then(successmsg => {
                if (successmsg == "Already Filled Survey")
                    this.changeMode('surveyalreadyfilled');
                else
                    this.changeMode('welcome');
                console.log(successmsg);
                // debugger;
            });
    }

    attachEventListener() {
        if (!AppConfig.isApp) {
            // if (this.eventListener)
            //     window.removeEventListener('beforeunload', this.eventListener);
            // else {
            //     this.eventListener = window.addEventListener('beforeunload', function (e) {
            //         if (location.href.indexOf('/survey') > -1 || location.href.indexOf('/uniquesurvey') > -1) {
            //             console.log("Testing")
            //             e.preventDefault();
            //             e.returnValue = '';
            //         }
            //     });
            // }
        }
    }

    async startSurvey(lang) {
        try {
            if (lang)
                this.cs.lang = lang;

            if (this.cs.skipped)
                await this.loadPage(this.cs.skippedToPageIndex);
            else {
                await this.move();
            }

            this.cs.isRunning = true;
            this.attachEventListener();
            this.changeMode('survey');
        }
        catch (err) {
            await this.ions.alertError(err);
        }
    }

    async move(fromPageIndex = null, prv = false) //move sequential
    {
        let pageIndex, moved;
        if (fromPageIndex !== null)
            pageIndex = fromPageIndex;
        else {
            if (!this.cs.page) //On survey start
                pageIndex = -1;
            else
                pageIndex = this.cs.page.Index;
        }

        if (prv) {
            let navIndex = this.cs.navHistory.length - 1;
            if (navIndex > 0) {
                pageIndex = this.cs.navHistory[--navIndex];
                const uniqueQuest = this.cs.pages[pageIndex].Questions.find(x => x.Unique);
                moved = uniqueQuest ? false : true;

                if (moved) {
                    if (!this.cs.page.Questions.find(x => x.Code === 'sys_disp' + this.cs.respVersionSuffix))
                        await this.save(pageIndex + 1, this.cs.page.Index, true);//Reset current page and hidden pages on the way
                    await this.loadPage(pageIndex);
                }
            }
        }
        else {
            while (1) {
                if (pageIndex === -1 || pageIndex < this.cs.pages.length - 1)
                    pageIndex++;

                if (pageIndex === fromPageIndex)
                    break;

                for (const quest of this.cs.pages[pageIndex].Questions) {
                    quest.ForceHidden = false;
                    if (quest.FHidden == true)
                        quest.Hidden = true;
                    if (quest.PreEnter) //Later on PreEnter will not be needed. Skip will be handled by Skip prop ##DEPRECATED
                    {
                        this.ss.evalExp(quest.PreEnter, quest.Code + ' - PreEnter');
                        if (this.cs.skipped) {
                            pageIndex = this.cs.skippedToPageIndex;
                            this.cs.skipped = false;
                            this.cs.skippedToPageIndex = -1
                            break; //If a page has multiple questions, it will not execute PreEnter of other questions after skip
                        }
                    }

                    if (quest.Skip && quest.Skip.Type === 'Enter') {
                        const flag = this.ss.callFn(quest.Skip.Logic, quest.code + " skip logic");
                        if (flag) {
                            const toQuest = this.cs.qMap[quest.Skip.To];    //Add condition to see if question is mentioned in "To"
                            pageIndex = toQuest.PageIndex;
                            break; //If a page has multiple questions, it will not execute PreEnter of other questions after skip
                        }
                    }
                }

                const quests = this.cs.pages[pageIndex].Questions.filter(x => !x.Hidden);
                for (const quest of quests) {
                    if (quest.Type === 'SingleSelect' || quest.Type === 'MultiSelect') //Autoselect/hide if only 1 choice /no choice visible
                    {
                        const choices = this.ss.getVisibleChoices(quest);
                        if (choices.length === 0)
                            quest.ForceHidden = true;
                        else if (choices.length === 1 && !choices[0].Other) {
                            if (quest.Type === 'SingleSelect')
                                quest.Answer = choices[0].Code;
                            else
                                choices[0].Selected = true;
                            quest.ForceHidden = true;
                        }
                    }
                    if (quest.Type === 'Grid' || quest.Type === 'DartBoard') //Autoselect/hide if only 1 choice /no choice visible
                    {
                        const rows = this.ss.getVisibleRowOrColumns(quest, 'Rows');
                        const cols = this.ss.getVisibleRowOrColumns(quest, 'Columns');
                        if (rows.length === 0 || cols.length === 0)
                            quest.ForceHidden = true;
                    }
                }

                if (this.cs.pages[pageIndex].Questions.find(x => !x.Hidden && !x.ForceHidden)) {
                    moved = true;
                    break;
                }

                else if (pageIndex === this.cs.pages.length - 1) //Hidden even on last page
                    break;
            }

            if (!moved) //complete
            {
                this.cs.page.Questions[this.cs.page.Questions.length - 1].Complete = true;
                await this.save(this.cs.page.Index, this.cs.page.Index, false);
            }
            else {
                if (pageIndex > 0) {

                    // Added For Anubhav Project To Check FHideen Unique Question 
                    if (fromPageIndex === null && pageIndex === 1 && prv === false) //Unique quest will work only on 1st page. On other pages it cannot be used because by that time a new record has been created
                    {
                        const uniqueQuests = this.cs.pages[0].Questions.filter(x => x.Unique);
                        if (uniqueQuests.length > 0)
                            await this.getFilledResponses(uniqueQuests); //It will skip to last filled question

                        if (this.cs.skipped) //when previous responses are filled, it will move to last attempted quest
                        {
                            await this.loadPage(this.cs.skippedToPageIndex);
                            return;
                        }
                    }

                    //It will save the current page and hidden pages. Will not touch Skipped pages
                    await this.save(fromPageIndex || 0, pageIndex - 1, false);
                }
                await this.loadPage(pageIndex);
            }
        }

        return moved ? pageIndex : -1;
    }

    async closesurvey() //move sequential
    {
        let pageIndex, fromPageIndex;

        if (this.cs.pages.filter(x => x.Questions[0].Code == "Feedback").length > 0) {
            this.cs.qMap["Feedback"].Answer = this.cs.feedback;
            fromPageIndex = this.cs.pages.filter(x => x.Questions[0].Code == "Feedback")[0].Index;
        }

        if (fromPageIndex !== null)
            pageIndex = fromPageIndex;

        await this.save(fromPageIndex, fromPageIndex, false);
        return pageIndex;
    }
    async sleep() {
        return new Promise(r => setTimeout(r, 2000))
    }

    async loadPage(pageIndex) {
        if (this.cs.type === SurveyType.ChatBot && pageIndex > 0) {
            this.dots = true;
            await this.sleep();
            this.dots = false;
        }

        await this.ions.hideLoader();

        if (this.cs.type === SurveyType.LivePoll)
            this.disableNext = true;

        this.cs.skipped = false;
        this.cs.skippedToPageIndex = -1;

        this.cs.page = this.cs.pages[pageIndex];

        if (this.cs.type === SurveyType.ChatBot) //In ChatBot Prev cannot be done
            this.pages.push(this.cs.page);
        else
            this.pages = [this.cs.page];

        this.ss.saveToNavHistory(pageIndex);

        const quests = this.cs.page.Questions;
        if (quests.length > 0) {
            await this.ss.setDefaultAnswers(pageIndex);
            if (!quests.find(x => x.Code === 'sys_disp' + this.cs.respVersionSuffix))
                this.surveyService.addVersionedSysVar('sys_last_quest', quests[quests.length - 1].Code);

            for (const quest of quests) {
                quest.Error = null;
                quest.Skipped = false;

                this.surveyService.formatFilePathsInQuest(quest, this.cs.code);
                this.ss.resetChoiceRowColCell(quest, true);

                if (quest.AllowDisposition === false)
                    this.cs.page.AllowDisposition = false;

                if (quest.PostEnter)
                    this.ss.evalExp(quest.PostEnter);

                if (quest.VoiceOver) {
                    setTimeout(() => {
                        this.ms.play(quest.VoiceOver)
                    }, 1000);
                }

                if (AppConfig.isApp && ((this.cs.config.Speak && quest.Speak !== false) || quest.Speak)) {
                    if (quest.Hidden || quest.Disabled)
                        return;
                    const text = await this.ss.getPlainText(quest, 'InitialText') || this.ss.getPlainText(quest, 'Text');
                    this.ss.getPlainText(quest);//Currently we are not including choices, rows and columns to be read out.
                    if (text)
                        setTimeout(() => this.tts.speak({ text: text, locale: this.cs.lang.Locale }), 1000);
                }
            }
        }

        if (this.cs.config.OnPageRender)
            this.ss.evalExp(this.cs.config.OnPageRender);

        if (this.cs.type !== SurveyType.ChatBot)
            this.scrollToTop();
        else {
            const visibleQuests = this.cs.page.Questions.filter(x => !x.Hidden && (!x.CssClass || x.CssClass.indexOf('hidden') === -1));
            if (visibleQuests.length === 1 && (visibleQuests[0].Type === 'Instruction' || visibleQuests[0].Disabled)) {
                await this.next();
            }
        }
    }

    replaceText() {
        for (const quest of this.cs.page.Questions) {
            quest.Text
        }
    }

    scrollToQuest(quest) {
        setTimeout(() => {
            const elmnt = document.getElementById(quest.Code + "_Container");
            if (elmnt && elmnt.scrollIntoView)
                elmnt.scrollIntoView({ 'behavior': 'smooth', 'block': 'start' });
        }, 100);
    }

    scrollToTop() {
        setTimeout(() => {
            const elmnt = document.getElementById(this.cs.page.Questions[0].Code + "_Container");
            if (elmnt && elmnt.scrollIntoView)
                elmnt.scrollIntoView();
        }, 100);
    }

    async validate() {
        let isValid;
        for (let i = 0; i < this.cs.page.Questions.length; i++) {
            const quest = this.cs.page.Questions[i];
            quest.Error = null;
            if (!quest.Hidden) {
                isValid = await this.validateQuest(quest);
                if (isValid === false) {
                    this.scrollToQuest(quest);
                    break;
                }

                if (quest.PreExit && !quest.Hidden)
                    this.ss.evalExp(quest.PreExit, quest.Code + ' - PreExit');

                if (quest.Skip && quest.Skip.Type === 'Exit') {
                    const flag = this.ss.callFn(quest.Skip.Logic, quest.code + ' skip logic');
                    if (flag) {
                        this.ss.skipTo(quest.Skip.To); //Add condition to see if question is mentioned in "To"
                        break;
                    }
                }
                if (quest.IsQuota) {

                    let url = 'response/getquotadata';
                    let res;
                    let QType = quest.QuotaType ? quest.QuotaType :QuotaType.SoftQuota;
                    url = GenericService.addToQueryString(url, "surveyCode", this.cs.code);
                    url = GenericService.addToQueryString(url, "questCode", quest.Code);
                    url = url + "&answer=" + quest.Answer;

                    res = await this.ajaxService.callGetService(url).toPromise();
                    console.log(res);
                    if (res) {
                        if (res.Count >= quest.Choices.filter(x => x.Code == quest.Answer)[0].Quota && QType == 2) {
                            this.ss.terminate(quest.Code);
                        }
                    }
                }
            }
        }
        return isValid;
    }

    async validateQuest(quest) {

        if (quest.Mode === 'DragDrop') {
            if (quest.Type === 'Grid') {
                if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                    quest.Error = "Please drag & drop all options in one of the buckets.";
                    return false;
                }
            }
            else
                if (quest.Type === 'MultiSelect') {
                    const AnswerChoices = quest.Choices ? quest.Choices.filter(x => x.Answer) : 0;
                    if ((quest.Required !== false && AnswerChoices.length === 0)) {
                        quest.Error = "Response is required.";
                        return false;
                    }

                }
            if (quest.Type === 'Ranking') {
                const AnswerChoices = quest.Choices ? quest.Choices.filter(x => x.Answer) : 0;
                if ((quest.Required !== false && (AnswerChoices.length === 0 || AnswerChoices.length != quest.Columns.length))) {
                    quest.Error = "Response is required.";
                    return false;
                }
            }

        }
        else

            if (quest.Type === 'SingleSelect') {
                if (quest.CMap && quest.CMap[quest.Answer] && quest.CMap[quest.Answer].Other && quest.CMap[quest.Answer].Mode) {
                    if (this.ss.isNullOrEmpty(quest.OtherAnswer)) {
                        quest.Error = "Response is required.";
                        return false;
                    }

                    if (quest.CMap[quest.Answer].Mode == "Numeric") {
                        if (isNaN(quest.OtherAnswer)) {
                            quest.Error = "Not a valid number";
                            return false;
                        }
                        if (!this.ss.isNullOrEmpty(quest.CMap[quest.Answer].Min) && !this.ss.isNullOrEmpty(quest.CMap[quest.Answer].Max)) {
                            if (Number(quest.OtherAnswer) < quest.CMap[quest.Answer].Min || Number(quest.OtherAnswer) > quest.CMap[quest.Answer].Max) {
                                quest.Error = "Answer should be in range " + quest.CMap[quest.Answer].Min + "-" + quest.CMap[quest.Answer].Max;
                                return false;
                            }
                        }
                        else if (!this.ss.isNullOrEmpty(quest.CMap[quest.Answer].Min) && Number(quest.OtherAnswer) < quest.CMap[quest.Answer].Min) {
                            quest.Error = "Answer cannot be less than " + quest.CMap[quest.Answer].Min;
                            return false;
                        }

                        else if (!this.ss.isNullOrEmpty(quest.CMap[quest.Answer].Max) && Number(quest.OtherAnswer) > quest.CMap[quest.Answer].Max) {
                            quest.Error = "Answer cannot be greater than " + quest.CMap[quest.Answer].Max;
                            return false;
                        }
                    }

                    if (quest.CMap[quest.Answer].Regex || quest.CMap[quest.Answer].Mode === 'Email') {
                        quest.CMap[quest.Answer].Regex = quest.CMap[quest.Answer].Regex || { Pattern: 'email', Error: 'Not a valid email.' };

                        if (!RegexService.test(quest.OtherAnswer, quest.CMap[quest.Answer].Regex.Pattern)) {
                            quest.Error = quest.CMap[quest.Answer].Regex.Error || "Not a valid answer.";
                            return false;
                        }
                    }
                }

                if ((quest.Required !== false && this.ss.isNullOrEmpty(quest.Answer))
                    || (quest.CMap && quest.CMap[quest.Answer] && quest.CMap[quest.Answer].Other && this.ss.isNullOrEmpty(quest.OtherAnswer))) {
                    quest.Error = "Response is required.";
                    return false;
                }

                if (quest.Code === 'sys_disp' + this.cs.respVersionSuffix && this.cs.config.MaxAttempts && this.cs.respVersion > this.cs.config.MaxAttempts && quest.CMap[quest.Answer].Action !== 'Complete' && quest.CMap[quest.Answer].Action !== 'Terminate') {
                    quest.Error = 'This is the last attempt. Either complete it or select an option from below list to complete/terminate.';
                    return false;
                }
            }

            else if (quest.Type === 'HotSpot') {
                if (quest.Required !== false && (!quest.Answers || quest.Answers.length === 0)) {
                    quest.Error = "Response is required.";
                    return false;
                }

                if (!this.ss.isNullOrEmptyOrZero(quest.Min) && quest.Answers) {
                    if (quest.Min > quest.Answers.length) {
                        quest.Error = "Please select at least " + quest.Min + " spots.";
                        return false;
                    }
                }

                if (!this.ss.isNullOrEmpty(quest.Max) && quest.Answers.length > quest.Max) {
                    quest.Error = "You can select at most " + quest.Max + " spots.";
                    return false;
                }
            }
            else if ((quest.Type === "OpenEndLoop")) {
                const RequiredChoices = quest.Choices ? quest.Choices.filter(x => x.Required !== false) : [];
                const AnswerChoices = RequiredChoices ? RequiredChoices.filter(x => x.OtherAnswer) : [];

                if ((AnswerChoices.length !== RequiredChoices.length)) {
                    quest.Error = "Response is required.";
                    return false;
                }
                else {
                    const NumericChoices = AnswerChoices ? AnswerChoices.filter(x => x.Mode === "Numeric") : [];
                    const EmailChoices = AnswerChoices ? AnswerChoices.filter(x => x.Mode === "Email") : [];
                    const TextChoices = AnswerChoices ? AnswerChoices.filter(x => (x.Mode === "Text" || !x.Mode)) : [];
                    if (NumericChoices.length > 0) {
                        for (var i = 0; i < NumericChoices.length; i++) {
                            if (NumericChoices[i].Min || NumericChoices[i].Max) {
                                var res = (NumericChoices[i].OtherAnswer <= NumericChoices[i].Max && NumericChoices[i].OtherAnswer >= NumericChoices[i].Min) ? 1 : 0;
                                if (res === 0) {
                                    quest.Error = "Response should be in " + NumericChoices[i].Min + " and " + NumericChoices[i].Max + ".";
                                    return false;
                                }
                            }
                        }
                    }
                    if (EmailChoices.length > 0) {
                        for (var i = 0; i < EmailChoices.length; i++) {
                            var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
                            var res = (expr.test(EmailChoices[i].OtherAnswer)) == true ? 1 : 0;
                            if (res === 0) {
                                quest.Error = "Please enter a valid email address.";
                                return false;
                            }
                        }
                    }
                    if (TextChoices.length > 0) {
                        var Wordlengthchoices = [];
                        var Textlengthchoices = [];
                        Wordlengthchoices = TextChoices.filter(x => x.WordLength);
                        Textlengthchoices = TextChoices.filter(x => x.TextLength);
                        if (Textlengthchoices.length > 0) {
                            for (var i = 0; i < Textlengthchoices.length; i++) {
                                if (Textlengthchoices[i].OtherAnswer.length > parseInt(Textlengthchoices[i].TextLength)) {
                                    quest.Error = "Length of the text is greater than " + Textlengthchoices[i].TextLength + " character."
                                    return false;
                                }
                            }
                        }
                        if (Wordlengthchoices.length > 0) {
                            for (var i = 0; i < Wordlengthchoices.length; i++) {
                                const arr = Wordlengthchoices[i].OtherAnswer.split(' ');
                                var res1 = arr.filter(word => word !== '').length;
                                if (res1 > Wordlengthchoices[i].WordLength) {
                                    quest.Error = "Length of the word is greater than " + Wordlengthchoices[i].WordLength + " words."
                                    return false;
                                }
                            }
                        }

                    }


                }


            }

            else if ((quest.Type === 'OpenEnd')) {
                if (quest.Mode === 'ConstantSumKnob') {
                    if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                        quest.Error = "Please fill the response that add up to total 10.";
                        return false;
                    }
                }
                else
                    if (quest.Mode === 'ConstantSum') {
                        if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                            quest.Error = "Please fill the response that add up to total 10.";
                            return false;
                        }
                    }
                    else
                        if (quest.Required !== false && this.ss.isNullOrEmpty(quest.Answer)) {
                            quest.Error = "Response is required.";
                            return false;
                        }
                        else
                            if (quest.TextLength) {
                                if (quest.Answer.length > parseInt(quest.TextLength)) {
                                    quest.Error = "Length of the text is greater than " + quest.TextLength + " characters."
                                    return false;
                                }

                            }
                            else
                                if (quest.WordLength) {
                                    const arr = quest.Answer.split(' ');
                                    var res1 = arr.filter(word => word !== '').length;
                                    if (res1 > parseInt(quest.WordLength)) {
                                        quest.Error = "Length of the text is greater than " + quest.WordLength + " words."
                                        return false;
                                    }

                                }
                                else
                                    if (!this.ss.isNullOrEmpty(quest.Answer)) {
                                        if (quest.Regex || quest.Mode === 'Email') {
                                            quest.Regex = quest.Regex || { Pattern: 'email', Error: 'Not a valid email.' };
                                            if (!RegexService.test(quest.Answer, quest.Regex.Pattern)) {
                                                quest.Error = quest.Regex.Error || "Not a valid answer.";
                                                return false;
                                            }
                                        }

                                        if (quest.Mode === 'SingleLine') {
                                            if (!this.ss.isNullOrEmptyOrZero(quest.Min) && !this.ss.isNullOrEmpty(quest.Max)) {
                                                if (quest.Answer.length < quest.Min || quest.Answer.length > quest.Max) {
                                                    quest.Error = "Answer should have " + quest.Min + "-" + quest.Max + " characters.";
                                                    return false;
                                                }
                                            }
                                            else if (!this.ss.isNullOrEmptyOrZero(quest.Min) && quest.Answer.length < quest.Min) {
                                                quest.Error = "Answer should have atleast " + quest.Min + " characters.";
                                                return false;
                                            }

                                            else if (!this.ss.isNullOrEmpty(quest.Max) && quest.Answer.length > quest.Max) {
                                                quest.Error = "Answer cannot have more than " + quest.Max + " characters.";;
                                                return false;
                                            }
                                        }

                                        if (quest.Mode === 'Numeric' || quest.Mode == 'Scroll' || quest.Mode == 'PlusMinus') {
                                            if (isNaN(quest.Answer)) {
                                                quest.Error = "Not a valid number";
                                                return false;
                                            }
                                            if (!this.ss.isNullOrEmpty(quest.Min) && !this.ss.isNullOrEmpty(quest.Max)) {
                                                if (Number(quest.Answer) < quest.Min || Number(quest.Answer) > quest.Max) {
                                                    quest.Error = "Answer should be in range " + quest.Min + "-" + quest.Max;
                                                    return false;
                                                }
                                            }
                                            else if (!this.ss.isNullOrEmpty(quest.Min) && Number(quest.Answer) < quest.Min) {
                                                quest.Error = "Answer cannot be less than " + quest.Min;
                                                return false;
                                            }

                                            else if (!this.ss.isNullOrEmpty(quest.Max) && Number(quest.Answer) > quest.Max) {
                                                quest.Error = "Answer cannot be greater than " + quest.Max;
                                                return false;
                                            }
                                        }
                                    }
            }

            else if (quest.Type === 'MultiSelect') {
                const selectedChoices = quest.Choices ? quest.Choices.filter(x => x.Selected) : quest.Cells.filter(x => x.Selected);
                var selectedUnAnswerdOthers = selectedChoices.filter(x => x.Other);
                selectedUnAnswerdOthers = selectedUnAnswerdOthers.filter(x => x.Required != false);

                if (quest.Mode == 'Swipe') {
                    return true;
                }
                if (quest.Mode != 'DragDrop') {
                    if ((quest.Required !== false && selectedChoices.length === 0) || selectedUnAnswerdOthers.length > 0) {
                        quest.Error = "Response is required.";
                        return false;
                    }

                    if (!this.ss.isNullOrEmptyOrZero(quest.Min)) {
                        const min = quest.Min <= quest.Choices.length ? quest.Min : quest.Choices.length;
                        if (min > selectedChoices.length) {
                            quest.Error = "Please select at least " + min + " options.";
                            return false;
                        }
                    }

                    if (!this.ss.isNullOrEmpty(quest.Max) && selectedChoices.length > quest.Max) {
                        quest.Error = "You can select at most " + quest.Max + " options.";
                        return false;
                    }
                }
                else {
                    const AnswerChoices = quest.Choices ? quest.Choices.filter(x => x.Answer) : 0;

                    if ((quest.Required !== false && AnswerChoices.length === 0)) {
                        quest.Error = "Response is required.";
                        return false;
                    }
                }
            }
            else if (quest.Type === 'Ranking') {
                const selectedChoices = quest.Choices ? quest.Choices.filter(x => x.Answer) : quest.Cells.filter(x => x.Answer);
                const selectedUnAnswerdOthers = selectedChoices.filter(x => x.Other && this.ss.isNullOrEmpty(x.OtherAnswer));

                if ((quest.Required !== false && selectedChoices.length === 0) || selectedUnAnswerdOthers.length > 0) {
                    quest.Error = "Response is required.";
                    return false;
                }
            }
            else if (quest.Type === 'Image' || quest.Type === 'Signature') {
                if (quest.Required !== false && this.ss.isNullOrEmpty(quest.Attachment)) {
                    quest.Error = "Response is required.";
                    return false;
                }
            }

            else if (quest.Type === 'AudioRecording' || quest.Type === 'VideoRecording') {
                if (quest.Required !== false && this.ss.isNullOrEmpty(quest.Answer)) {
                    if (AppConfig.isApp) //For web we will allow for testing purpose as audio recording is not supported on web
                    {
                        quest.Error = "Response is required.";
                        return false;
                    }
                }

                if (!this.ss.isNullOrEmptyOrZero(quest.Min)) {
                    const duration = quest.Type === 'AudioRecording' ? this.cs.recording.getDuration() : quest.Duration;
                    if (quest.Min < duration) {
                        if (quest.Min >= 60) {
                            const min = quest.Min / 60;
                            quest.Error = "Min length of recording should be " + min + " min.";
                        }
                        else
                            quest.Error = "Min length of recording should be " + quest.Min + " sec.";
                        return false;
                    }
                }

                if (!this.ss.isNullOrEmptyOrZero(quest.Max)) {
                    const duration = quest.Type === 'AudioRecording' ? this.cs.recording.getDuration() : quest.Duration;
                    if (quest.Max > duration) {
                        if (quest.Max >= 60) {
                            const max = quest.Max / 60;
                            quest.Error = "Max length of recording should be " + max + " min.";
                        }
                        else
                            quest.Error = "Max length of recording should be " + quest.Max + " sec.";

                        return false;
                    }
                }

                await this.ms.stopAudioRecording();
            }

            else if (quest.Type === "Grid") {
                if (quest.Mode === 'DragDropOld') {
                    if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                        quest.Error = "Please drag & drop all options in one of the buckets.";
                        return false;
                    }
                }
                else
                    if (quest.Mode === 'DragDrop') {
                        if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                            quest.Error = "Please drag & drop all options in one of the buckets.";
                            return false;
                        }
                    }
                    else
                        if (quest.Mode === 'ConstantSumKnob') {
                            if (quest.Required !== false && quest.Rows.find(x => !x.Hidden && this.ss.isNullOrEmpty(x.Answer))) {
                                quest.Error = "Please fill the response that add up to total 10.";
                                return false;
                            }
                        }
                        else
                            if (quest.Mode === 'ConstantSum') {
                                if (quest.Required !== false) {
                                    let isValid, r = 0;
                                    // debugger;
                                    if (quest.Direction == "Column") {
                                        isValid = undefined;
                                        for (let j = 0; j < quest.Columns.length; j++) {
                                            var RData = quest.Rows.filter(x => x.Mode != "ConstantSum").map(y => y.Code);
                                            const col = quest.Columns[j];
                                            for (let k = 0; k < col.Cells.length; k++) {
                                                if (!col.Cells[k].Hidden) {

                                                    isValid = col.Cells[k].Answer != null && col.Cells[k].Answer != undefined ? true : false;
                                                    let FRData = quest.Rows.filter(x => x.Code == col.Cells[k].Code);
                                                    if (FRData.length > 0 && FRData[0].Required == false) {
                                                        isValid = true;
                                                    }
                                                    if (FRData.length > 0 && FRData[0].Other == true) {
                                                        if (col.Cells[k].Answer && (!FRData[0].OtherAnswer || FRData[0].OtherAnswer == "")) {
                                                            isValid = false;
                                                            var ColNumber = j + 1;
                                                            var RowNumber = k + 1;
                                                            quest.Error = "Please fill other specify [RowNumber " + RowNumber + "]";
                                                            break;
                                                        }
                                                        else
                                                            if (!col.Cells[k].Answer && (FRData[0].OtherAnswer || FRData[0].OtherAnswer != "")) {
                                                                col.Cells[k].Answer = 0;
                                                            }
                                                    }
                                                    if (isValid === false) {
                                                        var ColNumber = j + 1;
                                                        var RowNumber = k + 1;
                                                        quest.Error = "Please fill the response [Cell " + RowNumber + ":" + ColNumber + "]";
                                                        break;
                                                    }

                                                }
                                                if (isValid === false)
                                                    break;
                                                var Ans = isNaN(col.Cells.filter(y => !RData.includes(y.Code) && y.Answer).map(x => x.Answer).reduce((a, b) => a + b)) ? 0 : col.Cells.filter(y => !RData.includes(y.Code) && y.Answer).map(x => x.Answer).reduce((a, b) => a + b);
                                                if (Ans != quest.Max) {
                                                    var ColNumber = j + 1;
                                                    quest.Error = "Please fill the response in [Column " + ColNumber + "] that add up to total " + quest.Max + ".";
                                                    isValid = false;
                                                    break;
                                                }

                                            }
                                        }
                                    }
                                    else
                                        if (quest.Direction == "Row") {
                                            isValid = undefined;
                                            for (let j = 0; j < quest.Rows.length; j++) {
                                                var CData = quest.Columns.filter(x => x.Mode != "ConstantSum").map(y => y.Code);
                                                const row = quest.Rows[j];
                                                for (let k = 0; k < row.Cells.length; k++) {
                                                    if (!row.Cells[k].Hidden) {
                                                        isValid = row.Cells[k].Answer != null && row.Cells[k].Answer != undefined ? true : false;
                                                        if (isValid === false) {
                                                            var RowNumber = j + 1;
                                                            var ColNumber = k + 1;
                                                            quest.Error = "Please fill the response [Cell " + RowNumber + ":" + ColNumber + "]";
                                                            break;
                                                        }
                                                    }
                                                }
                                                if (isValid === false)
                                                    break;
                                                var Ans = isNaN(row.Cells.filter(y => !CData.includes(y.Code) && !y.Hidden).map(x => x.Answer).reduce((a, b) => a + b)) ? 0 : row.Cells.filter(y => !CData.includes(y.Code) && !y.Hidden).map(x => x.Answer).reduce((a, b) => a + b);
                                                if (Ans != quest.Max) {
                                                    var RowNumber = j + 1;
                                                    quest.Error = "Please fill the response in [Row " + RowNumber + "] that add up to total " + quest.Max + ".";
                                                    isValid = false;
                                                    break;
                                                }

                                            }
                                        }

                                    if (isValid === false)
                                        return isValid;
                                }
                            }
                            else {
                                let isValid, isGlobalValid, r = 0;
                                for (let i = 0; i < quest.Rows.length; i++) {
                                    isValid = undefined;

                                    const row = quest.Rows[i];
                                    if (!row.Hidden && !row.IsHeader) {
                                        r++;
                                        if ((quest.Direction === "Row") && ((row.Type === 'SingleSelect' && row.Mode !== 'DropDown') || row.Type === 'MultiSelect')) {
                                            row.Required = row.Required == undefined ? quest.Required : row.Required;
                                            isValid = await this.validateQuest(row);
                                            if (isValid === false) {
                                                isGlobalValid = false;
                                                //Below Line - To Add Row Number in Error Msg 
                                                //quest.Error = row.Error + " [Row " + r + "]";

                                                //Below Line - To Add Row Text in Error Msg 
                                                //quest.Error = row.Error + " [" + row.Text + "]";

                                                if (quest.Error)
                                                    quest.Error = "Response is required for the highlighted rows - Please scroll down to ensure all the options are answered";
                                                else
                                                    quest.Error = "Response is required for the highlighted row - Please scroll down to ensure all the options are answered";

                                                // break;
                                            }
                                            if (isValid !== false && row.Error) {
                                                row.Error = undefined;
                                            }
                                        }
                                        else {
                                            let c = 0;
                                            for (let j = 0; j < quest.Columns.length; j++) {
                                                const col = quest.Columns[j];
                                                if (!col.Hidden) {
                                                    c++;
                                                    if (quest.Direction === "Column" && ((col.Type === 'SingleSelect' && col.Mode !== 'DropDown') || col.Type === 'MultiSelect')
                                                    ) {
                                                        col.Required = col.Required == undefined ? quest.Required : col.Required;
                                                        isValid = await this.validateQuest(col);
                                                        if (isValid === false) {
                                                            isGlobalValid = false;
                                                            //quest.Error = col.Error + " [Column " + c + "]";
                                                            // break;
                                                            if (quest.Error)
                                                                quest.Error = "Response is required for the highlighted columns";
                                                            else
                                                                quest.Error = "Response is required for the highlighted column";
                                                        }
                                                        if (isValid !== false && col.Error) {
                                                            col.Error = undefined;
                                                        }
                                                    }
                                                    else {
                                                        const cell = quest.Rows[i].Cells[j];
                                                        if (!cell.Hidden) {
                                                            cell.Required = cell.Required == undefined ? (col.Required == undefined ? quest.Required : col.Required) : cell.Required;
                                                            isValid = await this.validateQuest(cell);
                                                            if (isValid === false) {
                                                                isGlobalValid = false;
                                                                quest.Error = cell.Error + " [Cell " + r + ": " + c + "]";
                                                                // break;
                                                            }
                                                            if (isValid !== false && cell.Error) {
                                                                cell.Error = undefined;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            // if (isValid == false)
                                            //     break;
                                            if (isGlobalValid == false)
                                                break;
                                        }
                                    }
                                }
                                if (isGlobalValid === false)
                                    return isGlobalValid;
                            }
            }

            else if (quest.Type === 'DartBoard') {
                const answers = this.ss.getGridAnswers(quest);
                if (answers.length < quest.Rows.length) {
                    quest.Error = "Response is required.";
                    return false;
                }
            }

        if (quest.Validate && !quest.Hidden) {
            const error = this.ss.callFn(quest.Validate);
            if (error) {
                quest.Error = error;
                return false;
            }
        }
    }

    async prev(): Promise<any> {
        try {
            await this.ms.stopPlaying();
            await this.move(null, true);
        }
        catch (err) {
            this.ions.alertError(err);
        }
    }

    async next(): Promise<any> {
        try {
            await this.ms.stopPlaying();
            const currPageIndex = this.cs.page.Index;

            this.cs.skipped = false;
            this.cs.skippedToPageIndex = -1;

            const flag = await this.validate();
            if (flag === false) {
                const quest = this.cs.page.Questions.find(x => x.Error);
                if (quest) {
                    const ele = document.getElementById(quest.Code);
                    if (ele) //It should always be not null
                        ele.scrollIntoView();
                    return;
                }
            }

            if (this.cs.type === SurveyType.ChatBot) {
                this.cs.page.Questions.forEach(q => {
                    q.Disabled = true;
                    if (q.Type === 'SingleSelect' || q.Type === 'Scroll') {
                        q.Choices.forEach(x => {
                            if (x.Code != q.Answer)
                                x.Hidden = true;
                        });
                    }
                    if (q.Type === 'MultiSelect') {
                        q.Choices.forEach(x => {
                            if (!x.Selected)
                                x.CssClass = "hidden";
                        });
                    }
                });
            }

            let pageIndex = this.cs.page.Index;
            if (this.cs.skipped) //skipped due to some skip logic on PreExit
            {
                pageIndex = this.cs.skippedToPageIndex;
                await this.save(currPageIndex, currPageIndex, false);
                if (this.cs.pages[this.cs.skippedToPageIndex].Questions.find(x => !x.Hidden)) {
                    await this.loadPage(this.cs.skippedToPageIndex); //here we need to expicitely load page as we need to save
                    return; //To prevent move again in next() because skiptTo has moved where it should move.
                }
                else {
                    this.cs.skipped = false;
                    this.cs.skippedToPageIndex = -1;
                }
            }

            if (this.cs.page.Index === 0) //Unique quest will work only on 1st page. On other pages it cannot be used because by that time a new record has been created
            {
                const uniqueQuests = this.cs.page.Questions.filter(x => x.Unique);
                if (uniqueQuests.length > 0)
                    await this.getFilledResponses(uniqueQuests); //It will skip to last filled question

                if (this.cs.skipped) //when previous responses are filled, it will move to last attempted quest
                {
                    await this.loadPage(this.cs.skippedToPageIndex);
                    return;
                }
            }

            await this.move(pageIndex);
        }
        catch (err) {
            this.ions.alertError(err);
        }
    }

    async getFilledResponses(uniqueQuests): Promise<any> {
        //It supports multiple unique questions on same page. So you have the choice to search either by phone no. or email.
        await this.ions.showLoader("Please wait.... ");
        const keyValues = {};
        uniqueQuests.forEach(q => {
            let answer = q.Answer;
            if (!this.ss.isNullOrEmpty(q.PrependToAnswer))
                answer = q.PrependToAnswer + answer;
            if (!this.ss.isNullOrEmpty(q.AppendToAnswer))
                answer = answer + q.AppendToAnswer;

            keyValues[q.Code] = answer;
        });

        await this.surveyService.getFilledResponses(keyValues);
        let prvVersionSuffix = "";
        if (this.cs.respVersion > 2)
            prvVersionSuffix = "_" + (this.cs.respVersion - 1);

        const lastQuest = this.cs.qMap['sys_last_quest' + prvVersionSuffix];
        if (lastQuest && lastQuest.Answer) {
            const lastAttempted = this.cs.qMap[lastQuest.Answer];

            //if (lastAttempted && lastAttempted.PageIndex > this.cs.page.Index)

            // Added For Anubhav Project To Check FHideen Unique Question 
            if (lastAttempted && lastAttempted.PageIndex)
                this.ss.skipTo(lastQuest.Answer, true);
        }
    }

    async save(fromPageIndex, toPageIndex, prev: boolean): Promise<any> {
        if (!this.autoTest && this.cs.type !== SurveyType.ChatBot)
            await this.ions.showLoader('Please wait a moment...');
        this.saving = true;

        let terminationMessage, finish, dispQuest, res;
        const completes = [], terminates = [];

        const isLastPage = toPageIndex === this.cs.pages.length - 1;

        let surveyStatus = SurveyStatus.Incomplete;
        const qMap = this.surveyService.retrieveAnswersToSave(fromPageIndex, toPageIndex, prev);

        if (!prev) {
            if (fromPageIndex === this.cs.navHistory[0])
                await this.surveyService.setStartSysVars(qMap);
            if (this.cs.isRestart)
                this.surveyService.setRestartSysVars(qMap);

            for (let i = fromPageIndex; i <= toPageIndex; i++) {
                this.cs.pages[i].Questions.forEach(x => {
                    if (x.Code === 'sys_disp' + this.cs.respVersionSuffix)
                        dispQuest = x;
                    else if (x.Terminate)
                        terminates.push(x);
                    else if (x.Complete)
                        completes.push(x);
                });
            }

            finish = terminates.length > 0 || completes.length > 0 || (dispQuest && dispQuest.Answer) || isLastPage;

            if (dispQuest && dispQuest.Answer) {
                //Setting survey_status manually should be used carefully. It can cause confision while QC.
                const action = dispQuest.CMap[dispQuest.Answer].Action;
                if (action === 'Terminate')
                    surveyStatus = SurveyStatus.Terminated;
                if (action === 'Complete')
                    surveyStatus = SurveyStatus.Complete;
                else
                    surveyStatus = SurveyStatus.Incomplete;
            }
            else if (terminates.length > 0 || completes.length > 0) {
                surveyStatus = terminates.length > 0 ? SurveyStatus.Terminated : SurveyStatus.Complete;
                if (terminates.length > 0 && this.cs.lang.TerminationMessage)
                    terminationMessage = this.cs.lang.TerminationMessage
            }


            // Added For Anubhav Project
            this.surveyService.addSysVar('sys_end_device', new Date(), qMap);
            this.surveyService.addSysVar('sys_end_date', new Date(), qMap);
            this.surveyService.addSysVar('sys_end_time', new Date(), qMap);
            this.surveyService.addSysVar('sys_elapsed_time', "0H:0D:0M:0S", qMap);


            if (finish) {
                if (dispQuest && dispQuest.Answer)
                    this.surveyService.addVersionedSysVar('sys_close_device', new Date(), qMap);
                else {
                    // this.surveyService.addSysVar('sys_end_device', new Date(), qMap);
                    // this.surveyService.addSysVar('sys_elapsed_time', 1, qMap);

                    if (surveyStatus === SurveyStatus.Incomplete)
                        surveyStatus = SurveyStatus.Complete;
                }
            }
        }

        if (AppConfig.isApp) {
            if (this.cs.recording && finish)
                await this.ms.stopAudioRecording();
            if (!this.cs.runOnline)
                await this.sqliteService.saveResponses(this.cs.code, this.cs.uId, surveyStatus, qMap);
        }

        if (!AppConfig.isApp || this.cs.runOnline) {
            if (this.cs.respId) {
                this.surveyService.addSysVar('sys_resp_id', this.cs.respId, qMap);
            }
            if (!this.cs.respId) {
                this.surveyService.addSysVar('sys_start_device', new Date(), qMap);
                this.surveyService.addSysVar('sys_start_date', new Date(), qMap);
                this.surveyService.addSysVar('sys_start_time', new Date(), qMap);
            }

            this.surveyService.addSysVar('sys_uid', this.cs.uId, qMap);
            this.surveyService.addSysVar('sys_survey_status', surveyStatus, qMap);

            let url = 'response/save';
            url = GenericService.addToQueryString(url, "surveyCode", this.cs.code);
            if (prev)
                url = GenericService.addToQueryString(url, "prev", prev);
            res = await this.ajaxService.postData(url, qMap, !GlobalService.user).toPromise();

            this.cs.respId = this.cs.respId || res['sys_resp_id'];
            // if (res['QuotaStatus'] === "2")
            //     this.ss.terminate(Object.keys(qMap)[0]);
        }

        if (this.cs.isRestart)
            this.cs.isRestart = false;

        await this.ions.hideLoader();
        this.saving = false;

        if (finish)
            await this.endSurvey(terminationMessage, res, surveyStatus);
    }

    async savechoicesorder(QuestCode: string, order: string,): Promise<any> {

        const qMap = {};//this.surveyService.retrieveAnswersToSave(fromPageIndex, toPageIndex, prev);
        let res;
        // if (!prev) {
        //     if (fromPageIndex === this.cs.navHistory[0])
        //         await this.surveyService.setStartSysVars(qMap);
        //     if (this.cs.isRestart)
        //         this.surveyService.setRestartSysVars(qMap);

        //     for (let i = fromPageIndex; i <= toPageIndex; i++) {
        //         this.cs.pages[i].Questions.forEach(x => {
        //             if (x.Code === 'sys_disp' + this.cs.respVersionSuffix)
        //                 dispQuest = x;
        //             else if (x.Terminate)
        //                 terminates.push(x);
        //             else if (x.Complete)
        //                 completes.push(x);
        //         });
        //     }



        // }

        // if (AppConfig.isApp) {
        //     if (this.cs.recording && finish)
        //         await this.ms.stopAudioRecording();
        //     if (!this.cs.runOnline)
        //         await this.sqliteService.saveResponses(this.cs.code, this.cs.uId, surveyStatus, qMap);
        // }

        if (!AppConfig.isApp || this.cs.runOnline) {
            if (this.cs.respId)
                this.surveyService.addSysVar('sys_resp_id', this.cs.respId, qMap);

            this.surveyService.addSysVar('sys_uid', this.cs.uId, qMap);
            let url = 'response/savechoicesoreder';

            url = GenericService.addToQueryString(url, "surveyCode", this.cs.code);
            res = await this.ajaxService.postData(url, qMap, !GlobalService.user).toPromise();
        }
    }

    async endSurvey(message = null, res = null, surveyStatus = null) {
        if (!AppConfig.isApp) {
            const clink = await this.ss.pipeInAndFormatFilePaths(this.cs.config, 'CompleteRedirectUrl', 'TerminateRedirectUrl');
            const tlink = await this.ss.pipeInAndFormatFilePaths(this.cs.config, 'TerminateRedirectUrl', 'TerminateRedirectUrl');
            const olink = await this.ss.pipeInAndFormatFilePaths(this.cs.config, 'QuotaOverflowRedirectUrl', 'QuotaOverflowRedirectUrl');

            if (res['overflow'] && olink) //We will need to change this condition when overflow is implemented on server
            {
                location.href = olink;
                return;
            }
            if (surveyStatus === SurveyStatus.Terminated && tlink) {
                location.href = tlink;
                return;
            }
            if (surveyStatus === SurveyStatus.Complete && clink) {
                location.href = clink;
                return;
            }
        }

        else if ((this.cs.config.AutoSync === true || this.cs.runOnline) && NetworkService.isOnline()) {
            await this.syncService.sync(this.cs.code);
        }

        this.cs.isRunning = false;
        // if (this.eventListener)
        //     window.removeEventListener('beforeunload', this.eventListener);

        if (this.cs.type !== SurveyType.ChatBot) {
            this.cs.surveyEndMessage = message || this.cs.lang.ThanksMessage;
            this.changeMode('thanks');
        }

        //if (!this.cs.config.OneTimeLink && this.cs.type !== SurveyType.LivePoll && this.cs.type !== SurveyType.ChatBot)
        //{
        //    setTimeout(() =>
        //    {
        //        this.renderSurvey(this.cs.code);
        //    }, 4000);
        //}

        if (this.autoTest)
            this.autoTestsDone++;
    }

    onError(quest, error) {
        quest.Error = error;
    }

    changeMode(mode) {
        this.mode = mode;
    }

    async questChange(changedQuest, questCode) {
        changedQuest.Error = null;
        this.cs.qMap[questCode] = changedQuest;

        // if (changedQuest.answered && !changedQuest.Code.startsWith('sys_disp') && changedQuest.Mode !== 'Slider' && changedQuest.Type !== 'OpenEnd') {
        if (changedQuest.answered && !changedQuest.Code.startsWith('sys_disp') && changedQuest.Type !== 'OpenEnd') {

            if (this.cs.type !== SurveyType.ChatBot)
                delete changedQuest.answered;

            if (!changedQuest.Hidden) {
                const visibleQuests = this.cs.page.Questions.filter(x => !x.Hidden && (!x.CssClass || x.CssClass.indexOf('hidden') === -1));
                if (visibleQuests.length === 0)
                    return;

                const isValid = await this.validateQuest(changedQuest); //Only this question is validated. On Next this question will be revalidated.
                if (isValid === false)
                    this.scrollToQuest(changedQuest);

                else if (visibleQuests.length === 1 || visibleQuests[visibleQuests.length - 1].Code === changedQuest.Code) //Only one visible question on page or this is the last question on page
                {
                    if (this.cs.type !== SurveyType.LivePoll && changedQuest.AutoNext !== false && this.cs.config.AutoNext !== false) //Sometimes we need to disable AutoNext in Script
                        this.next();
                }
            }
        }

        // To Auto Next For Particaular Questions
        else
            if (changedQuest.AutoNext === true && this.cs.type !== SurveyType.LivePoll) {
                this.next();
            }

        for (const quest of this.cs.page.Questions) {
            if (!quest.Hidden) {
                this.surveyService.formatFilePathsInQuest(quest, this.cs.code);
            }
        }
    }

    setLangOptions() {
        this.langOptions = [];
        for (let i = 0; i < this.cs.languages.length; i++) {
            const lang = this.cs.languages[i];
            this.langOptions.push({ type: 'radio', label: lang.Name, value: lang.Name, checked: lang.Name === this.cs.lang.Name });
        }
    }

    async selectLanguage() {
        const prompt = await this.ions.alertCtrl.create({
            header: 'Select Survey Language',
            inputs: this.langOptions,
            buttons: [
                {
                    text: "Cancel",
                },
                {
                    text: "Select",
                    handler: langName => {
                        this.setLanguage(langName);
                    }
                }]
        });
        await prompt.present();
    }
    async leavesurvey() {
        const prompt = await this.ions.alertCtrl.create({
            // header: "Please don't leave us! It will just take a few minutes.",
            message: "Please don't leave us! It will just take a few minutes." + '<br>' +
                "Your feedback is valuable in shaping the future of Indian banking.",
            // message: "Your feedback is valuable in shaping " + '<br>' +
            //     "the future of Indian banking.",

            // inputs: [
            //     {
            //         name: 'text',
            //         type: 'textarea',
            //         placeholder: 'Enter your feedback....'
            //     }],
            buttons: [
                {
                    text: "Leave",
                    handler: x => {
                        window.location.href = "http://www.google.com";
                    }
                },
                {
                    text: "Cancel",
                    // handler: langName => {
                    //     this.setLanguage(langName);
                    // }
                }]
        });
        await prompt.present();
    }

    setLanguage(langName) {
        const languages = this.cs.languages;
        const lang = languages.filter(x => x.Name === langName)[0];
        lang.StartButtonText = lang.StartButtonText || languages[0].StartButtonText;
        lang.WelcomeMessage = lang.WelcomeMessage || languages[0].WelcomeMessage;
        lang.ThanksMessage = lang.ThanksMessage || languages[0].ThanksMessage;
        lang.TerminationMessage = lang.TerminationMessage || languages[0].TerminationMessage;
        this.cs.lang = lang;

        for (const opt of this.langOptions)
            opt.checked = opt.value === langName;

        this.applyTranslations();
    }
    setSectionName(quest) {
        var SectionName = "";
        var obj1 = {
            Text: Text
        };
        if (this.cs.lang.Name == 'English') {
            obj1.Text = quest.SectionName;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Hindi') {
            obj1.Text = quest.SectionName2;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Marathi') {
            obj1.Text = quest.SectionName3;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Bengali') {
            obj1.Text = quest.SectionName4;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Gujarati') {
            obj1.Text = quest.SectionName5;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Oriya') {
            obj1.Text = quest.SectionName6;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Tamil') {
            obj1.Text = quest.SectionName7;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Telugu') {
            obj1.Text = quest.SectionName8;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Assamese') {
            obj1.Text = quest.SectionName9;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Malayalam') {
            obj1.Text = quest.SectionName10;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }
        if (this.cs.lang.Name == 'Kannada') {
            obj1.Text = quest.SectionName11;
            SectionName = this.ss.evalPipeIns(obj1, "Text", null);
        }

        return SectionName;

    }
    applyTranslations() {
        this.storage.set(this.cs.code + '-language', this.cs.lang.Name);
        const translations = this.cs.translations.filter(x => x.Language === this.cs.lang.Name);

        this.cs.lists.forEach(list => {
            this.applyTranslations1(list, translations, 'List');
        });

        this.cs.questions.forEach(quest => {
            this.applyTranslations1(quest, translations, 'Question'); //All record Types except List
        });
    }

    private getItrText(itr): string {
        if (itr) {
            const listCode = this.cs.loops[itr.Loop].List;
            const list = this.cs.listMap[listCode];
            const text = list.CMap[itr.Code].Text;
            return text;
        }
    }

    private async applyTranslations1(obj, translations, type = null) {
        if (obj.Itr)
            obj.Itr.Text = this.getItrText(obj.Itr);

        if (obj.ItrNext)
            obj.ItrNext.Text = this.getItrText(obj.ItrNext);

        let props = ['Text', 'GridTitle', 'Prefix', 'Suffix'];

        for (const prop of props) {
            if (!this.ss.isNullOrEmpty(obj[prop])) {
                const trs1 = translations.find(x => x.Type === type && (x.Code === obj.Code || x.Code === obj.MainQuestCode) && x.Field === prop);

                if (trs1) {
                    obj['PrimaryLang' + prop] = obj['PrimaryLang' + prop] || obj[prop];
                    obj[prop] = trs1.Text;
                    if (type === 'Question')
                        this.surveyService.replaceItrInQuestion(obj);
                }
                else
                    obj[prop] = obj['PrimaryLang' + prop] || obj[prop];
            }
        }

        props = ['Choice', 'Row', 'Column'];
        props.forEach(prop => {
            if (obj[prop + 's']) {
                const trs1 = translations.filter(x => x.ParentType === type && (x.ParentCode === obj.Code || x.ParentCode === obj.MainQuestCode));
                obj[prop + 's'].forEach(choice => {
                    let trs2;
                    if (choice.ParentList)
                        trs2 = translations.find(x => x.ParentType === "List" && x.ParentCode === choice.ParentList && x.Code == choice.Code);
                    else
                        trs2 = trs1.find(x => x.Type === prop && x.Code == choice.Code);
                    if (trs2) {
                        choice.PrimaryLangText = choice.PrimaryLangText || choice.Text;
                        choice.Text = trs2.Text;
                        if (type === 'Question')
                            this.surveyService.replaceItrInChoices(choice, obj.Itr, obj.ItrNext);
                    }
                    else
                        choice.Text = choice.PrimaryLangText || choice.Text;
                });
            }
        });
    }

    async skipTo(questCode) {
        if (questCode) {
            this.ss.skipTo(questCode, false, false);
            if (this.cs.skipped)
                await this.loadPage(this.cs.skippedToPageIndex);
        }
    }
}
