import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { CurrentSurvey } from './current-survey';
import { SurveyStatus } from './enum';

@Injectable()
export class SqliteService
{
    cs = CurrentSurvey;
    //dbs = {};
    constructor(private sqlite: SQLite)
    {

    }

    async open(surveyCode: string): Promise<SQLiteObject>
    {
        // let dbName = 'pollstudiov2_0';
        let dbName = 'ps';
        
        if (surveyCode)
            dbName += "_" + surveyCode.toLowerCase();

        //if (this.dbs[dbName])  //this is not required
        //{
        //    console.log('connection found');
        //    if (dbName in this.dbs[dbName].openDBs)
        //        return this.dbs[dbName];
        //}
        return this.sqlite.create({
            name: dbName,
            location: 'default'
        }).then(db =>
        {
            //this.dbs[dbName] = db;
            return db;
        });
    }

    async deleteDb(surveyCode: string): Promise<SQLiteObject>
    {
        if (!surveyCode)
            throw new Error('Please mention Survey Code to delete database.');

        const dbName = 'pollstudiov2_0' + "_" + surveyCode;
        return this.sqlite.deleteDatabase({
            name: dbName,
            location: 'default'
        });
    }

    async executeSql(query: string, params, surveyCode:string): Promise<any>
    {
        params = params || []
        return this.open(surveyCode).then(db =>
        {
            return db.executeSql(query, params);
        });
    }

    async sqlBatch(queries:any, surveyCode): Promise<any>
    {
        return this.open(surveyCode).then(db =>
        {
            return db.sqlBatch(queries);
        });
    }

    async init(surveyCode): Promise<any>
    {
        const db = await this.open(surveyCode);

        if (!surveyCode)
        {
            let query = 'CREATE TABLE IF NOT EXISTS survey(code TEXT PRIMARY KEY, title TEXT, type INTEGER, created_on TEXT, last_modified_on TEXT, expires_on TEXT, added_on TEXT, questionnaire TEXT, translations TEXT);';
            const p1 = db.executeSql(query, []);

            query = ' CREATE TABLE IF NOT EXISTS log(id TEXT PRIMARY KEY, message TEXT, level INT DEFAULT 1, survey_code TEXT, remarks TEXT, created_by_id INT, created_on TEXT, synced INT DEFAULT 0);';
            const p2 = db.executeSql(query, []);

            return Promise.all([p1, p2]);
        }
        else
        {
            let query = 'CREATE TABLE IF NOT EXISTS interview(sys_uid TEXT PRIMARY KEY, synced INT DEFAULT 0, sys_survey_status INT DEFAULT 0, unique_field_values TEXT);';
            const p1 = db.executeSql(query, []);
            query = ' CREATE TABLE IF NOT EXISTS response(sys_uid TEXT, quest_code TEXT, data TEXT, PRIMARY KEY (sys_uid, quest_code));';
            const p2 = db.executeSql(query, []);
            query = ' CREATE TABLE IF NOT EXISTS response_log(id INTEGER PRIMARY KEY, sys_uid TEXT, quest_code TEXT, data TEXT, created_on TEXT);';
            const p3 = db.executeSql(query, []);
            query = ' CREATE TABLE IF NOT EXISTS survey_file(remote_path TEXT PRIMARY KEY, local_path TEXT, downloaded_on TEXT);';
            const p4 = db.executeSql(query, []);
            query = ' CREATE TABLE IF NOT EXISTS interview_file(name TEXT, quest_code TEXT, sys_uid TEXT, type TEXT, status TEXT, synced INT DEFAULT 0, created_on TEXT, PRIMARY KEY (quest_code, sys_uid));';
            const p5 = db.executeSql(query, []);

            return Promise.all([p1, p2, p3, p4, p5]);
        }
    }

    async saveSurvey(survey: any): Promise<any>
    {
        const query = 'REPLACE INTO survey(code, title, type, created_on, last_modified_on, expires_on, added_on, questionnaire, translations) VALUES(?, ?, ?, ?, ?, ?, datetime("now"), ?, ?)';
        const params = [survey.Code, survey.Title, survey.Type, survey.CreatedOn, survey.LastModifiedOn, survey.ExpiresOn, JSON.stringify(survey.Questionnaire), JSON.stringify(survey.Translations)]
        return this.executeSql(query, params, null);
    }

    async getSurveyList(code: string = null): Promise<any[]>
    {
        const params = [];
        let query = "SELECT * FROM survey";
        if (code)
        {
            query += " WHERE code= ?";
            params.push(code);
        }
        query += " ORDER BY added_on desc";

        const res = await this.executeSql(query, params, null);
        const surveys = [];
        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            const survey =
            {
                Code: item.code,
                Title: item.title,
                Type: item.type,
                CreatedOn: item.created_on,
                LastModifiedOn: item.last_modified_on,
                ExpiresOn: item.expires_on,
                AddedOn: item.added_on,
                Questionnaire: JSON.parse(item.questionnaire),
                Translations: JSON.parse(item.translations)
            };

            surveys.push(survey);
        }
        return surveys;
    }

    async getSurvey(surveyCode: string): Promise<any>
    {
        const surveys = await this.getSurveyList(surveyCode);
        return surveys.length === 0 ? null : surveys[0];
    }

    async removeSurvey(surveyCode: string): Promise<void>
    {
        const query = "DELETE FROM survey WHERE code=?";
        await this.executeSql(query, [surveyCode], null);
        await this.deleteDb(surveyCode);
    }

    async getSurveyFiles(surveyCode: string, remotePath: string=null): Promise<any[]>
    {
        const params = [];
        let query = "SELECT * FROM survey_file";

        if (remotePath)
        {
            query += " WHERE remote_path=? LIMIT 1";
            params.push(remotePath);
        }

        const res = await this.executeSql(query, params, surveyCode);
        const files = [];
        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            const file =
            {
                RemotePath: item.remote_path,
                LocalPath: item.local_path,
                DownloadedOn: item.downloaded_on
            };
            files.push(file);
        }
        return files;
    }

    async getSurveyFile(surveyCode: string, remotePath: string)
    {
        const files: any = await this.getSurveyFiles(surveyCode, remotePath);
        if (files.length > 0)
            return files[0];
        else
            return null;
    }

    async getInterviewFiles(surveyCode: string, synced: number = null, uId:string=null, questCode: string=null): Promise<any[]>
    {
        const params = [];
        let query = "SELECT * FROM interview_file WHERE 1=1";

        if (uId)
        {
            query += " and sys_uid=?";
            params.push(uId);
        }

        if (questCode)
        {
            query += " and quest_code=?";
            params.push(questCode);
        }

        const syncClause = synced === 1 || synced === 0 ? ' AND synced=' + synced : '';
        query += syncClause;

        const res = await this.executeSql(query, params, surveyCode);
        const files = [];
        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            const file =
            {
                UId:item.sys_uid,
                Name: item.name,
                QuestCode: item.quest_code,
                Type: item.type,
                Status: item.status,
                CreatedOn:item.created_on
            };
            files.push(file);
        }
        return files;
    }

    async countInterviewFiles(surveyCode: string, synced: number = null, type: string = null): Promise<number>
    {
        const params = [];
        let query = "SELECT count(*) cnt FROM interview_file WHERE 1=1";

        const syncClause = synced === 1 || synced === 0 ? ' AND synced=' + synced : '';
        query += syncClause;

        if (type)
        {
            query += ' AND type=?';
            params.push(type);
        }
        else if (synced === 0) //Otherwise it will keep on showing unsynced missing files.
            query += " AND type!='missing'";

        const res = await this.executeSql(query, params, surveyCode);
        let count = 0;
        if (res.rows.length > 0)
            count = res.rows.item(0)['cnt'];

        return count;
    }

    async getInterviewFile(surveyCode: string, uId:string, questCode: string)
    {
        const files: any = await this.getInterviewFiles(surveyCode, null, uId, questCode);
        if (files.length > 0)
            return files[0];
        else
            return null;
    }

    async saveSurveyFile(surveyCode: string, localPath: string, remotePath:string, downloadedOn:Date): Promise<any>
    {
        const query = 'REPLACE INTO survey_file(local_path, remote_path, downloaded_on) VALUES(?, ?, ?)';
        const params = [localPath, remotePath, downloadedOn]
        return this.executeSql(query, params, surveyCode);
    }

    async saveInterviewFile(surveyCode: string, uId:string, name: string, questCode: string, type:string, status:string, createdOn: Date): Promise<any>
    {
        const query = 'REPLACE INTO interview_file(sys_uid, name, quest_code, type, status, created_on) VALUES(?, ?, ?, ?, ?, ?)';
        const params = [uId, name, questCode, type, status, createdOn];
        return this.executeSql(query, params, surveyCode);
    }

    async deleteInterviewFile(surveyCode:string, uId: string, questCode: string): Promise<any>
    {
        const query = 'DELETE FROM interview_file WHERE sys_uid=? AND quest_code=?';
        const params = [uId, questCode];
        return this.executeSql(query, params, surveyCode);
    }

    async updateInterviewFileStatus(surveyCode:string, uId: string, name: string, status: string): Promise<any>
    {
        const query = 'UPDATE interview_file SET status=? WHERE sys_uid=? and name=?';
        const params = [status, uId, name];
        return this.executeSql(query, params, surveyCode);
    }

    async saveResponses(surveyCode: string, uId: string, status: number, qMap: {}): Promise<any>
    {
        qMap = GenericService.clone(qMap);

        const fieldVals = Object.keys(qMap).filter(x => qMap[x].Unique).map(x =>
        {
            const q = qMap[x];
            let answer = q.Answer;
            if (!GenericService.isNullOrEmpty(q.PrependToAnswer))
                answer = q.PrependToAnswer + answer;
            if (!GenericService.isNullOrEmpty(q.AppendToAnswer))
                answer = answer + q.AppendToAnswer;

            return x + ':' + answer;
        });

        let csv = GenericService.toCsv(fieldVals, null, ';');
        if (csv === "")
            csv = null;

        const query = 'INSERT INTO interview(sys_uid, sys_survey_status, unique_field_values) VALUES(?, ?, ?) ON CONFLICT(sys_uid) DO UPDATE SET sys_survey_status=excluded.sys_survey_status, synced=0 WHERE sys_uid=excluded.sys_uid';
        const params = [uId, status, csv];

        const queries: any = [[query, params]];
        Object.keys(qMap).forEach(key =>
        {
            if (key !== "sys_survey_status" && key !== "sys_uid")
            {
                const quest = qMap[key];
                queries.push([
                    "INSERT INTO response_log(sys_uid, quest_code, data, created_on) VALUES (?, ?, ?, ?)",
                    [uId, key, JSON.stringify(quest), new Date()]
                ]);
                queries.push([
                    "REPLACE INTO response(sys_uid, quest_code, data) VALUES (?, ?, ?)",
                    [uId, key, JSON.stringify(quest)]
                ]);
            }
        });
        return this.sqlBatch(queries, surveyCode);
    }

    async markInterviewsAsSynced(surveyCode:string, uIds: string[]): Promise<any>
    {
        uIds = uIds.map(x => "'" + x + "'");
        const csv = GenericService.toCsv(uIds);
        const query = "UPDATE interview SET synced=1 WHERE sys_uid IN (" + csv + ")";
        return this.executeSql(query, [], surveyCode);
    }

    async markInterviewFileAsSynced(surveyCode: string, uId: string, questCode: string): Promise<any>
    {
        const query = "UPDATE interview_file SET synced=1 WHERE sys_uid=? and quest_code=?";
        return this.executeSql(query, [uId, questCode], surveyCode);
    }

    async clearInterviews(surveyCode:string, synced:number = null): Promise<any>
    {
        if (!surveyCode)
            throw new Error("Please mention Survey Code to Clear Interviews");
        const syncClause = synced === 1 || synced === 0 ? ' WHERE synced=' + synced : '';

        let query = "DELETE FROM response";
        if (synced === 1 || synced === 0)
            query += ' WHERE sys_uid IN (SELECT sys_uid FROM interview WHERE synced=' + synced + ')';

        return this.executeSql(query, [], surveyCode).then(() =>
        {
            query = "DELETE FROM interview" + syncClause;
            if (synced === 1 || synced === 0)
                query += 'WHERE synced= ' + synced;

            return this.executeSql(query, [], surveyCode);
        });
    }

    async clearSurveyFiles(surveyCode: string): Promise<any>
    {
        if (!surveyCode)
            throw new Error("Please mention Survey Code to clear files");

        const query = "DELETE FROM survey_file";

        return this.executeSql(query, [], surveyCode);
    }

    async clearInterviewFiles(surveyCode: string): Promise<any>
    {
        if (!surveyCode)
            throw new Error("Please mention Survey Code to clear Response Files");

        const query = "DELETE FROM interview_file";

        return this.executeSql(query, [], surveyCode);
    }

    async countInterviewsByStatus(surveyCode: string): Promise<any>
    {
        const query = "SELECT COUNT(*) AS cnt, synced, sys_survey_status FROM interview GROUP BY synced, sys_survey_status";
        const res = await this.executeSql(query, [], surveyCode);
        const rec: any = { Synced: { Complete: 0, Terminated: 0, Incomplete: 0 }, Unsynced: { Complete: 0, Terminated: 0, Incomplete: 0 } };

        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            const obj = item.synced ? rec.Synced : rec.Unsynced;

            if (item.sys_survey_status === SurveyStatus.Complete)
                obj.Complete = item.cnt;
            else if (item.sys_survey_status === SurveyStatus.Terminated)
                obj.Terminated = item.cnt;
            else
                obj.Incomplete = item.cnt;
        }

        return rec;
    }

    async getInterviews(surveyCode: string, filters = null, pageNo = 1, pageSize = 10): Promise<any[]>
    {
        let query = 'SELECT inv.sys_survey_status, r.* FROM (SELECT * FROM interview WHERE 1=1';
        const params = [];
        if (filters)
        {
            let csv = '';
            Object.keys(filters).forEach(key =>
            {
                if (key === 'synced' || key === 'sys_survey_status' || key === 'sys_uid')
                {
                    query += ` AND ${key}=?`;
                    params.push(filters[key]);
                }
                else
                    csv += key + ':' + filters[key] + '; ';
            });

            if (csv)
            {
                csv = csv.substring(0, csv.length - 2);
                query += ` AND unique_field_values=?`;
                params.push(csv);
            }
        }

        query += ' LIMIT ' + pageSize + ' OFFSET ' + ((pageNo - 1) * pageSize) + ') inv JOIN response r ON r.sys_uid=inv.sys_uid';

        const records = await this.executeSql(query, params, surveyCode);
        const qMaps = {};
        let qMap;
        for (let i = 0; i < records.rows.length; i++)
        {
            const item = records.rows.item(i);
            const uId = item["sys_uid"];
            qMap = qMaps[uId];
            if (!qMap)
            {
                qMap = {};
                qMap['sys_uid'] = { Code: 'sys_uid', Type: 'OpenEnd', Answer: uId };
                qMap['sys_survey_status'] = { Code: 'sys_survey_status', Type: 'OpenEnd', Answer: item["sys_survey_status"] };
                qMaps[uId] = qMap;
            }
            qMap[item.quest_code] = JSON.parse(item.data);
        }

        const list = [];
        Object.keys(qMaps).forEach(key =>
        {
            list.push(qMaps[key]);
        });
        return list;
    }

    async getAllSurveys() //For testing
    {
        const query = 'SELECT * FROM survey';
        return this.executeSql(query, [], null);
    }

    async getAllInterviews(surveyCode) //For testing
    {
        const query = 'SELECT * FROM interview';
        return this.executeSql(query, [], surveyCode);
    }

    async saveLog(log: any): Promise<any>
    {
        const createdById = log.CreatedBy ? log.CreatedBy.Id : null;
        const query = 'INSERT INTO log(id, message, level, survey_code, remarks, created_by_id, created_on) VALUES(?, ?, ?, ?, ?, ?, ?)';
        const params = [log.Id, log.Message, log.StackTrace, log.Level, log.SurveyCode, log.Remarks, createdById, log.CreatedOn];
        return this.executeSql(query, params, null);
    }

    async markLogsAsSynced(ids: number[]): Promise<any>
    {
        const idsStr = ids.map(x => "'" + x + "'");
        const csv = GenericService.toCsv(idsStr);
        const query = "UPDATE log SET synced=1 WHERE id IN (" + csv + ")";
        return this.executeSql(query, [], null);
    }

    async getLogs(surveyCode: string, synced: number = null, pageNo = 1, pageSize = 10): Promise<any>
    {
        let query = 'SELECT * FROM log WHERE 1=1';
        let params = [];
        if (surveyCode)
        {
            query += ' AND survey_code=?';
            params = [surveyCode];
        }

        if (synced === 1 || synced === 0)
            query += ' AND synced=' + synced;

        query += ' LIMIT ' + pageSize + ' OFFSET ' + ((pageNo - 1) * pageSize);

        const res = await this.executeSql(query, params, null);
        const logs = [];
        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            const log =
            {
                Id: item.id,
                Message: item.message,
                Level: item.level,
                SurveyCode: item.survey_code,
                Remarks: item.remarks,
                CreatedById: item.created_by_id,
                CreatedOn: item.created_on
            };
            logs.push(log);
        }
        return logs;
    }

    async countLogsByStatus(surveyCode: string): Promise<any>
    {
        let query = "SELECT COUNT(*) AS cnt, synced FROM log";
        let params = [];
        if (surveyCode)
        {
            query += ' WHERE survey_code=?';
            params = [surveyCode];
        }

        query += ' GROUP BY synced';
        const res = await this.executeSql(query, params, null);

        const rec: any = { Synced: 0, Unsynced: 0 };
        for (let i = 0; i < res.rows.length; i++)
        {
            const item = res.rows.item(i);
            if (item.synced)
                rec.Synced = item.cnt;
            else
                rec.Unsynced = item.cnt;
        }

        return rec;
    }

    async clearLogs(surveyCode:string, synced:number = null): Promise<any>
    {
        if (!surveyCode)
            throw new Error("Please mention Survey Code to Clear Logs");

        let query = 'DELETE FROM log WHERE survey_code=?';
        if (synced === 1 || synced === 0)
            query += ' AND synced=' + synced;

        return this.executeSql(query, [surveyCode], null);
    }
}
