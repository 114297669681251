import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import * as moment from 'moment';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CurrentSurvey } from './current-survey';
import { LogService } from './log.service';
import { RegexService } from "./regex.service";
import { IonicService } from "./ionic.service";
import { LogLevel } from './enum';
import { GlobalService } from './global.service';
import { FileService } from './file.service';
// import { SurveyPageService } from './survey-page.service';
//import { SurveyService } from './survey.service';
import { Styler } from './styler';
import { AjaxService } from './ajax.service';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { AppConfig } from './app.config';
import { MediaService } from './media.service';
import { isArray } from '@amcharts/amcharts4/core';

@Injectable()
export class ScriptService {
    gns = GenericService;
    gs = GlobalService;
    cs = CurrentSurvey;
    // sps = SurveyPageService;
    //surveyservice = SurveyService;
    styler = Styler;
    ns = NetworkService;
    ss = this;

    constructor(private ajaxService: AjaxService, private ionicService: IonicService, private ms: MediaService, private statusBar: StatusBar,
        private ls: LogService, private fs: FileService, private geolocation: Geolocation, private locationAccuracy: LocationAccuracy) //some services imported for evalExp
    {
    }

    isNullOrEmpty = GenericService.isNullOrEmpty;
    isNullOrEmptyOrZero = GenericService.isNullOrEmptyOrZero;
    appendTimeStamp = this.fs.appendTimeStamp;
    ions = this.ionicService;

    toCsv(list, propName = 'Text', seperator = ",") {
        return GenericService.toCsv(list, propName, seperator);
    }

    getCodeOrText(obj) {
        var obj1 = {
            Text: Text
        };
        obj1.Text = obj.Text;
        obj.Text = this.ss.evalPipeIns(obj1, "Text", null)
        if (!obj.Text)
            return obj.Code;
        if (obj.Code != obj.Text && CurrentSurvey.config.ShowCodes) {
            return obj.Code + '. ' + obj.Text;
        }
        return obj.Text;
    }



    getPostCodeOrText(obj) {
        var obj1 = {
            Text: Text
        };
        obj1.Text = obj.Text;
        obj.Text = this.ss.evalPipeIns(obj1, "Text", null)
        if (!obj.PostText)
            return obj.Code;
        if (obj.Code != obj.PostText && CurrentSurvey.config.ShowCodes)
            return obj.Code + '. ' + obj.PostText;

        return obj.PostText;
    }

    getText(obj, prop = 'Text') {
        let text = "";
        if (!obj || !obj[prop])
            return "";
        try {
            text = this.evalPipeIns(obj, prop, obj.Code + " PipeIn");
        }
        catch
        {
        }

        return text;
    }

    getPostText(obj, prop = 'PostText') {
        let text = "";
        if (!obj || !obj[prop])
            return "";
        try {
            text = this.evalPipeIns(obj, prop, obj.Code + " PipeIn");
        }
        catch
        {
        }

        return text;
    }

    shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            // Generate random number 
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    getPlainText(obj, prop = 'Text') {
        let text = this.getText(obj, prop);
        if (text)
            text = this.gns.stripHtml(text);
        return text;
    }

    evalPipeIns(obj, prop = 'Text', ref = null) {
        if (!obj || !obj[prop])
            return '';

        const text = obj[prop] || obj['Initial' + prop];
        let newText = unescape(text);

        //Modify below lines to support multiple PipeIns in text
        let groups = RegexService.commonPatterns.script.exec(newText);
        while (groups !== null) {
            const exp = groups[2].replace('[%', '').replace('%]', '').trim();
            const expVal = this.evalExp(exp, ref, false);
            newText = this.gns.replaceAll(newText, groups[2], expVal);
            groups = RegexService.commonPatterns.script.exec(newText);
        }
        return newText;
    }

    formatFilePaths(textOrObj: any, surveyCode: string) {
        if (!textOrObj)
            return textOrObj;

        let text;
        if (typeof textOrObj === 'object')
            text = JSON.stringify(textOrObj);
        else
            text = textOrObj;

        const regex = new RegExp(/(ProjectFiles\/)(\w|\d|-|\s|\/|\.|\(|\)|%|'|\[|\]|#)+\.(min\.)*(?:jpg|gif|png|bmp|svg|mp3|mp4|m4a|wav|css|js|json|text)/ig);

        for (let match; (match = regex.exec(text)) !== null;) {
            const remotePath = match[0].replace('ProjectFiles/', '');
            if (AppConfig.isApp) {
                const localPath = this.cs.surveyFilesDict[remotePath.toLowerCase()];
                if (!localPath) {
                    this.ls.log('File ' + remotePath + ' not found. Please sync to download missing files again.', LogLevel.Info);
                    return text; //We are not throwing error to avoid bad user experience while filling survey
                }
                text = text.replace(match[0], localPath);
            }
            else {
                let fileName = match[0];
                fileName = fileName.replace('ProjectFiles/', surveyCode + '/ProjectAssets/');
                fileName = AppConfig.filesUrl + fileName;
                text = text.replace(match[0], encodeURI(fileName));
            }
        }

        if (typeof textOrObj === 'object')
            textOrObj = JSON.parse(text);
        else
            textOrObj = text;

        return textOrObj;
    }

    pipeInAndFormatFilePaths(obj: any, prop: string, surveyCode: string) {
        let newText1, newText2;
        try {
            if (obj['has' + prop + 'PipeIns'] !== false) {
                if (obj[prop])
                    obj[prop] = obj[prop] ? unescape(obj[prop]) : '';
                newText1 = this.evalPipeIns(obj, prop, obj.Code + " " + prop + " PipeIn");
                if (newText1 !== obj[prop]) {
                    obj['has' + prop + 'PipeIns'] = true;
                    obj['Initial' + prop] = obj['Initial' + prop] || obj[prop];
                }
                else
                    obj['has' + prop + 'PipeIns'] = false;
            }
            else
                newText1 = obj[prop];

            if (obj['has' + prop + 'Url'] !== false) {
                newText2 = this.formatFilePaths(newText1, surveyCode);
                if (newText1 !== newText2) {
                    obj['has' + prop + 'Url'] = true;
                    obj['Initial' + prop] = obj['Initial' + prop] || obj[prop];
                }
                else
                    obj['has' + prop + 'Url'] = false;
            }
            else
                newText2 = newText1;
        }
        catch
        {
            obj['Initial' + prop] = obj[prop];
            newText2 = "PIPEIN ERRORR";
        }
        return newText2;
    }

    createPages() {
        this.cs.pages = [];
        this.cs.questions.forEach(quest => {
            this.cs.pages[quest.PageIndex] = this.cs.pages[quest.PageIndex] || { Questions: [], Index: quest.PageIndex };
            this.cs.pages[quest.PageIndex].Questions.push(quest);
        });
    }

    formatExp(exp) {
        //We should not pass context in this until we want to access some propery/function of that page
        if (typeof (exp) !== "boolean") {
            if (!this.isNullOrEmpty(exp)) {
                const append = 'that';
                //Use # so that it does not replace any text that is not required
                exp = GenericService.replaceAll(exp, '#ss.', append + '.');
                exp = GenericService.replaceAll(exp, '#cs.', append + '.cs.');
                exp = GenericService.replaceAll(exp, '#ms.', append + '.ms.');
                exp = GenericService.replaceAll(exp, '#ns.', append + '.ns.');
                exp = GenericService.replaceAll(exp, '#ions.', append + '.ions.');
                exp = GenericService.replaceAll(exp, '#gns.', append + '.gns.');
                exp = GenericService.replaceAll(exp, '#gs.', append + '.gs.');
                exp = GenericService.replaceAll(exp, '#styler.', append + '.styler.');
                exp = GenericService.replaceAll(exp, '#questions', append + '.cs.questions');
                exp = GenericService.replaceAll(exp, '#qMap', append + '.cs.qMap');
                exp = GenericService.replaceAll(exp, '#listMap', append + '.cs.listMap');
                exp = GenericService.replaceAll(exp, '#lang', append + '.cs.lang');

                //later on we will delete below lines to force # before these. ##DEPRECATED
                exp = GenericService.replaceAll(exp, 'ss.', append + '.');
                exp = GenericService.replaceAll(exp, 'ms.', append + '.ms.');
                //exp = GenericService.replaceAll(exp, 'ns.', append + '.ns.');
                exp = GenericService.replaceAll(exp, 'gns.', append + '.gns.');
                exp = GenericService.replaceAll(exp, 'gs.', append + '.gs.');
                exp = GenericService.replaceAll(exp, 'styler.', append + '.styler.');
                exp = GenericService.replaceAll(exp, 'questions', append + '.cs.questions');
                exp = GenericService.replaceAll(exp, 'qMap', append + '.cs.qMap');
                exp = GenericService.replaceAll(exp, 'listMap', append + '.cs.listMap');
                exp = GenericService.replaceAll(exp, 'lang', append + '.cs.lang');
            }
        }
        return exp;
    }

    callFn(exp, ref = null, alertError = true) {
        exp = this.formatExp('var that=context;' + exp);
        try {
            //If we are calling a fuction from external javascript file, we will have to call it this way "myCustomFn(context)". 
            //We cannot pass 'this' but to pass 'context'. 'this' here becomes window object. 
            //If it is validate fn then prepend return before it like "return myCustomFn(context)"
            const value = new Function("return function(context){" + exp + "}")();
            const res = value(this);
            return res;
        }
        catch (err) {
            this.actionOnErr(exp, err, ref, alertError);
        }
    }

    evalExp(exp, ref = null, alertError = true) {
        //We should not pass context in this until we want to access some propery/function of that page
        exp = this.formatExp('var that=this;' + exp);

        try {
            const hiddenIndex = exp.replace(' ', '').replace('.Hidden==', '').indexOf('.Hidden');
            if (hiddenIndex > -1)
                throw new Error('.Hidden is readonly');

            const res = eval(exp);
            return res;
        }
        catch (err) {
            this.actionOnErr(exp, err, ref, alertError);
        }
    }

    actionOnErr(exp, err, ref, alertError) {
        let remarks = "Error in logic";
        if (ref)
            remarks += "- " + ref;
        const msg: string = GenericService.fetchErrorMessage(err);
        console.log(msg, LogLevel.Error);
        console.log("Ref: " + ref);
        console.log(exp);
        this.ls.log(exp, LogLevel.Error, null, "Error in script. Ref: " + ref);
        if (!alertError)
            throw new Error(remarks);
        else {
            setTimeout(async () => {
                await this.ionicService.hideLoader();
                if (alertError)
                    alert(remarks);
            }, 100);
        }
    }

    evalText(text, ref = null) {
        //This function is called when variable used in expression contains another expression. 
        //So to execute that inner expression we call this and pass that variable inside it
        if (!text)
            return "";

        //EVAL: is used for simple expressions which directly represent a value. This can also be done with [% %]. This was developed prior to [% %] and may become obsolete in future
        //If we need to do some calculations and then return value with return statement then we need to use callFn.
        if (text.toString().indexOf('EVAL:') == 0) {
            text = text.substring(5);
            text = this.evalExp(text, ref);
        }
        if (!text)
            return "";
        return text.toString();
    }

    getMonthDiffFromCurrDate(month, year) {
        const currentDate = new Date();
        const targetDate = new Date(Number(year), Number(month) - 1, currentDate.getDate()); //month index starts from 0

        const m1 = moment(currentDate);
        const m2 = moment(targetDate);

        const diff = m2.diff(m1, 'months');

        return diff;
    }

    saveToNavHistory(pageIndex) {
        const navIndex = this.cs.navHistory.indexOf(pageIndex);

        if (navIndex === -1)
            this.cs.navHistory.push(pageIndex);
        else {
            this.cs.navHistory = this.cs.navHistory.slice(0, navIndex + 1);
        }
    }

    async getGeoLocation(slientCapture = false) {
        let err;
        if (AppConfig.isApp) {
            if (slientCapture) //silent capture mode does not work in browser
            {
                const isAuthroized = await this.ionicService.diagnostic.isLocationAuthorized();
                if (!isAuthroized)
                    return null;
                const isEnabled = await this.ionicService.diagnostic.isLocationEnabled;
                if (!isEnabled)
                    return null;
            }

            const canRequest = await this.locationAccuracy.canRequest();
            if (!canRequest)
                return null;

            [err] = await this.gns.to(this.ionicService.diagnostic.requestLocationAuthorization());
            if (err)
                return this.ionicService.showErrorToast(err, 'Location permission denied');

            [err] = await this.gns.to(this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY));
            if (err && !slientCapture)
                return this.ionicService.showErrorToast(err, 'Error requesting location permissions');
        }

        let res;
        [err, res] = await this.gns.to(this.geolocation.getCurrentPosition());
        if (err && !slientCapture)
            return this.ionicService.showErrorToast(err, 'Error while requesting geo location');

        return res;
    }

    async setDefaultAnswers(pageIndex) {
        const page = this.cs.pages[pageIndex];
        if (page) {
            for (let i = 0; i < page.Questions.length; i++) {
                const x = page.Questions[i];
                if (x.Default === "#CURRENT_DATE_TIME#" || x.Default === "#CURRENT_DATE#") {
                    const date = new Date();
                    x.Answer = date;
                }

                else if (x.Default === "#GPS_LOC#" || x.Default === "#GEO_LOC#") {
                    //If I make it synchronous using await and click 2 time start, it goes to next question
                    this.getGeoLocation(x.SilentCapture).then(res => {
                        if (res)
                            x.Answer = res.coords.latitude + "," + res.coords.longitude;
                    });
                }

                else if (x.Default === "#SURVEY_LANGUAGE#") {
                    x.Answer = this.cs.lang.Code;
                }

                else if (x.Default === "#INTERVIEWER_ID#")  //Id will be username for interviewer
                {
                    if (GlobalService.user)
                        x.Answer = GlobalService.user.UserName;
                }

                else if (x.Default === "#INTERVIEWER_NAME#") {
                    if (GlobalService.user)
                        x.Answer = GlobalService.user.Name;
                }
            }
        }
    }

    skipTo(questOrCode, saveSkippedPagesInHistory = false, preventSkipBack = true) //jumps directly to that question without touching others
    {
        if (!questOrCode)
            throw new Error('Please provide Question Code.');

        let targetQuest;
        if (typeof (questOrCode) !== 'object') {
            const code = Object.keys(this.cs.qMap).find(x => x.toLowerCase() == questOrCode.toLowerCase());
            if (!code)
                throw new Error('Invalid Question Code:' + questOrCode);
            targetQuest = this.cs.qMap[code];
        }
        else
            targetQuest = questOrCode;

        const prev = this.cs.page && this.cs.page.Index > targetQuest.PageIndex;

        if (this.cs.page) //On OnLoad if this fn is called page is null
        {
            if (this.cs.page.Index === targetQuest.PageIndex) //Nothing to do. But this should never be the case
                return;
            if (preventSkipBack && this.cs.page.Index && !targetQuest.SkipBack > targetQuest.PageIndex) {
                throw new Error('Skip back not allowed: ' + questOrCode);
            }
            else if (preventSkipBack && this.cs.page.Index && !targetQuest.SkipBack > targetQuest.PageIndex) {
                this.saveToNavHistory(this.cs.page.Index);
            }
            else if (targetQuest.SkipBack) {
                this.saveToNavHistory(this.cs.page.Index);
            }
        }

        let startIndex, endIndex;
        if (!prev) //skip next
        {
            startIndex = this.cs.page ? this.cs.page.Index + 1 : 0;
            endIndex = targetQuest.PageIndex;
        }
        else //skip back
        {
            startIndex = targetQuest.PageIndex;
            endIndex = this.cs.page.Index - 1;
        }

        for (let i = startIndex + 1; i < endIndex; i++) //loop on pages inside start and end
        {
            //Hiding it for now. If we skip on a question and dispose. When we reopen and move back it does not know where to go and moves only 1 page back. 
            //We will need to save nav history to database and fetch it back in such case

            //if (saveSkippedPagesInHistory)  //This is required when we restart the survey, we need to move to last attempted question and user may need to go to previous questions
            //{
            //    if (this.cs.pages[i].Questions.find(x => !x.Hidden))
            //        this.saveToNavHistory(i); //in prev it will pop
            //}

            this.cs.pages[i].Questions.forEach(quest => {
                // if (!saveSkippedPagesInHistory)
                //     this.reset(quest);
                if (!prev) {
                    //We will not execute PreEnter and Prexit of previously answered questions on restart.
                    //So show / hide or any other logic related to forthcoming questions should be applied on their PreEnter / PreExit only
                    //We will also not allow Goback to prv questions on restart
                    //if (quest.PreEnter)
                    //    this.evalExp(quest.PreEnter, null, quest.Code + ' - PreEnter');

                    if (!saveSkippedPagesInHistory)
                        quest.Skipped = true;
                    else //this case is for restart survey. check this thoroughly
                    {
                        if (!quest.Hidden) {
                            //if (quest.OnChange) //Commenting this because it can reset previous filled value of answer in case of cascading
                            //    this.evalExp(quest.OnChange);
                            //if (quest.PreExit)
                            //    this.evalExp(quest.PreExit);
                        }
                    }
                }
                else
                    quest.Skipped = false;
            });
        }

        if (!prev) {
            for (const quest of this.cs.pages[targetQuest.PageIndex].Questions) {
                if (quest.PreEnter)
                    this.evalExp(quest.PreEnter, quest.Code + ' - PreEnter');
            }
        }

        //skipped pages do not maintained in nav history until mentioned specifically (e.g restart survey). 
        //So skippedPageIndex will serve the purpose of finding which page survey skipped to.
        this.cs.skipped = true;
        this.cs.skippedToPageIndex = targetQuest.PageIndex;
        return targetQuest.PageIndex;
    }

    getObjectByCode(code, qMap = this.cs.qMap) //Code can be Object Reference also
    {
        if (typeof (code) === 'object') //if object is passed then return it as it is
            return code;

        //quest/row/column/cell/choices. //This fn can be used to reset saved data. That's why we may need to pass qMap as an argument
        //In case of choices we can pass like Q1$C1
        //In case of Row- Q$R1, for Column: Q1$C1, for Cell- Q1$R1$C1.
        //We are not using underscore here to seperate as it is also allowed in other questions. But we do not allow $ in question codes

        let quest = qMap[code];
        if (quest)
            return quest;

        let obj;
        const arr = code.toString().split('$');
        if (arr.length > 1) {
            quest = qMap[arr[0]];
            if (quest && (quest.Type === 'Grid' || quest.Type === 'DartBoard')) {
                let row, col;

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i].startsWith('R')) {
                        const rowCode = arr[i].substr(1);
                        if (!this.isNullOrEmpty(rowCode))
                            row = quest.RowMap[rowCode];
                    }

                    else if (arr[i].startsWith('C')) {
                        const colCode = arr[i].substr(1);
                        if (!this.isNullOrEmpty(colCode))
                            col = quest.ColMap[colCode];
                    }
                }

                if (row && col) {
                    if (quest.Direction === 'Row') {
                        const cell = row.CellMap[col.Code];
                        obj = cell;
                    }
                    else {
                        const cell = col.CellMap[row.Code];
                        obj = cell;
                    }
                }
                else if (row)
                    obj = row;
                else if (col)
                    obj = col;
            }

            else if (quest && (quest.Type === 'SingleSelect' || quest.Type === 'MultiSelect')) {
                if (arr[1].startsWith('C')) {
                    const choiceCode = arr[1].substr(1);
                    if (!this.isNullOrEmpty(choiceCode)) {
                        obj = quest.CMap[choiceCode];
                    }
                }
            }
        }
        if (!obj)
            throw new Error("Object " + code + " does not exist");

        return obj;
    }

    reset(objectOrCodes) {
        objectOrCodes = GenericService.toArray(objectOrCodes);
        objectOrCodes.forEach(x => {
            const obj = this.getObjectByCode(x); //quest/row/column/cell/choice

            if (obj.Error)
                setTimeout(() => { obj.Error = null });

            delete obj.Selected;
            delete obj.Answer;
            delete obj.Answers;
            delete obj.OtherAnswer;
            delete obj.Attachment;
            delete obj.answered;

            if (obj.Type === 'MultiSelect' || obj.Type === 'Ranking' || obj.Type === 'Grid' || obj.Type === 'DartBoard')
                this.resetChoiceRowColCell(obj);
        });
    }

    resetChoiceRowColCell(quest, onlyHidden = false) {
        const loopOn = ['Choices', 'Rows', 'Columns', 'Cells'];
        loopOn.forEach(prop => {
            if (quest[prop]) {
                quest[prop].forEach(x => {
                    if ((onlyHidden && x.Hidden) || !onlyHidden) {
                        this.reset(x);
                    }
                });
            }
        });
    }

    resetIfAnswered(questOrCode, questOrCodesToReset) {
        if (this.isAnswered(questOrCode))
            this.reset(questOrCodesToReset);
    }

    resetIfNotAnswered(questOrCode, questOrCodesToReset) {
        if (!this.isAnswered(questOrCode))
            this.reset(questOrCodesToReset);
    }

    resetIfSelectedAny(questOrCode, choiceCodes: number | number[], questOrCodesToReset) {
        if (this.isSelectedAny(questOrCode, choiceCodes))
            this.reset(questOrCodesToReset);
    }

    resetIfNotSelectedAny(questOrCode, choiceCodes: number | number[], questOrCodesToReset) {
        if (!this.isSelectedAny(questOrCode, choiceCodes))
            this.reset(questOrCodesToReset);
    }

    compare(value1, value2, op) {
        if (op === '==') return value1 == value2;
        else if (op === '!=') return value1 != value2;
        else if (op === '>') return value1 > value2;
        else if (op === '>=') return value1 >= value2;
        else if (op === '<') return value1 < value2;
        else if (op === '<=') return value1 <= value2;
        else throw new Error("Invalid operator '" + op + " in compare()");
    }

    filterChoices(questOrCodes, prop: string, value, op = '==', excludeChoices = []) //Supports SingleSelect, MultiSelect, Ranking including Grid (non Dropdown) Subquests  
    {
        excludeChoices = GenericService.toNumberArray(excludeChoices);
        questOrCodes = GenericService.toArray(questOrCodes);
        let filtered = [];

        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);

            if (quest.Type !== "SingleSelect" && quest.Type !== "MultiSelect" && quest.Type !== "Ranking")
                throw new Error("Fn 'filterChoices()' not supported for " + quest.Type);

            if (quest.Cells && quest.Mode == 'DropDown')
                throw new Error("Fn 'filterChoices()' not supported for DropDowns in Grid");

            const choices = quest.Choices || quest.Cells;

            if (quest.Type === "SingleSelect" || quest.Type === "MultiSelect" || quest.Type === "Ranking") //returns array
            {
                const selectedChoices = choices.filter(x => {
                    if (excludeChoices.indexOf(x.Code) >= 0)
                        return;

                    return this.compare(x[prop], value, op);
                });
                if (selectedChoices && selectedChoices.length > 0)
                    filtered = filtered.concat(selectedChoices);
            }
        });
        return filtered;
    }

    getSelectedChoices(questOrCodes, excludeChoices = []) //It will return selected choices/
    {
        excludeChoices = GenericService.toNumberArray(excludeChoices);
        questOrCodes = GenericService.toArray(questOrCodes);
        let selected = [];

        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);

            if (quest.Type === "SingleSelect")
                selected = selected.concat(this.filterChoices(quest, 'Code', quest.Answer, '==', excludeChoices));
            else if (quest.Type === "MultiSelect")
                selected = selected.concat(this.filterChoices(quest, 'Selected', true, '==', excludeChoices));
            else if (quest.Type === "Grid") {
                const subQuests = quest.Direction === 'Row' ? quest.Rows : quest.Columns;

                subQuests.forEach((obj) => {
                    if ((obj.Type === 'SingleSelect' && obj.Mode !== 'DropDown')) {
                        if (!this.isNullOrEmpty(obj.Answer) && (excludeChoices.indexOf(obj['Code']) === -1))
                            selected.push(obj);
                    }
                    else {
                        obj.Cells.forEach((cell) => {
                            if (obj.Type === 'MultiSelect') {
                                if (cell.Selected && (excludeChoices.indexOf(cell['Code']) === -1))
                                    selected.push(cell);
                            }
                            else if (!this.isNullOrEmpty(cell.Answer) && (excludeChoices.indexOf(cell['Code']) === -1))
                                selected.push(cell);
                        });
                    }
                });
            }
        });
        return selected;
    }

    getSelectedChoiceCodes(questOrCodes, excludeChoices = []) {
        const selected = this.getSelectedChoices(questOrCodes, excludeChoices);
        return selected;
    }

    getGridAnswers(questOrCode, excludeChoices = []) //It will return selected choices/
    {
        excludeChoices = GenericService.toNumberArray(excludeChoices);
        const quest = this.getObjectByCode(questOrCode);
        const answers = [];

        const subQuests = quest.Direction === 'Row' ? quest.Rows : quest.Columns;

        subQuests.forEach((obj) => {
            if (obj.Type === 'SingleSelect' && obj.Mode !== 'DropDown') {
                if (!this.isNullOrEmpty(obj.Answer) && (excludeChoices.indexOf(obj['Code']) === -1))
                    answers.push(obj);
            }
            else {
                if (obj.Cells) {
                    obj.Cells.forEach((cell) => {
                        if (obj.Type === 'MultiSelect') {
                            if (cell.Selected && (excludeChoices.indexOf(cell['Code']) === -1))
                                answers.push(cell);
                        }
                        else if (!this.isNullOrEmpty(cell.Answer) && (excludeChoices.indexOf(cell['Code']) === -1))
                            answers.push(cell);
                    });
                }
            }
        });
        return answers;
    }

    getVisibleChoices(questOrCodes, excludeChoices = []) {
        return this.filterChoices(questOrCodes, 'Hidden', true, '!=', excludeChoices);
    }

    getVisibleRowOrColumns(questOrCodes, prop: string, excludeRows = []) {
        let visible = [];
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);

            if (quest.Type !== "Grid" && quest.Type !== "DartBoard")
                throw new Error("Fn 'getVisibleRowOrColumns()' not supported for " + quest.Type);

            visible = visible.concat(quest[prop].filter(x => x.Hidden !== true && excludeRows.indexOf(x.Code) === -1));
        });

        return visible;
    }

    getNextVisibleChoice(questOrCode, choiceCode: number, excludeChoices = []) {
        const choices = this.getVisibleChoices(questOrCode, excludeChoices);
        const index = choices.findIndex(x => x.Code === choiceCode)
        return choices[index + 1];
    }

    getNextVisibleRowOrColumn(questOrCode, rowOrColumnCode: number, prop: string, excludeChoices = []) {
        const visible = this.getVisibleRowOrColumns(questOrCode, prop, excludeChoices);
        const index = visible.findIndex(x => x.Code === rowOrColumnCode);
        const visibleNext = visible[index + 1];
        return visibleNext;
    }

    isAnswered(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);

        if (quest.Type === 'Instruction')
            return false;

        if (quest.Type === 'MultiSelect')
            return quest.Choices.find(x => x.Selected);

        if (quest.Type === 'Ranking')
            return quest.Choices.find(x => !this.isNullOrEmpty(x.Answer));

        else if (quest.Type === 'HotSpot')
            return quest.Answers && quest.Answers.length > 0;

        else if (quest.Type === 'Grid' || quest.Type === 'DartBoard')
            return this.getGridAnswers(quest).length > 0;

        return !this.isNullOrEmpty(quest.Answer);
    }

    isSelectedAny(questOrCode, choiceCodes: number | number[]) {
        const chCodes = GenericService.toArray(choiceCodes, true);
        const quest = this.getObjectByCode(questOrCode);
        if (quest.Type === 'SingleSelect' && !this.isNullOrEmpty(quest.Answer) && chCodes.indexOf(parseInt(quest.Answer)) > -1)
            return true;
        if (quest.Type === 'MultiSelect') {
            if (quest.Choices.find(x => x.Selected && chCodes.indexOf(x.Code) > -1))
                return true;
        }
        return false;
    }

    complete(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);
        quest.Complete = true;
    }

    terminate(questOrCode, review = undefined) {
        let skipped = false;
        if (review === true || ((review === undefined || review === null) && this.cs.config.ReviewBeforeTerminate)) {
            const quest = this.getObjectByCode('TermReview');
            if (quest) {
                this.skipTo(quest);
                skipped = true;
            }
        }
        if (!skipped) {
            const quest = this.getObjectByCode(questOrCode);
            quest.Terminate = true;
        }
    }

    terminateIfAnswered(questOrCode, review = false) {
        const quest = this.getObjectByCode(questOrCode);
        if (this.isAnswered(quest))
            this.terminate(quest, review);
        else
            quest.Terminate = false;
    }

    terminateIfNotAnswered(questOrCode, review = undefined) {
        const quest = this.getObjectByCode(questOrCode);
        if (!this.isAnswered(quest))
            this.terminate(quest, review);
        else
            quest.Terminate = false;
    }

    terminateIfSelectedAny(questOrCode, choiceCodes: number | number[], review = undefined) {
        const quest = this.getObjectByCode(questOrCode);
        if (this.isSelectedAny(quest, choiceCodes))
            this.terminate(quest, review);
        else
            quest.Terminate = false;
    }

    terminateIfNotAnsweredAny(questOrCode, choiceCodes: number | number[], review = undefined) {
        const quest = this.getObjectByCode(questOrCode);
        if (!this.isSelectedAny(quest, choiceCodes))
            this.terminate(quest, review);
        else
            quest.Terminate = false;
    }

    disable(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);
        quest.Disabled = true;
    }

    enable(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);
        quest.Disabled = false;
    }

    disableIfAnswered(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);
        if (this.isAnswered(quest))
            this.disable(quest);
        else
            quest.Disabled = false;
    }

    disableIfNotAnswered(questOrCode) {
        const quest = this.getObjectByCode(questOrCode);
        if (!this.isAnswered(quest))
            this.disable(quest);
        else
            quest.Disabled = false;
    }

    disableIfSelectedAny(questOrCode, choiceCodes: number | number[]) {
        const quest = this.getObjectByCode(questOrCode);
        if (this.isSelectedAny(quest, choiceCodes))
            this.disable(quest);
        else
            quest.Disabled = false;
    }

    disableIfNotAnsweredAny(questOrCode, choiceCodes: number | number[]) {
        const quest = this.getObjectByCode(questOrCode);
        if (!this.isSelectedAny(quest, choiceCodes))
            this.disable(quest);
        else
            quest.Disabled = false;
    }

    isHidden(questOrCode) {
        const obj = this.getObjectByCode(questOrCode);
        return obj.Hidden;
    }

    show(questOrCodes) {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const obj = this.getObjectByCode(x);
            obj.Hidden = false;
        });
    }

    hide(objOrCodes) //row/ col/ cell/ choice. We can also use hideChoice for choice. Hiding questions not supported
    {
        //If there are some Autocode questions, those will also be cleared on Skip. So progammer should place autocodes on visible pages only.
        //Hidden Choices/Rows/Columns will not be saved in database. 
        //If we need to save hide and save answers both then we will set CssClass = Hidden

        objOrCodes = GenericService.toArray(objOrCodes);
        objOrCodes.forEach(x => {
            if (typeof (x) === 'string')
                x = this.getObjectByCode(x);

            // if (x.PageIndex !== undefined && this.cs.page && x.PageIndex < this.cs.page.Index)
            // throw new Error('You cannot hide questions on previous pages.');

            x.Hidden = true;
            this.reset(x);
        });
    }

    skipToIfAnswered(questOrCode, skipToQuestCode) //Does not matter what answer is given
    {
        if (this.isAnswered(questOrCode))
            this.skipTo(skipToQuestCode);
    }

    skipToIfSelectedAny(questOrCode, choiceCodes: number | number[], skipToQuestCode) {
        if (this.isSelectedAny(questOrCode, choiceCodes))
            this.skipTo(skipToQuestCode);
    }

    skipToIfNotAnswered(questOrCode, skipToQuestCode) //Does not matter what answer is given
    {
        if (!this.isAnswered(questOrCode))
            this.skipTo(skipToQuestCode);
    }

    skipToIfNotSelectedAny(questOrCode, choiceCodes: number | number[], skipToQuestCode) {
        if (!this.isSelectedAny(questOrCode, choiceCodes))
            this.skipTo(skipToQuestCode);
    }

    hideIfAnswered(questOrCode, objOrCodesToHide) //Does not matter what answer is given
    {
        if (this.isAnswered(questOrCode))
            this.hide(objOrCodesToHide);
        else
            this.show(objOrCodesToHide);
    }

    hideIfSelectedAny(questOrCode, choiceCodes: number | number[], objOrCodesToHide) {
        if (this.isSelectedAny(questOrCode, choiceCodes))
            this.hide(objOrCodesToHide);
        else
            this.show(objOrCodesToHide);
    }

    hideIfNotAnswered(questOrCode, objOrCodesToHide) //Does not matter what answer is given
    {
        if (!this.isAnswered(questOrCode))
            this.hide(objOrCodesToHide);
        else
            this.show(objOrCodesToHide);
    }

    hideIfNotSelectedAny(questOrCode, choiceCodes: number | number[], objOrCodesToHide) {
        if (!this.isSelectedAny(questOrCode, choiceCodes))
            this.hide(objOrCodesToHide);
        else
            this.show(objOrCodesToHide);
    }

    selectChoices(questOrCode, choiceCodes: number | number[]) {
        choiceCodes = GenericService.toArray(choiceCodes);
        const quest = this.getObjectByCode(questOrCode);
        choiceCodes.forEach(x => {
            if (quest.CMap[x]) {
                quest.CMap[x].Selected = true;
            }
        });
    }

    unSelectChoices(questOrCode, choiceCodes: number | number[]) {
        choiceCodes = GenericService.toArray(choiceCodes);
        const quest = this.getObjectByCode(questOrCode);
        choiceCodes.forEach(x => {
            if (quest.CMap[x]) {
                quest.CMap[x].Selected = false;
            }
        });
    }

    showChoices(questOrCode, choiceCodes: number | number[]) {
        choiceCodes = GenericService.toArray(choiceCodes);
        const quest = this.getObjectByCode(questOrCode);
        choiceCodes.forEach(x => {
            if (quest.CMap[x]) {
                quest.CMap[x].Hidden = false;
            }
        });
    }

    hideChoices(questOrCode, choiceCodes) //Exclusively for Choices. Not for Choices in Grid
    {
        choiceCodes = GenericService.toArray(choiceCodes);
        const quest = this.getObjectByCode(questOrCode);
        choiceCodes.forEach(code => {
            const choice = quest.CMap[code];
            if (choice)
                this.hide(choice);
        });
    }

    hideChoicesIfAnswered(questOrCode, questOrCodesTarget, choiceCodesToHide) {
        if (this.isAnswered(questOrCode))
            this.hideChoices(questOrCodesTarget, choiceCodesToHide);
        else
            this.showChoices(questOrCodesTarget, choiceCodesToHide);
    }

    hideChoicesIfSelectedAny(questOrCode, choiceCodes: number | number[], questOrCodesTarget, choiceCodesToHide) {
        if (this.isSelectedAny(questOrCode, choiceCodes))
            this.hideChoices(questOrCodesTarget, choiceCodesToHide);
        else
            this.showChoices(questOrCodesTarget, choiceCodesToHide);
    }

    hideChoicesIfNotAnswered(questOrCode, questOrCodesTarget, choiceCodesToHide) {
        if (!this.isAnswered(questOrCode))
            this.hideChoices(questOrCodesTarget, choiceCodesToHide);
        else
            this.showChoices(questOrCodesTarget, choiceCodesToHide);
    }

    hideChoicesIfNotSelectedAny(questOrCode, choiceCodes: number | number[], questOrCodesTarget, choiceCodesToHide) {
        if (!this.isSelectedAny(questOrCode, choiceCodes))
            this.hideChoices(questOrCodesTarget, choiceCodesToHide);
        else
            this.showChoices(questOrCodesTarget, choiceCodesToHide);
    }

    setAnswer(questOrCodeSource, questOrCodeTarget, invert = false) //invert only for choices
    {
        const questSource = this.getObjectByCode(questOrCodeSource);
        const questTarget = this.getObjectByCode(questOrCodeTarget);

        if (questSource.Type === "MultiSelect") {
            const choices = questSource.Choices.filter(choice => choice.Selected);
            if (choices.length > 0) {
                if (questTarget.Type === "MultiSelect") {
                    choices.forEach(x => {
                        if (!x.Hidden) {
                            const ch = questTarget.CMap[x.Code];
                            if (ch && !ch.Hidden) {
                                ch.Selected = !invert;
                            }
                        }
                    });
                }
                else
                    throw new Error('Incompatiple types ' + questSource.Code + ' & ' + questTarget.Code);
            }
        }
        else if (questSource.Type === "SingleSelect" && !this.isNullOrEmpty(questSource.Answer)) {
            if (questTarget.Type === "MultiSelect") {
                const ch = questTarget.CMap[questSource.Answer];
                if (ch && !ch.Hidden)
                    ch.Selected = true;
            }
            else {
                questTarget.Answer = questSource.Answer;
            }
        }
        else
            this.copyAnswer(questOrCodeSource, questOrCodeTarget);
    }

    copyAnswer(questOrCodeSource, questOrCodeTarget) {
        const questSource = this.getObjectByCode(questOrCodeSource);
        const questTarget = this.getObjectByCode(questOrCodeTarget);

        this.reset(questTarget); //This is to remove old answer if it has
        if (questSource.Type === 'Instruction' || !this.isAnswered(questSource))
            return;

        if (questTarget.Type === 'SingleSelect') {
            if (questSource.Type === questTarget.Type) {
                const targetChoice = questTarget.CMap[questSource.Answer];
                if (targetChoice) {
                    questTarget.Answer = questSource.Answer;
                    if (targetChoice.Other)
                        questTarget.OtherAnswer = questSource.OtherAnswer;
                }
            }

            if (questSource.Type === 'MultiSelect') {
                const selected = questSource.Choices.filter(x => x.Selected);
                for (let i = 0; i < selected.length; i++) {
                    const targetChoice = questTarget.CMap[selected[i].Code];
                    if (targetChoice) {
                        questTarget.Answer = targetChoice.Code;
                        break;
                    }
                }
            }
        }

        else if (questTarget.Type === 'OpenEnd') {
            if (questSource.Type === questTarget.Type
                && (questSource.Mode === questTarget.Mode || questTarget.Mode === 'Text')) {
                questTarget.Answer = questSource.Answer;
            }
            else if (questSource.Type === 'SingleSelect') {
                const choice = questSource.Choices.find(x => x.Code === questSource.Answer);
                if (choice)
                    questTarget.Answer = choice.Text;
            }
        }

        else if (questTarget.Type === 'HotSpot') {
            if (questSource.Type === questTarget.Type)
                questTarget.Answers = questSource.Answers;
        }

        else if (questTarget.Type === 'MultiSelect') {
            if (!questSource.Choices)
                throw new Error("Choices of " + questSource.Code + " cannot be null");
            if (questSource.Type === questTarget.Type) {
                questSource.Choices.forEach(sourceChoice => {
                    if (sourceChoice.Selected) {
                        const targetChoice = questTarget.CMap[sourceChoice.Code];
                        if (targetChoice) {
                            targetChoice.Selected = true;
                            if (sourceChoice.Other && targetChoice.Other)
                                targetChoice.OtherAnswer = sourceChoice.OtherAnswer;
                        }
                    }
                });
            }
            else if (questTarget.Type === 'SingleSelect') {
                const targetChoice = questTarget.CMap[questSource.Answer];
                if (targetChoice) {
                    targetChoice.Selected = true;
                    if (targetChoice.Other)
                        targetChoice.OtherAnswer = questSource.OtherAnswer;
                }
            }
        }

        else if (questTarget.Type === 'Ranking') {
            if (!questSource.Choices)
                throw new Error("Choices of " + questSource.Code + " cannot be null");
            if (questSource.Type === questTarget.Type) {
                questSource.Choices.forEach(sourceChoice => {
                    if (!this.isNullOrEmpty(sourceChoice.Answer)) {
                        const targetChoice = questTarget.CMap[sourceChoice.Code];
                        if (targetChoice) {
                            targetChoice.Answer = sourceChoice.Answer;
                        }
                    }
                });
            }
        }

        else if (questTarget.Type === "Grid" || questTarget.Type === "DartBoard") {
            //In Grid currently we are copying only when type and mode match. But we can handle compatible types in future as done above
            if (questSource.Type === questTarget.Type && questSource.Mode === questTarget.Mode) {
                const sourceSubQuests = questSource.Direction === 'Row' ? questSource.Rows : questSource.Columns;
                const targetSubQuests = questTarget.Direction === 'Row' ? questTarget.Rows : questTarget.Columns;

                for (let i = 0; i < sourceSubQuests.length; i++) {
                    const sourceObj = sourceSubQuests[i];
                    const targetObj = targetSubQuests.find(x => x.Code == sourceObj.Code);

                    if (sourceObj.Type === targetObj.Type && sourceObj.Mode === targetObj.Mode) {
                        if (sourceObj.Type === 'SingleSelect' && sourceObj.Mode !== 'DropDown') {
                            if (!this.isNullOrEmpty(sourceObj.Answer)) {
                                if (targetObj)
                                    targetObj.Answer = sourceObj.Answer;
                            }
                        }
                        else {
                            sourceObj.Cells.forEach(sourceCell => {
                                const targetCell = targetObj.Cells.find(x => x.Code == sourceCell.Code);
                                if (targetCell) {
                                    if (sourceObj.Type === 'MultiSelect') {
                                        if (sourceCell.Selected)
                                            targetCell.Selected = true;
                                    }
                                    else if (!this.isNullOrEmpty(sourceCell.Answer))
                                        targetCell.Answer = sourceCell.Answer;
                                }
                            });
                        }
                    }
                }
            }
        }

        if (questSource.Attachment)
            questTarget.Attachment = questSource.Attachment;
    }

    updateAnswerFromQuest(questOrCodeSource, questOrCodeTarget) {
        const questRef = this.getObjectByCode(questOrCodeSource);
        const increment = !isNaN(questRef.Answer) && (questRef.Answer) && (questRef.Answer != "") ? parseInt(questRef.Answer) : 0;

        questOrCodeSource = questOrCodeTarget + "x" + increment;
        const questSource = this.getObjectByCode(questOrCodeSource);
        const questTarget = this.getObjectByCode(questOrCodeTarget);

        this.reset(questTarget); //This is to remove old answer if it has
        if (questSource.Type === 'Instruction' || !this.isAnswered(questSource))
            return;

        if (questTarget.Type === 'SingleSelect') {
            if (questSource.Type === questTarget.Type) {
                const targetChoice = questTarget.CMap[questSource.Answer];
                if (targetChoice) {
                    questTarget.Answer = questSource.Answer;
                    if (targetChoice.Other)
                        questTarget.OtherAnswer = questSource.OtherAnswer;
                }
            }

            if (questSource.Type === 'MultiSelect') {
                const selected = questSource.Choices.filter(x => x.Selected);
                for (let i = 0; i < selected.length; i++) {
                    const targetChoice = questTarget.CMap[selected[i].Code];
                    if (targetChoice) {
                        questTarget.Answer = targetChoice.Code;
                        break;
                    }
                }
            }
        }

        else if (questTarget.Type === 'OpenEnd') {
            if (questSource.Type === questTarget.Type
                && (questSource.Mode === questTarget.Mode || questTarget.Mode === 'Text')) {
                questTarget.Answer = questSource.Answer;
            }
            else if (questSource.Type === 'SingleSelect') {
                const choice = questSource.Choices.find(x => x.Code === questSource.Answer);
                if (choice)
                    questTarget.Answer = choice.Text;
            }
        }

        else if (questTarget.Type === 'HotSpot') {
            if (questSource.Type === questTarget.Type)
                questTarget.Answers = questSource.Answers;
        }

        else if (questTarget.Type === 'MultiSelect') {
            if (!questSource.Choices)
                throw new Error("Choices of " + questSource.Code + " cannot be null");
            if (questSource.Type === questTarget.Type) {
                questSource.Choices.forEach(sourceChoice => {
                    if (sourceChoice.Selected) {
                        const targetChoice = questTarget.CMap[sourceChoice.Code];
                        if (targetChoice) {
                            targetChoice.Selected = true;
                            if (sourceChoice.Other && targetChoice.Other)
                                targetChoice.OtherAnswer = sourceChoice.OtherAnswer;
                        }
                    }
                });
            }
            else if (questTarget.Type === 'SingleSelect') {
                const targetChoice = questTarget.CMap[questSource.Answer];
                if (targetChoice) {
                    targetChoice.Selected = true;
                    if (targetChoice.Other)
                        targetChoice.OtherAnswer = questSource.OtherAnswer;
                }
            }
        }

        else if (questTarget.Type === 'Ranking') {
            if (!questSource.Choices)
                throw new Error("Choices of " + questSource.Code + " cannot be null");
            if (questSource.Type === questTarget.Type) {
                questSource.Choices.forEach(sourceChoice => {
                    if (!this.isNullOrEmpty(sourceChoice.Answer)) {
                        const targetChoice = questTarget.CMap[sourceChoice.Code];
                        if (targetChoice) {
                            targetChoice.Answer = sourceChoice.Answer;
                        }
                    }
                });
            }
        }

        else if (questTarget.Type === "Grid" || questTarget.Type === "DartBoard") {
            //In Grid currently we are copying only when type and mode match. But we can handle compatible types in future as done above
            if (questSource.Type === questTarget.Type && questSource.Mode === questTarget.Mode) {
                const sourceSubQuests = questSource.Direction === 'Row' ? questSource.Rows : questSource.Columns;
                const targetSubQuests = questTarget.Direction === 'Row' ? questTarget.Rows : questTarget.Columns;

                for (let i = 0; i < sourceSubQuests.length; i++) {
                    const sourceObj = sourceSubQuests[i];
                    const targetObj = targetSubQuests.find(x => x.Code == sourceObj.Code);

                    if (sourceObj.Type === targetObj.Type && sourceObj.Mode === targetObj.Mode) {
                        if (sourceObj.Type === 'SingleSelect' && sourceObj.Mode !== 'DropDown') {
                            if (!this.isNullOrEmpty(sourceObj.Answer)) {
                                if (targetObj)
                                    targetObj.Answer = sourceObj.Answer;
                            }
                        }
                        else {
                            sourceObj.Cells.forEach(sourceCell => {
                                const targetCell = targetObj.Cells.find(x => x.Code == sourceCell.Code);
                                if (targetCell) {
                                    if (sourceObj.Type === 'MultiSelect') {
                                        if (sourceCell.Selected)
                                            targetCell.Selected = true;
                                    }
                                    else if (!this.isNullOrEmpty(sourceCell.Answer))
                                        targetCell.Answer = sourceCell.Answer;
                                }
                            });
                        }
                    }
                }
            }
        }

        if (questSource.Attachment)
            questTarget.Attachment = questSource.Attachment;
    }

    ProductAnswer(questOrCodeSource, questOrCodeTarget) {
        const questRef = this.getObjectByCode(questOrCodeSource);
        const increment = !isNaN(questRef.Answer) && (questRef.Answer) && (questRef.Answer != "") ? parseInt(questRef.Answer) : 0;

        if (increment) {
            questOrCodeSource = "Q1x" + increment;
            const questSource = this.getObjectByCode(questOrCodeSource);
            const questTarget = this.getObjectByCode(questOrCodeTarget);

            if (!this.isNullOrEmpty(questSource.Answer)) {
                const targetChoice = questTarget.CMap[questSource.Answer];
                if (targetChoice) {
                    questTarget.Answer = questSource.Answer;
                }
            }
        }
    }

    AIC(questOrCodesSource, questOrCodesTarget, excludeChoices = []) //This is not for Grid
    {
        this.showHideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices, 'show');
    }

    ANC(questOrCodesSource, questOrCodesTarget, excludeChoices = []) //This is not for Grid
    {
        this.showHideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices, 'hide');
    }

    AIE(questOrCodesSource, questOrCodesTarget, value = 0, excludeChoices = []) //This is not for Grid
    {
        this.AddIfAnswered(questOrCodesSource, questOrCodesTarget, value, excludeChoices, "=");
    }

    ANE(questOrCodesSource, questOrCodesTarget, value = 0, excludeChoices = []) //This is not for Grid
    {
        this.AddIfAnswered(questOrCodesSource, questOrCodesTarget, value, excludeChoices, "!=");
    }

    AIG(questOrCodesSource, questOrCodesTarget, value = 0, excludeChoices = []) //This is not for Grid
    {
        this.AddIfAnswered(questOrCodesSource, questOrCodesTarget, value, excludeChoices, ">");
    }

    AIL(questOrCodesSource, questOrCodesTarget, value = 0, excludeChoices = []) //This is not for Grid
    {
        this.AddIfAnswered(questOrCodesSource, questOrCodesTarget, value, excludeChoices, "<");
    }

    AddIfAnswered(questOrCodesSource, questOrCodesTarget, value = 0, excludeChoices = [], operator = "") //This is not for Grid
    {
        const show = true;
        questOrCodesSource = GenericService.toArray(questOrCodesSource);
        questOrCodesTarget = GenericService.toArray(questOrCodesTarget);

        excludeChoices = GenericService.toNumberArray(excludeChoices);

        let choices = [];
        questOrCodesSource.forEach(x => {
            const qs = this.getObjectByCode(x);
            let list = this.getSelectedChoices(x, excludeChoices);
            if (list) {
                if (!Array.isArray(list)) //In case of single select
                    list = [list];

                list.forEach(x => x.Question = { Code: qs.Code, Type: qs.Type, Other: qs.Other, OtherAnswer: qs.OtherAnswer });
                //choices = choices.concat(list);
                switch (operator) {
                    case ("!="): {
                        choices = choices.concat(list.filter(z => z.Answer != value));
                    }
                        break;
                    case ("="): {
                        choices = choices.concat(list.filter(z => z.Answer == value));
                    }
                        break;
                    case (">"): {
                        choices = choices.concat(list.filter(z => z.Answer > value));
                    }
                        break;
                    case ("<"): {
                        choices = choices.concat(list.filter(z => z.Answer < value));
                    }
                        break;
                    default: {
                        choices = choices.concat(list.filter(z => z.Answer == value));
                    }
                        break;

                }
            }
        });

        questOrCodesTarget.forEach(x => {
            const qt = this.getObjectByCode(x);

            qt.Choices.forEach(c => {
                if (c.Exclusive)
                    return;
                const chSource = choices.find(y => y.Code === c.Code);
                if (chSource) {
                    c.Hidden = !show;
                    if (show) {
                        if (chSource.Other && chSource.Question.Type === 'SingleSelect' && !this.isNullOrEmpty(chSource.Question.OtherAnswer)) {
                            c.Text = chSource.Question.OtherAnswer;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }

                        else if (chSource.Other && chSource.Question.Type === 'MultiSelect' && !this.isNullOrEmpty(chSource.OtherAnswer)) {
                            c.Text = chSource.OtherAnswer;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }

                        else if (c.Other && this.isSelectedAny(chSource.Question.Code, chSource.Code)) //A==>B==>C, A's OtherAnswer becomes B's Text. So C'Text will be B's Text
                        {
                            c.Text = chSource.Text;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }
                    }
                }
                else {
                    if (qt.Type === 'SingleSelect' && qt.Answer == c.Code)
                        qt.Answer = null;
                    else if (qt.Type === 'MultiSelect')
                        c.Selected = false;

                    if (c.Other)
                        c.OtherAnswer = null;
                    c.Hidden = show;
                }
            });
        });
    }


    showSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices = []) //This is not for Grid
    {
        this.showHideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices, 'show');
    }

    hideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices = []) //This is not for Grid
    {
        this.showHideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices, 'hide');
    }

    showHideSelectedChoices(questOrCodesSource, questOrCodesTarget, excludeChoices = [], showHide = 'show') //Only for Single & Multi. Not for Grid currently
    {
        const show = showHide === 'show' ? true : false;
        questOrCodesSource = GenericService.toArray(questOrCodesSource);
        questOrCodesTarget = GenericService.toArray(questOrCodesTarget);

        excludeChoices = GenericService.toNumberArray(excludeChoices);

        let choices = [];
        questOrCodesSource.forEach(x => {
            const qs = this.getObjectByCode(x);
            let list = this.getSelectedChoices(x, excludeChoices);
            if (list) {
                if (!Array.isArray(list)) //In case of single select
                    list = [list];

                list.forEach(x => x.Question = { Code: qs.Code, Type: qs.Type, Other: qs.Other, OtherAnswer: qs.OtherAnswer });

                choices = choices.concat(list);
            }
        });

        questOrCodesTarget.forEach(x => {
            const qt = this.getObjectByCode(x);

            qt.Choices.forEach(c => {
                if (c.Exclusive)
                    return;
                const chSource = choices.find(y => y.Code === c.Code);
                if (chSource) {
                    c.Hidden = !show;
                    if (show) {
                        if (chSource.Other && chSource.Question.Type === 'SingleSelect' && !this.isNullOrEmpty(chSource.Question.OtherAnswer)) {
                            c.Text = chSource.Question.OtherAnswer;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }

                        else if (chSource.Other && chSource.Question.Type === 'MultiSelect' && !this.isNullOrEmpty(chSource.OtherAnswer)) {
                            c.Text = chSource.OtherAnswer;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }

                        else if (c.Other && this.isSelectedAny(chSource.Question.Code, chSource.Code)) //A==>B==>C, A's OtherAnswer becomes B's Text. So C'Text will be B's Text
                        {
                            c.Text = chSource.Text;
                            c.Other = false;
                            c.OtherAnswer = null;
                        }
                    }
                }
                else {
                    if (qt.Type === 'SingleSelect' && qt.Answer == c.Code)
                        qt.Answer = null;
                    else if (qt.Type === 'MultiSelect')
                        c.Selected = false;

                    if (c.Other)
                        c.OtherAnswer = null;
                    c.Hidden = show;
                }
            });
        });
    }

    randomizeQuestions(identifiers, randomizeOn = 'Group') //Group/Section
    {
        let questions = this.cs.questions.filter(x => identifiers.indexOf(x[randomizeOn]) > -1);
        const min = this.gns.getMinOfArray(questions, 'PageIndex');

        let pageIndex = min;
        while (identifiers.length > 0) {
            const i = this.gns.getRandomNumber(0, identifiers.length);
            questions = this.cs.questions.filter(x => x[randomizeOn] === identifiers[i]);
            questions.forEach(quest => {
                quest.PageIndex = pageIndex;
                //console.debug("Q:" + quest.Code + ", PageIndex:" + pageIndex);
                if (quest.PageBreak !== false)
                    pageIndex++;
            });

            identifiers.splice(i, 1);
        }
        this.createPages();
    }

    rotateQuestions(identifiers, firstIndex: number, rotationOn = 'Group') //Group/Section
    {
        //this.ls.logDebug("calling randomRotateQuestions");
        let questions = this.cs.questions.filter(x => identifiers.indexOf(x[rotationOn]) > -1);
        const min = this.gns.getMinOfArray(questions, 'PageIndex');

        let pageIndex = min;

        for (let i = firstIndex; i < identifiers.length; i++) {
            questions = this.cs.questions.filter(x => x[rotationOn] === identifiers[i]);
            questions.forEach(quest => {
                quest.PageIndex = pageIndex;
                // console.debug("Q:" + quest.Code + ", PageIndex:" + pageIndex);
                if (quest.PageBreak !== false)
                    pageIndex++;
            });
        }
        for (let i = 0; i < firstIndex; i++) {
            questions = this.cs.questions.filter(x => x[rotationOn] === identifiers[i]);
            questions.forEach(quest => {
                quest.PageIndex = pageIndex;
                // this.ls.logDebug("Q:" + quest.Code + ", PageIndex:" + pageIndex);
                if (quest.PageBreak !== false)
                    pageIndex++;
            });
        }

        this.createPages();
    }

    randomRotateQuestions(identifiers, rotationOn = 'Group') //Group/Section
    {
        //pick 1st randomly and then rotate remaining
        const firstIndex = this.gns.getRandomNumber(0, identifiers.length);
        this.rotateQuestions(identifiers, firstIndex, rotationOn);
        return firstIndex;
    }

    randomRotateQuestionsOnSamePage(pageIndex) {
        const questions = this.cs.pages[pageIndex].Questions;
        if (questions.length > 0) {
            const arr = [];
            if (questions[0].Type == 'Instruction') {
                arr.push(questions[0]);
                questions.shift();
            }

            var first = this.gns.getRandomNumber(0, questions.length);
            while (questions.length > 1 && first == 0) {
                first = this.gns.getRandomNumber(0, questions.length);
            }

            for (let i = first; i < questions.length; i++) {
                arr.push(questions[i]);
            }

            for (let i = 0; i < first; i++) {
                arr.push(questions[i]);
            }

            this.cs.pages[pageIndex].Questions = arr;

            return first;
        }
        return -1;
    }

    randomizeChoices(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1, sortOrderStart = 1) {
        if (!AppConfig.isApp || this.cs.runOnline) {
            return this.randomizeChoicesOnline(questOrCodes, choiceProp, startIndex, endIndex, sortOrderStart)
        }
        else {
            return this.randomizeChoicesOffline(questOrCodes, choiceProp, startIndex, endIndex, sortOrderStart)
        }
    }

    async randomizeChoicesOnline(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1, sortOrderStart = 1): Promise<any> {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);
            // const check = this.getObjectByCode(x);

            const start = startIndex < 0 ? 0 : startIndex;
            const end = endIndex < 0 ? quest[choiceProp].length - 1 : endIndex;
            // const choicesorder = quest.Choices.map((x, i) => ({ ...x, "Order": i }));

            // const choicesorder = quest.Choices ? quest.Choices.map((x, i) => ({ ...x, "Order": i })) : quest.Rows.map((x, i) => ({ ...x, "Order": i }));

            const choicesorder = quest.Choices ? quest.Choices.map((x, i) => ({ ...x, "Order": i })) : quest[choiceProp].map((x, i) => ({ ...x, "Order": i }));

            const headers = choicesorder.filter(x => x.IsHeader);
            const headersChoices = choicesorder.filter(x => x.Group);
            const onlyexcl = choicesorder.filter(x => x.Exclusive);
            const onlyoth = choicesorder.filter(x => x.Other);
            const withoutgrp = this.shuffleArray(choicesorder.filter(x => !x.IsHeader && !x.Exclusive && !x.Other && !x.Group));
            const fChoices = [];
            headers.forEach(x => {
                var head = headersChoices.filter(y => y.Group == x.Code);
                var norders = this.shuffleArray(head.filter(i => !i.fix).map(a => a.Order));
                head.filter(f => !f.fix).forEach((x, i) => { x.Order = norders[i]; });
                const arrangedOrder = head.sort((a, b) => a.Order - b.Order);
                x.choices = arrangedOrder;
            });
            const combined = (headers.concat(withoutgrp));
            combined.forEach((item, i) => { item.Order = i; });
            var neworders = this.shuffleArray(combined.filter(i => !i.fix).map(a => a.Order));
            combined.filter(f => !f.fix).forEach((x, i) => { x.Order = neworders[i]; });
            combined.sort((a, b) => a.Order - b.Order);
            combined.forEach(element => {
                if (element.choices) {
                    fChoices.push(element);
                    fChoices.push(element.choices);
                }
                else {
                    fChoices.unshift(element);
                }
            });
            fChoices.push(onlyoth);
            fChoices.push(onlyexcl);
            var flatChoices = isArray(fChoices) ? fChoices.reduce((acc, val) => acc.concat(val), []) : [];
            //const flatChoices = fChoices.flatMap(y => y);
            //console.log(flatChoices);
            const exOthers = quest[choiceProp].filter(x => x.Other || x.Exclusive);
            //flatChoices = flatChoices.push(quest.Code);                // quest[choiceProp] = flatChoices;
            var finallistdata = {
                "surveyCode": CurrentSurvey.code,
                "questCode": quest.Code,
                "ordering": flatChoices.map(x => x.Code).toString(),
                "uniqueid": this.cs.respId
            }

            var finalChoices: any;
            quest[choiceProp] = flatChoices;


            if (choiceProp == 'Columns') {
                quest.Rows.forEach(x => {
                    var testcells = x.Cells;
                    x.Cells = [];
                    flatChoices.forEach(y => {
                        x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                    })
                })
            }
            if (choiceProp == 'Rows') {
                quest.Columns.forEach(x => {
                    var testcells = x.Cells;
                    x.Cells = [];
                    flatChoices.forEach(y => {
                        x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                    })
                })
            }

            if (!quest.OrderBy) {

                let url = 'response/savechoicesorder';
                url = GenericService.addToQueryString(url, "surveyCode", finallistdata.surveyCode);
                url = GenericService.addToQueryString(url, "questCode", finallistdata.questCode);
                url = GenericService.addToQueryString(url, "ordering", finallistdata.ordering);
                url = url + "&uniqueid=" + finallistdata.uniqueid;

                this.ajaxService.callPostService(url, finallistdata).toPromise()

            }
            else {
                var finallistdata1 = {
                    "surveyCode": CurrentSurvey.code,
                    "questCode": quest.OrderBy,
                    "uniqueid": this.cs.respId
                }

                let url = 'response/getchoicesorder';
                url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
                url = GenericService.addToQueryString(url, "questCode", finallistdata1.questCode);
                url = url + "&uniqueid=" + finallistdata1.uniqueid;
                var data;
                this.ajaxService.callGetService(url).toPromise().then(res => {
                    data = res.Data.ConListData;
                    // console.log(res);
                    url = 'response/savechoicesorder';
                    url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
                    url = GenericService.addToQueryString(url, "questCode", quest.Code);
                    url = GenericService.addToQueryString(url, "ordering", data);
                    url = url + "&uniqueid=" + finallistdata1.uniqueid;
                    if (data) {
                        var vdata = [];
                        var SplitData = data.split(',')
                        for (let index = 0; index < SplitData.length; index++) {
                            vdata.push(flatChoices.filter(x => x.Code == SplitData[index])[0])
                        }

                        quest[choiceProp] = vdata;
                        if (choiceProp == 'Columns') {
                            quest.Rows.forEach(x => {
                                var testcells = x.Cells;
                                x.Cells = [];
                                vdata.forEach(y => {
                                    x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                                })
                            })
                        }
                        if (choiceProp == 'Rows') {
                            quest.Columns.forEach(x => {
                                var testcells = x.Cells;
                                x.Cells = [];
                                vdata.forEach(y => {
                                    x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                                })
                            })
                        }
                    }
                    this.ajaxService.callPostService(url, finallistdata).toPromise()

                }
                )

                // url = 'response/savechoicesorder';
                // url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
                // url = GenericService.addToQueryString(url, "questCode", quest.Code);
                // url = GenericService.addToQueryString(url, "ordering", data);
                // url = url + "&uniqueid=" + finallistdata.uniqueid;

                // this.ajaxService.callPostService(url, finallistdata).toPromise()



                // var flatchoices1 = localStorage.getItem('list');
                // var temp = JSON.parse(flatchoices1).filter(x => x.QuestionCode == quest.OrderBy)[0].Options
                // quest[choiceProp] = temp;
                // console.log(quest[choiceProp]);
            }
            //console.log(this.reload);
            // var reload = JSON.stringify(flatChoices);                       });
        });
    }

    randomizeChoicesOffline(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1, sortOrderStart = 1) {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);
            // const check = this.getObjectByCode(x);

            const start = startIndex < 0 ? 0 : startIndex;
            const end = endIndex < 0 ? quest[choiceProp].length - 1 : endIndex;
            // const choicesorder = quest.Choices.map((x, i) => ({ ...x, "Order": i }));

            // const choicesorder = quest.Choices ? quest.Choices.map((x, i) => ({ ...x, "Order": i })) : quest.Rows.map((x, i) => ({ ...x, "Order": i }));

            const choicesorder = quest.Choices ? quest.Choices.map((x, i) => ({ ...x, "Order": i })) : quest[choiceProp].map((x, i) => ({ ...x, "Order": i }));

            const headers = choicesorder.filter(x => x.IsHeader);
            const headersChoices = choicesorder.filter(x => x.Group);
            const onlyexcl = choicesorder.filter(x => x.Exclusive);
            const onlyoth = choicesorder.filter(x => x.Other);
            const withoutgrp = this.shuffleArray(choicesorder.filter(x => !x.IsHeader && !x.Exclusive && !x.Other && !x.Group));
            const fChoices = [];
            headers.forEach(x => {
                var head = headersChoices.filter(y => y.Group == x.Code);
                var norders = this.shuffleArray(head.filter(i => !i.fix).map(a => a.Order));
                head.filter(f => !f.fix).forEach((x, i) => { x.Order = norders[i]; });
                const arrangedOrder = head.sort((a, b) => a.Order - b.Order);
                x.choices = arrangedOrder;
            });
            const combined = (headers.concat(withoutgrp));
            combined.forEach((item, i) => { item.Order = i; });
            var neworders = this.shuffleArray(combined.filter(i => !i.fix).map(a => a.Order));
            combined.filter(f => !f.fix).forEach((x, i) => { x.Order = neworders[i]; });
            combined.sort((a, b) => a.Order - b.Order);
            combined.forEach(element => {
                if (element.choices) {
                    fChoices.push(element);
                    fChoices.push(element.choices);
                }
                else {
                    fChoices.unshift(element);
                }
            });
            fChoices.push(onlyoth);
            fChoices.push(onlyexcl);
            var flatChoices = isArray(fChoices) ? fChoices.reduce((acc, val) => acc.concat(val), []) : [];
            //const flatChoices = fChoices.flatMap(y => y);
            // console.log(flatChoices);
            const exOthers = quest[choiceProp].filter(x => x.Other || x.Exclusive);
            //flatChoices = flatChoices.push(quest.Code);                // quest[choiceProp] = flatChoices;
            var finallistdata = {
                "surveyCode": CurrentSurvey.code,
                "questCode": quest.Code,
                "ordering": flatChoices.map(x => x.Code).toString(),
                "uniqueid": this.cs.respId
            }

            var finalChoices: any;
            quest[choiceProp] = flatChoices;


            if (choiceProp == 'Columns') {
                quest.Rows.forEach(x => {
                    var testcells = x.Cells;
                    x.Cells = [];
                    flatChoices.forEach(y => {
                        x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                    })
                })
            }
            if (choiceProp == 'Rows') {
                quest.Columns.forEach(x => {
                    var testcells = x.Cells;
                    x.Cells = [];
                    flatChoices.forEach(y => {
                        x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                    })
                })
            }
            if (!quest.OrderBy) { }
            else {
                var data = this.cs.qMap[quest.OrderBy][choiceProp].map(x => x.Code)
                if (data) {
                    var vdata = [];
                    var SplitData = data;
                    for (let index = 0; index < SplitData.length; index++) {
                        vdata.push(flatChoices.filter(x => x.Code == SplitData[index])[0])
                    }

                    quest[choiceProp] = vdata;
                    if (choiceProp == 'Columns') {
                        quest.Rows.forEach(x => {
                            var testcells = x.Cells;
                            x.Cells = [];
                            vdata.forEach(y => {
                                x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                            })
                        })
                    }
                    if (choiceProp == 'Rows') {
                        quest.Columns.forEach(x => {
                            var testcells = x.Cells;
                            x.Cells = [];
                            vdata.forEach(y => {
                                x.Cells.push(testcells.filter(z => z.Code == y.Code)[0])
                            })
                        })
                    }
                }
            }

            // if (!quest.OrderBy) {

            //     let url = 'response/savechoicesorder';
            //     url = GenericService.addToQueryString(url, "surveyCode", finallistdata.surveyCode);
            //     url = GenericService.addToQueryString(url, "questCode", finallistdata.questCode);
            //     url = GenericService.addToQueryString(url, "ordering", finallistdata.ordering);
            //     url = url + "&uniqueid=" + finallistdata.uniqueid;

            //     this.ajaxService.callPostService(url, finallistdata).toPromise()

            // }
            // else {
            //     var finallistdata1 = {
            //         "surveyCode": CurrentSurvey.code,
            //         "questCode": quest.OrderBy,
            //         "uniqueid": this.cs.respId
            //     }

            //     let url = 'response/getchoicesorder';
            //     url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
            //     url = GenericService.addToQueryString(url, "questCode", finallistdata1.questCode);
            //     url = url + "&uniqueid=" + finallistdata1.uniqueid;
            //     var data;
            //     this.ajaxService.callGetService(url).toPromise().then(res => {
            //         data = res.Data.ConListData;
            //         console.log(res);
            //         url = 'response/savechoicesorder';
            //         url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
            //         url = GenericService.addToQueryString(url, "questCode", quest.Code);
            //         url = GenericService.addToQueryString(url, "ordering", data);
            //         url = url + "&uniqueid=" + finallistdata1.uniqueid;
            //         if (data) {
            //             var vdata = [];
            //             var SplitData = data.split(',')
            //             for (let index = 0; index < SplitData.length; index++) {
            //                 vdata.push(flatChoices.filter(x => x.Code == SplitData[index])[0])
            //             }
            //             quest[choiceProp] = vdata;
            //         }
            //         this.ajaxService.callPostService(url, finallistdata).toPromise()

            //     }
            //     )

            //     // url = 'response/savechoicesorder';
            //     // url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
            //     // url = GenericService.addToQueryString(url, "questCode", quest.Code);
            //     // url = GenericService.addToQueryString(url, "ordering", data);
            //     // url = url + "&uniqueid=" + finallistdata.uniqueid;

            //     // this.ajaxService.callPostService(url, finallistdata).toPromise()



            //     // var flatchoices1 = localStorage.getItem('list');
            //     // var temp = JSON.parse(flatchoices1).filter(x => x.QuestionCode == quest.OrderBy)[0].Options
            //     // quest[choiceProp] = temp;
            //     // console.log(quest[choiceProp]);
            // }
            //console.log(this.reload);
            // var reload = JSON.stringify(flatChoices);                       });
        });
    }

    randomizeChoicesByExcavate(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1, sortOrderStart = 1) {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);
            const start = startIndex < 0 ? 0 : startIndex;
            const end = endIndex < 0 ? quest[choiceProp].length - 1 : endIndex;

            const choices = [];
            for (let i = start; i <= end; i++) {
                if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                    choices.push(quest[choiceProp][i]);
            }

            const choicesNew = [];
            while (choices.length > 0) {
                const i = this.gns.getRandomNumber(0, choices.length);
                choices[i].SortOrder = sortOrderStart++;
                choicesNew.push(choices[i]);
                choices.splice(i, 1);
            }

            if (start && start > 0) {
                for (let i = 0; i < start; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.unshift(quest[choiceProp][i]);
                }
            }

            if (end && end < quest[choiceProp].length - 1) {
                for (let i = end + 1; i < quest[choiceProp].length; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.push(quest[choiceProp][i]);
                }
            }

            const exOthers = quest[choiceProp].filter(x => x.Other || x.Exclusive);
            quest[choiceProp] = choicesNew.concat(exOthers);
        });
    }

    randomRotateChoices(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1) {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);
            const start = startIndex < 0 ? 0 : startIndex;
            const end = endIndex < 0 ? quest[choiceProp].length - 1 : endIndex;

            const choices = [];
            for (let i = start; i <= end; i++) {
                if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                    choices.push(quest[choiceProp][i]);
            }

            const first = this.gns.getRandomNumber(0, choices.length);
            const choicesNew = [];

            for (let i = first; i < choices.length; i++) {
                choicesNew.push(choices[i]);
            }

            for (let i = 0; i < first; i++) {
                choicesNew.push(choices[i]);
            }

            if (start && start > 0) {
                for (let i = 0; i < start; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.unshift(quest[choiceProp][i]);
                }
            }

            if (end && end < quest[choiceProp].length - 1) {
                for (let i = end + 1; i < quest[choiceProp].length; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.push(quest[choiceProp][i]);
                }
            }

            const exOthers = quest[choiceProp].filter(x => x.Other || x.Exclusive);
            quest[choiceProp] = choicesNew.concat(exOthers);
        });
    }

    randomRotateChoicesbyHeaders(questOrCodes, choiceProp = 'Choices', startIndex = -1, endIndex = -1) {
        questOrCodes = GenericService.toArray(questOrCodes);
        questOrCodes.forEach(x => {
            const quest = this.getObjectByCode(x);
            const start = startIndex < 0 ? 0 : startIndex;
            const end = endIndex < 0 ? quest[choiceProp].length - 1 : endIndex;

            const choices = [];
            for (let i = start; i <= end; i++) {
                if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                    choices.push(quest[choiceProp][i]);
            }
            const headers = choices.filter(x => x.IsHeader);
            const headersChoices = choices.filter(x => x.Group);
            const onlyexcl = choices.filter(x => x.Exclusive);
            const onlyoth = choices.filter(x => x.Other);
            const withoutgrp = this.shuffleArray(choices.filter(x => !x.IsHeader && !x.Exclusive && !x.Other && !x.Group));

            const hfirst = this.gns.getRandomNumber(0, headers.length);
            const hchoices = [];
            const choicenew1 = [];
            for (let i = hfirst; i < headers.length; i++) {
                hchoices.push(headers[i]);
                // for(let p=0;p<headersChoices.length;p++){
                // headers.forEach(x => {
                var head = headersChoices.filter(y => y.Group == headers[i].Code);
                // }
                const Cfirst = this.gns.getRandomNumber(0, head.length)
                for (let j = Cfirst; j < head.length; j++) {
                    hchoices.push(head[j]);
                }
                for (let z = 0; z < Cfirst; z++) {
                    hchoices.push(head[z]);
                }

            }
            for (let i = 0; i < hfirst; i++) {
                hchoices.push(headers[i]);
                // for(let p=0;p<headersChoices.length;p++){
                // headers.forEach(x => {
                var head = headersChoices.filter(y => y.Group == headers[i].Code);
                // }
                const Cfirst = this.gns.getRandomNumber(0, head.length)
                for (let j = Cfirst; j < head.length; j++) {
                    hchoices.push(head[j]);
                }
                for (let z = 0; z < Cfirst; z++) {
                    hchoices.push(head[z]);
                }

            }
            const first = this.gns.getRandomNumber(0, choices.length);
            const choicesNew = hchoices;

            // for (let i = first; i < choices.length; i++) {
            //     choicesNew.push(choices[i]);
            // }

            // for (let i = 0; i < first; i++) {
            //     choicesNew.push(choices[i]);
            // }

            if (start && start > 0) {
                for (let i = 0; i < start; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.unshift(quest[choiceProp][i]);
                }
            }

            if (end && end < quest[choiceProp].length - 1) {
                for (let i = end + 1; i < quest[choiceProp].length; i++) {
                    if (!quest[choiceProp][i].Other && !quest[choiceProp][i].Exclusive)
                        choicesNew.push(quest[choiceProp][i]);
                }
            }

            const exOthers = quest[choiceProp].filter(x => x.Other || x.Exclusive);
            quest[choiceProp] = choicesNew.concat(exOthers);
            var finallistdata = {
                "surveyCode": CurrentSurvey.code,
                "questCode": quest.Code,
                "ordering": choicesNew.map(x => x.Code).toString(),
                "uniqueid": this.cs.respId
            }
            // console.log(finallistdata);
            if (!quest.OrderBy) {

                let url = 'response/savechoicesorder';
                url = GenericService.addToQueryString(url, "surveyCode", finallistdata.surveyCode);
                url = GenericService.addToQueryString(url, "questCode", finallistdata.questCode);
                url = GenericService.addToQueryString(url, "ordering", finallistdata.ordering);
                url = url + "&uniqueid=" + finallistdata.uniqueid;

                this.ajaxService.callPostService(url, finallistdata).toPromise()

            }
            else {
                var finallistdata1 = {
                    "surveyCode": CurrentSurvey.code,
                    "questCode": quest.OrderBy,
                    "uniqueid": this.cs.respId
                }

                let url = 'response/getchoicesorder';
                url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
                url = GenericService.addToQueryString(url, "questCode", finallistdata1.questCode);
                url = url + "&uniqueid=" + finallistdata1.uniqueid;
                var data;
                this.ajaxService.callGetService(url).toPromise().then(res => {
                    data = res.Data.ConListData;
                    // console.log(res);
                    url = 'response/savechoicesorder';
                    url = GenericService.addToQueryString(url, "surveyCode", finallistdata1.surveyCode);
                    url = GenericService.addToQueryString(url, "questCode", quest.Code);
                    url = GenericService.addToQueryString(url, "ordering", data);
                    url = url + "&uniqueid=" + finallistdata1.uniqueid;
                    if (data) {
                        var vdata = [];
                        var SplitData = data.split(',')
                        for (let index = 0; index < SplitData.length; index++) {
                            vdata.push(choicesNew.filter(x => x.Code == SplitData[index])[0])
                        }

                        quest[choiceProp] = vdata;

                        this.ajaxService.callPostService(url, finallistdata).toPromise()

                    }
                });
            }


        });
    }

    sortChoices(choices, type = 'Code', order = 'asc') {
        if (type == "SortOrder") {
            this.sortChoicesBySortOrder(choices, order);
        }
        else if (type == "Text") {
            this.sortChoicesByText(choices, order);
        }
        else {
            this.sortChoicesByCode(choices, order);
        }
    }

    sortChoicesByCode(choices, order = 'asc') {
        if (order == 'desc') {
            choices = choices.sort((choice1, choice2) => {
                const n1 = !isNaN(choice1.Code) ? Number(choice1.Code) : 0;
                const n2 = !isNaN(choice2.Code) ? Number(choice2.Code) : 0;
                return n2 - n1;
            });
        } else {
            choices = choices.sort((choice1, choice2) => {
                const n1 = !isNaN(choice1.Code) ? Number(choice1.Code) : 0;
                const n2 = !isNaN(choice2.Code) ? Number(choice2.Code) : 0;
                return n1 - n2;
            });
        }

        return choices;
    }

    sortChoicesBySortOrder(choices, order = 'asc') {
        if (order == 'desc') {
            choices = choices.sort((choice1, choice2) => {
                const n1 = !isNaN(choice1.SortOrder) ? Number(choice1.SortOrder) : 0;
                const n2 = !isNaN(choice2.SortOrder) ? Number(choice2.Code) : 0;
                return n2 - n1;
            });
        } else {
            choices = choices.sort((choice1, choice2) => {
                const n1 = !isNaN(choice1.SortOrder) ? Number(choice1.SortOrder) : 0;
                const n2 = !isNaN(choice2.SortOrder) ? Number(choice2.SortOrder) : 0;
                return n1 - n2;
            });
        }

        return choices;
    }

    sortChoicesByText(choices, order = 'asc') {
        if (order == 'desc') {
            choices = choices.sort((choice1, choice2) => {
                var textA = choice1.Text.toUpperCase(); // Convert to uppercase for case-insensitive comparison
                var textB = choice2.Text.toUpperCase();
                if (textA > textB) {
                    return -1;
                }
                if (textA < textB) {
                    return 1;
                }
                // Texts are equal
                return 0;
            });
        } else {
            choices = choices.sort((choice1, choice2) => {
                var textA = choice1.Text.toUpperCase(); // Convert to uppercase for case-insensitive comparison
                var textB = choice2.Text.toUpperCase();
                if (textA < textB) {
                    return -1;
                }
                if (textA > textB) {
                    return 1;
                }
                // Texts are equal
                return 0;
            });
        }

        return choices;
    }

    // reverseChoices(choices) {
    //     return choices.slice().reverse();
    //         }

    reverseChoices1(questOrCodes) {
        questOrCodes = (questOrCodes.slice().reverse());
        const start = 0;
        const end = questOrCodes.length - 1;

        const choices = [];
        for (let i = start; i <= end; i++) {
            if (!questOrCodes[i].Other && !questOrCodes[i].Exclusive)
                choices.push(questOrCodes[i]);
        }
        const headers = choices.filter(x => x.IsHeader);
        const headersChoices = choices.filter(x => x.Group);
        const hfirst = headers.length;
        const hchoices = [];

        for (let i = 0; i < hfirst; i++) {
            hchoices.push(headers[i]);
            var head = headersChoices.filter(y => y.Group == headers[i].Code);
            const Cfirst = this.gns.getRandomNumber(0, head.length)
            for (let j = Cfirst; j < head.length; j++) {
                hchoices.push(head[j]);
            }
            for (let z = 0; z < Cfirst; z++) {
                hchoices.push(head[z]);
            }

        }
        const choicesNew = hchoices;

        if (start && start > 0) {
            for (let i = 0; i < start; i++) {
                if (!questOrCodes[i].Other && !questOrCodes[i].Exclusive)
                    choicesNew.unshift(questOrCodes[i]);
            }
        }

        if (end && end < questOrCodes.length - 1) {
            for (let i = end + 1; i < questOrCodes.length; i++) {
                if (!questOrCodes[i].Other && !questOrCodes[i].Exclusive)
                    choicesNew.push(questOrCodes[i]);
            }
        }

        const exOthers = questOrCodes.filter(x => x.Other || x.Exclusive);
        questOrCodes = choicesNew.concat(exOthers);

        //return questOrCodes;
    }

    changeStatusBarColor(color) {
        if (AppConfig.isApp)
            this.statusBar.backgroundColorByHexString(color);
    }

    callGetApi(apiUrl): Observable<any> {
        return this.ajaxService.callGetApi(apiUrl);
    }

    callPostApi(apiUrl, data): Observable<any> {
        return this.ajaxService.callPostApi(apiUrl, data);
    }

    getRandomOtp() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    sendOtp(mobileNo, otp, message = null, api = null): Observable<any> {
        return this.ajaxService.callGetService('otp/send?surveyCode=' + this.cs.code + '&mobileNo=' + mobileNo + '&otp=' + otp + '&message=' + message + '&api=' + api);
    }

    validateOtp(mobileNo, otp): Observable<any> {
        return this.ajaxService.callGetService('otp/validate?surveyCode=' + this.cs.code + '&mobileNo=' + mobileNo + '&otp=' + otp);
    }
}
