import { Injectable } from '@angular/core';

declare const globalConfig: any;
declare const themes: any;

@Injectable()
export class AppConfig
{
    static isAppLoaded;

    static get baseUrl()
    {
        return globalConfig.baseUrl;
    }

    static get filesUrl()
    {
        return globalConfig.filesUrl || globalConfig.baseUrl + "/Files/";
    }

    static get skipLogin()
    {
        if (AppConfig.isApp)
            return false;
        else
            return globalConfig.skipLogin;
    }

    static get autoSync()
    {
        return true;
    }

    static get isApp() //set from app.component because it is static and platform object is required here
    {
        if (globalConfig.isApp) //we will keep isApp true in custom.js in case of App
            return true;
        else
            return !document.URL.startsWith('http');
    }

    static get debugMode()
    {
        return globalConfig.debugMode;
    }

    static get appVersion() // Only for browser platform. For other we will fetch it from AppVserion Plugin
    {
        return globalConfig.appVersion;
    }

    static clientId = '099153c2625149bc8ecb3e85e03f0022';
    static dateFormat = 'dd/MM/yyyy';
    static googleAnalyticsId = 'UA-121839951-1';
    static codePushKeyStaging = 'x6ij99_eEsmbPxdLB0gdX-urtMTEa8053550-a86a-4c8d-bdb3-5095d476e9f8';
    static codePushKeyProduction = 'IUdd5zperkSrH-PytJKRX1Z8DXj7a8053550-a86a-4c8d-bdb3-5095d476e9f8';

    static get themes()
    {
        return themes;
    }
}
