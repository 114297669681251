import { Injectable } from "@angular/core";

@Injectable()
export class Styler
{
    static contentSelector = 'page-survey ion-content';

    static makeHeaderTransparent()
    {
        const root = document.querySelector(this.contentSelector).shadowRoot;
        if (root)
        {
            const bg: any = root.querySelector('.inner-scroll');
            if (bg)
                bg.style.padding = 0;
        }
    }

    static setPageBackground(url, propertyString = "no-repeat content-box center/cover")
    {
        const ele: any = document.querySelector(this.contentSelector);
        ele.style.setProperty("--background", "url('" + url + "') " + propertyString);
    }

    static removePageBackground()
    {
        const ele: any = document.querySelector(this.contentSelector);
        ele.style.setProperty("--background", "none");
    }

    static getClassArray(classNames:string|string[])
    {
        if (typeof classNames === 'string')
            classNames = classNames.split(' ').filter(x => x && x.trim()).map(x => x.trim());
        return classNames;
    }

    static addPageClasses(classNames: string | string[], removePreviousClasses = true)
    {
        classNames = this.getClassArray(classNames);
        const ele = document.querySelector(this.contentSelector);

        if (ele)
        {
            if (removePreviousClasses)
            {
                const existingClasses = this.getClassArray(ele.className).filter(x => x !== 'hydrated' && x !== 'md');
                ele.classList.remove(...existingClasses);
            }
            ele.classList.add(...classNames);
        }
    }

    static addClasses(selector: string, classNames: string | string[])
    {
        classNames = this.getClassArray(classNames);
        const ele = document.querySelector(selector);
        if(ele)
            ele.classList.add(...classNames);
    }

    static removeClasses(selector: string, classNames: string | string[])
    {
        classNames = this.getClassArray(classNames);
        const ele = document.querySelector(selector);
        if(ele)
            ele.classList.remove(...classNames);
    }

    static scrollTo(selector: string)
    {
        const ele = document.querySelector(selector);
        if (ele && ele.scrollIntoView)
        {
            setTimeout(() =>
            {
                ele.scrollIntoView({ 'behavior': 'smooth', 'block': 'start' });
                this.removeClasses(selector, 'animation-paused');
            }, 500); //This timeout is added because it takes some time to render all elements.
        }
    }
}
