import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TimeoutError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CONNECTION_ERROR } from './network.service';

@Injectable()
export class GenericService
{
    static serializeDate(date:Date, format=null)
    {
        //To check if takes care of time zone?
        format = format || 'M/D/YYYY h:m:s a';
        return moment(date).format(format);
    }

    static nullToEmpty(value): string
    {
        value = value ? value : '';
        return value;
    }

    static isObject(value)
    {
        return value && typeof value === 'object' && value.constructor === Object;
    }

    static isEmpty(value: any): boolean
    {
        // we don't check for string here so it also works with arrays
        return value === null || value.length === 0;
    }

    static datePickerOptions =
    {
        singleDatePicker: true,
        showDropdowns: true,
        opens: "left"
    }

    static addToDate(date: Date, n: number, unit = 'sec')
    {
        if (unit === 'sec')
            date.setSeconds(date.getSeconds() + n);
        else if (unit === 'min')
            date.setMinutes(date.getMinutes() + n);
        else if (unit === 'hour')
            date.setHours(date.getHours() + n);
        else if (unit === 'day')
            date.setDate(date.getDate() + n);
        else if (unit === 'month')
            date.setMonth(date.getMonth() + n);
        else if (unit === 'year')
            date.setFullYear(date.getFullYear() + n);
    }

    static getTimeDiff(date2: Date, date1: Date, unit = 'sec')
    {
        const ms = date2.getTime() - date1.getTime();
        let div = 1;

        if (unit === 'sec')
            div = 1000;
        else if (unit === 'min')
            div = 60000;
        else if (unit === 'hour')
            div = 60000 * 60;
        else if (unit === 'day')
            div = 60000 * 60 * 24;

        return ms * 1.0 / div;  //1.0 is to make it float
    }

    static getPropValue(obj, propPath)
    {
        const arr = propPath.split('.');
        while (arr.length && (obj = obj[arr.shift()]));
        return obj;
    }

    static getValueByPropIndex(obj, propIndex)
    {
        const key = Object.keys(obj)[propIndex];
        return obj[key];
    }

    static setPropValue(object, path, value)
    {
        const a = path.split('.');
        let o = object;
        for (let i = 0; i < a.length - 1; i++)
        {
            const n = a[i];
            if (n in o)
            {
                o = o[n];
            } else
            {
                o[n] = {};
                o = o[n];
            }
        }
        o[a[a.length - 1]] = value;
    }

    static compareById(obj1: any, obj2: any): boolean
    {
        return obj1 && obj2 ? obj1.Id === obj2.Id : obj1 === obj2;
    }

    static compareByCode(obj1: any, obj2: any): boolean
    {
        return obj1 && obj2 ? obj1.Code === obj2.Code : obj1 === obj2;
    }

    static getQueryStringMap()
    {
        const query = window.location.search.substring(1);
        if (!query)
            return {};

        const params = (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) =>
            {
                const [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {});

        return params;
    }

    static getQueryStringValue(key: any)
    {
        const params = this.getQueryStringMap() || {};
        return params[key];
    }


    static trackByFn(index)
    {
        return index;
    }

    static addToQueryString(url, key, value)
    {
        if (value)
        {
            let qs = "";
            if (url.indexOf('?') === -1)
                qs += "?";
            else
                qs += "&";
            qs += key + "=" + value;
            return url + qs;
        }
        return url;
    }

    static addToFilterString(filter, key, value)
    {
        if (value)
        {
            filter = filter ? filter + ';' : '';
            filter += key + ":" + value;
            return filter;
        }
        return filter;
    }

    static clone(obj)
    {
        try
        {
            if (!obj)
                return obj;
            return JSON.parse(JSON.stringify(obj));
        }
        catch(e)
        {
            console.log(e);
            console.log(obj);
            throw e;
        }
    }

    static getRandomNumber(min, max) //less than max, greater than or equal to min
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    static toCsv(list, propName = null, seperator = ",")
    {
        if (!list)
            return "";
        let str = "";
        for (const item of list)
        {
            str += seperator + " " + (propName ? item[propName] : item);
        }
        if (str.length > 0)
            return str.substring(2, str.length);

        return str;
    }

    static csvToJson(csv)
    {
        const lines = csv.split("\n");

        const result = [];

        const headers = lines[0].split(",");

        for (let i = 1; i < lines.length; i++)
        {
            const obj = {};
            const currentline = lines[i].split(",");

            for (let j = 0; j < headers.length; j++)
            {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        return result;
    }

    static round(value, upTo = 2)
    {
        let mul = 1;
        for (let i = 0; i < upTo; i++)
            mul *= 10;
        return (Math.round(value * mul) / mul);
    }

    static getRandomColorHex()
    {
        return '#' + Math.random().toString(16).slice(-6);
    }

    static jsonToCsv(data)
    {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        const arrData = typeof data !== 'object' ? JSON.parse(data) : data;

        let csv = '';
        //Set Report title in first row or line

        //This condition will generate the Label/Header
        let row = "";
        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0])
        {

            //Now convert each value to string and comma-seprated
            row += index + ',';
        }

        row = row.slice(0, -1);

        //append Label row with line break
        csv += row + '\r\n';

        //1st loop is to extract each row
        for (let i = 0; i < arrData.length; i++)
        {
            row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (const index in arrData[i])
            {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            csv += row + '\r\n';
        }

        if (csv === '')
        {
            alert("Invalid data");
            return;
        }
        return csv;
    }

    static getMaxOfArray(array, propName = null)
    {
        if (array.length === 0)
            return 0;
        if (propName)
            array = array.map(x => x[propName]);

        return Math.max.apply(null, array);
    }

    static getMinOfArray(array, propName)
    {
        if (array.length === 0)
            return 0;

        if (propName)
            array = array.map(x => x[propName]);

        return Math.min.apply(null, array);
    }

    static createGuid()  
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c)
        {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static createUId()  //To make Uid more Unique we added date time
    {
        let uid = this.createGuid();
        uid += '-' + (new Date().getTime()).toString(36);

        return uid;
    }

    static fetchErrorMessage(error)
    {
        if (error === null)
            return "";

        if (error instanceof HttpErrorResponse && error.status === 0)
            return CONNECTION_ERROR;

        if (error.error)
            error = this.fetchErrorMessage(error.error);

        if (error instanceof ProgressEvent || error instanceof TimeoutError || error.status === 0)
            return CONNECTION_ERROR;

        if (typeof error === 'object')
        {
            try
            {
                error = error.json();
            }
            catch (err)
            {
            }

            if (error.message)
                return error.message;

            error = error.error_description || error.Message || error._body  || error.body || error;

            return JSON.stringify(error);
        }
        return error;
    }

    static isNullOrEmpty(value)
    {
        if (value === undefined || value === null)
            return true;
        else
            return value.toString().trim() === "";
    }

    static isNullOrEmptyOrZero(value)
    {
        return GenericService.isNullOrEmpty(value) || value === 0;
    }

    static toArray(csvOrArr, isNumberArray = false): any[]
    {
        if (GenericService.isNullOrEmpty(csvOrArr))
            return [];
        if (Array.isArray(csvOrArr))
        {
            if (isNumberArray)
                csvOrArr = csvOrArr.map(x => Number(x));
            return csvOrArr;
        }
        if (typeof csvOrArr === 'object' || typeof csvOrArr === 'number')
            return [csvOrArr];

        let arr = csvOrArr.toString().split(',');
        if (isNumberArray)
            arr = arr.map(x => Number(x));

        return arr;
    }

    static toNumberArray(arr:any[])
    {
        return arr.map(x => Number(x));
    }

    static arrayToMap(array, keyProp = 'name')
    {
        const obj: any = {};
        if (!array)
            return obj;
        array.forEach(x =>
        {
            obj[x[keyProp]] = x;
        });

        return obj;
    }

    static distinct(list, prop = 'Code')
    {
        return list.filter((obj, pos, arr) =>
        {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    static formatImageUrl(url)
    {
        if (url)
        {
            if (url.startsWith('http:') || url.startsWith('assets') || url.startsWith('data:image'))
                return url;
            else
                return "assets/images/" + url;
        }
    }

    static to(promise:Promise<any>)
    {
        return promise.then(data =>
        {
            return [null, data];
        })
        .catch(err => [err]);
    }

    static escapeRegExp(string)
    {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static replaceAll(text, searchText, replaceWith)
    {
        replaceWith = replaceWith || '';
        if (text && searchText)
            return text.replace(new RegExp(GenericService.escapeRegExp(searchText), "g"), replaceWith);

        else
            return text;
    }

    static isValidUrl(str: string): boolean
    {
        const result: boolean = str === undefined ? false : ((str.indexOf("http://") > -1 || str.indexOf("https://") > -1) && str.indexOf("data:image/") < 0);
        return result;
    }

    static stripHtml(html)
    {
        //It may be unsafe. For now keeping it as it is
        if (!html) return "";
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}
