import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService  } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
import { AppConfig } from './app.config';

@Injectable()
export class AjaxService
{
    jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(private http: HttpClient)
    {

    }

    callService1(serviceUrl: string, skipAuth = false, method = 'Get', data = null): Observable<any>
    {
        let headers;

        //As per documentation of angular-jwt, access_token should have been appended automatically. But not working that why we added it manually
        //Will check it later on. And also need to see how refresh_tokens will work
        if (!skipAuth && !environment["skipAuth"])
        {
            headers = new HttpHeaders(
                {
                    //'Content-Type': 'application/json',
                });

            if (GlobalService.user)
                headers = headers.set("Authorization", "Bearer " + GlobalService.user.access_token);
            headers = { headers: headers };
        }
        const url = AppConfig.baseUrl + serviceUrl;
        if (method === 'Get')
        {
            return this.http.get(url, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
        else if (method === 'Delete')
        {
            return this.http.delete(url, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
        else if (method === 'Put')
        {
            return this.http.put(url, data, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
        else
        {
            return this.http.post(url, data, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
    }

    callService(serviceUrl: string, skipAuth = false, method = 'Get', data = null): Observable<any>
    {
        return this.callService1(serviceUrl, skipAuth, method, data);
    }

    callGetService(serviceUrl: string, skipAuth = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Get');
    }

    callPostService(serviceUrl: string, data: any, skipAuth = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    callDeleteService(serviceUrl: string): Observable<any>
    {
        return this.callService(serviceUrl, false, 'Delete');
    }

    getList(serviceName: string = null, pageNo: number = null, pageSize: number = null, orderBy: string = null, filters: string = null, excel = false): Observable<any>
    {
        pageNo = pageNo || 1;
        pageSize = pageSize || 50;
        filters = encodeURIComponent(GenericService.nullToEmpty(filters));
        let url = serviceName;
        if (url.indexOf('/') == -1)
            url += '/list';

        url = GenericService.addToQueryString(url, "pageNo", pageNo);
        url = GenericService.addToQueryString(url, "pageSize", pageSize);
        url = GenericService.addToQueryString(url, "orderBy", orderBy);
        url = GenericService.addToQueryString(url, "filters", filters);
        url = GenericService.addToQueryString(url, "excel", excel);

        return this.callGetService(url);
    }

    getDetails(serviceName: string, id: any): Observable<any>
    {
        return this.callGetService(serviceName + '/details?id=' + id);
    }

    fetchData(serviceName: string, filter: string = null): Observable<any>
    {
        filter = encodeURIComponent(GenericService.nullToEmpty(filter));
        return this.callGetService(serviceName + "?filter=" + filter);
    }

    postData(serviceUrl: string, data: any, skipAuth = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    putData(serviceUrl: string, data: any, skipAuth = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Put', data);
    }

    save(serviceUrl: string, data: any): Observable<any>
    {
        return this.postData(serviceUrl + '/save', data);
    }

    callGetApi(apiUrl):Observable<any>
    {
        return this.http.get(apiUrl).pipe(
            map(response =>
            {
                return response;
            }), catchError(this.handleError)
        );
    }

    callPostApi(apiUrl, data): Observable<any>
    {
        return this.http.post(apiUrl, data).pipe(
            map(response =>
            {
                return response;
            }), catchError(this.handleError)
        );
    }

    public handleError(error: any)
    {
        const errMsg = GenericService.fetchErrorMessage(error);
        console.log(errMsg);
        return throwError(errMsg);
    }

    public fetchErrorMessage(error: any)
    {
        return GenericService.fetchErrorMessage(error);
    }
}
