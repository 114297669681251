import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { SqliteService } from './sqlite.service';
import { CurrentSurvey } from './current-survey';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomService
{
  cs = CurrentSurvey;
  constructor(private sqliteService: SqliteService)
  {

  }
}
