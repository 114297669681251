import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppConfig } from './providers/app.config';
import { CurrentSurvey } from './providers/current-survey';
import { GlobalService } from './providers/global.service';
import { AuthService } from './providers/auth.service';
import { FCMService } from './providers/fcm.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { NetworkService } from './providers/network.service';
import { IonicService } from './providers/ionic.service';
import { LogService } from './providers/log.service';
import { LogLevel } from './providers/enum';
import { MediaService } from './providers/media.service';
import { GenericService } from './providers/generic.service';

declare var firebase: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    rootPage: any;
    message: any;
    syncInterval: any;
    closeConfirmationShown: boolean;
    cs = CurrentSurvey;
    gs = GlobalService; //To access static methods on HTML
    appConfig = AppConfig;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private ms: MediaService,
        private as: AuthService,
        private fcmService: FCMService,
        private ga: GoogleAnalytics,
        private ns: NetworkService,
        private ions: IonicService,
        private ls: LogService,
        private navCtrl: NavController
    ) {
        this.platform.ready().then(() => {

            //if (window.matchMedia("(min-width: 1200px)").matches)
            //    this.menu.open();

            this.checkMobileView();
            window.addEventListener("resize", () => { this.checkMobileView() });
            if (AppConfig.isApp) {
                this.statusBar.styleDefault();
                this.statusBar.backgroundColorByHexString('#275389');
                this.splashScreen.hide();
                this.platform.backButton.subscribeWithPriority(0, () => {
                    if (!this.closeConfirmationShown) {
                        this.confirmBeforeExit();
                    }
                });

                this.ga.startTrackerWithId(AppConfig.googleAnalyticsId)
                    .then(() => {
                        this.ga.enableUncaughtExceptionReporting(true)
                            .catch(() => {

                            });
                    })
                    .catch(() => {

                    });
            }

            this.ns.initializeNetworkEvents();
            if (location.pathname === '/login')
                this.navCtrl.navigateRoot('/login')
            else
                if (location.pathname.includes('/uniquesurvey')) {
                    const params = GenericService.getQueryStringMap();
                    if (Object.keys(params).length > 0)
                        this.navCtrl.navigateRoot(location.pathname, { queryParams: params });
                    else
                        this.navCtrl.navigateRoot(location.pathname);
                }
                else {
                    this.as.getDefaultPage().then(path => {
                        const params = GenericService.getQueryStringMap();
                        if (path === '/survey' && Object.keys(params).length > 0)
                            this.navCtrl.navigateRoot(path, { queryParams: params });
                        else
                            this.navCtrl.navigateRoot(path);
                    });
                }
        });
    }

    async confirmBeforeExit() {
        this.closeConfirmationShown = true;
        const alert = await this.ions.alertCtrl.create({
            header: 'Confirm Exit',
            message: 'Do you want to exit?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        this.closeConfirmationShown = false;
                    }
                },
                {
                    text: 'Yes',
                    handler: async () => {
                        await this.ms.stopAudioRecording();
                        await this.ms.stopPlaying();

                        navigator['app'].exitApp();
                    }
                }
            ]
        });
        await alert.present();
    }

    initServiceWorker() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('service-worker.js')
                .then((registration) => {
                    //console.log('service worker installed');
                    firebase.initializeApp(this.fcmService.config);
                    var messaging = firebase.messaging();
                    messaging.useServiceWorker(registration);
                    this.fcmService.messaging = messaging;
                    this.fcmService.enableNotifications();
                    this.fcmService.receiveMessage();
                    // this.message = this.fcmService.currentMessage;
                })
                .catch(err => this.ls.log(err, LogLevel.Error));
        }
    }

    get menuType() {
        if (window.matchMedia("(min-width: 1200px)").matches)
            return 'push';
        else
            return 'overlay';
    }

    sendFCMMessage() {
        this.fcmService.sendMessage();
    }

    updateToken() {
        this.fcmService.updateToken();
    }

    checkMobileView() {
        this.gs.isMobileView = window.innerWidth <= 480;
        return true;
    }
}
