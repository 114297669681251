import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { AjaxService } from './ajax.service';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';
import { SqliteService } from './sqlite.service';
import { NetworkService } from './network.service';
import { LogLevel } from './enum';
import { AppConfig } from './app.config';
import { CurrentSurvey } from './current-survey';

@Injectable()
export class LogService
{
    constructor(private ajaxService: AjaxService, private sqliteService: SqliteService)
    {
    }

    logDebug(msg)
    {
        if (AppConfig.debugMode)
            console.log(msg, LogLevel.Debug);
    }

    log(obj, level: LogLevel = LogLevel.Info, callBack = null, remarks = null)
    {
        try
        {
            let msg = '';
            if (typeof obj === 'string')
                msg = obj;
            else
            {
                if (obj.rejection)
                    obj = obj.rejection;

                const parsedObj = this.parse(obj);
                msg = JSON.stringify(parsedObj);
            }
            console.log(msg);
            if (msg.startsWith('ServerError:') || msg.indexOf('Unable to connect to server')>-1)
                return;

            const log: any =
            {
                Id: GenericService.createUId(), Remarks: remarks, Message: msg, Level: level, CreatedOn: GenericService.serializeDate(new Date()), SurveyCode: CurrentSurvey.code, UId: CurrentSurvey.uId, RespId: CurrentSurvey.respId
            };

            if (GlobalService.user)
                log.CreatedById = GlobalService.user.Id;

            if (NetworkService.isOnline())
                this.postLog(log, callBack)
            else
            {
                if (AppConfig.isApp)
                {
                    this.sqliteService.saveLog(log);
                }
            }
        }
        catch (err)
        {
            console.log(err);
        }
    }

    postLog(log, callBack)
    {
        this.ajaxService.postData('log', log, !GlobalService.user)
            .subscribe(() =>
            {
                if (callBack)
                    callBack();
            },
            () =>
            {
                //if (AppConfig.isApp && log.Level >= 3)
                //{
                //    this.ga.startTrackerWithId(AppConfig.googleAnalyticsId)
                //    .then(() =>
                //    {
                //        this.ga.trackEvent("Exception", "Error", log.Message, 1, true);
                //        //this.ga.trackException(JSON.stringify(parsedError), true);
                //        if (callBack)
                //            callBack();
                //    })
                //    .catch(e =>
                //    {
                //        if (callBack)
                //            callBack();
                //    });
                //}
            });
    }

    parse(obj: any)
    {
        const parsedObj: any =
            {
                message: obj.message ? obj.message as string : JSON.stringify(obj)
            };

        // include HTTP status code
        if (obj.status !== null)
            parsedObj.status = obj.status;

        // include stack trace
        if (obj.stack !== null)
            parsedObj.stack = obj.stack;

        return parsedObj;
    }
}
